import { Aod } from "@mui/icons-material";
import React, { useState } from "react";

import MyGamesRedirect from "./MyGamesRedirect";

import dayjs from "dayjs";

import AppBar from "./appBar";
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import Select from '@mui/material/Select';

import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import {
  Button, Table,
 TableBody, TableHead, TableRow,
} from "@material-ui/core";


import SportsHandballIcon from '@material-ui/icons/SportsHandball';
import EmojiPeopleIcon from '@material-ui/icons/EmojiPeople';
import SettingsBackupRestoreIcon from '@mui/icons-material/SettingsBackupRestore';

import Userfront from "@userfront/react";
import { TableCell } from "@mui/material";




class MyGames extends React.Component {
  
  constructor(props) {
    super(props);
    this.state = {
      
      game:
      {
        matchID: null,  
        matchUID: null,  
        gameNum:null ,
        gameStatus: null,
        team1Serving:  null,
        t1score:  null,
        t2score:  null,
        start_time: null,
        end_time:  null,
       },
      servingPlayer: { playerID: 0, index: 0,playerName: "T1p1"},
      Redirect: null

}


}




  handleScoreGameClick = (i) => {
  
    const game = this.props.games[i];
    if (game.gameUID == null)
    {
      //console.log("Game Clicked: game_UID is NULL" )
      this.setupGame(game);
      
    }      
    else
    {
      //console.log("Game In Progress Clicked: " + game.gameUID)
      // exsting game go to score game with game uid
     // const navigate = useNavigate();
      
   //const denis= () =>  {this.props.navigation.navigate("../scoregame/game/"+game.gameUID)};
  

     
   this.setState({ redirect: "../scoregame/game/"+game.gameUID });
    }
    

      
  }; 

  appendLeadingZeroes(n) {
    if (n <= 9) {
      return "0" + n;
    }
    return n
  }
  setupGame(game)
  {
    //alert(clickedButtonName)
    const loggedInUser = Userfront.user;
    var current_datetime = new Date();
    var formatted_date = current_datetime.getFullYear() + "-" +
      this.appendLeadingZeroes(current_datetime.getMonth() + 1) + "-" +
      this.appendLeadingZeroes(current_datetime.getDate()) + "_" +
      this.appendLeadingZeroes(current_datetime.getHours()) + ":" +
      this.appendLeadingZeroes(current_datetime.getMinutes()) + ":" +
      this.appendLeadingZeroes(current_datetime.getSeconds());

      //console.log(formatted_date)
    


    let setupGame = this.state.game;
   setupGame.matchID=this.props.match.MATCH_ID;
    setupGame.gameNum= game.gameNum;
    setupGame.gameStatus= "In Progress";
    //setupGame.team1Serving=  1;
    setupGame.t1score=0;
    setupGame.t2score=0;
    setupGame.start_time= formatted_date;

          

    this.insertGame(setupGame)
    };
             
    

    handleChange = (e) => {
  
      const { name, value } = e.target;
      const myGame = this.state.game;
      myGame[name] = value;
      //console.log("name: " + name + " value: " + value)
      this.setState({
        game:myGame
      });
        
    }; 
insertGame (game){

  const requestOptions = {
    method: 'POST',
    headers: { 'Content-Type': 'application/json', 
    Authorization: `Bearer ${Userfront.tokens.accessToken}` },
    body: JSON.stringify(game)
};
fetch('/api/getScoreBoard', requestOptions)
    .then(response => response.json())
    .then(data => this.handleInsert({ data }));
   

  }

handleInsert (insertData){
  if (insertData.data)
  {
    //console.log(insertData.response)
    this.setState({ redirect: "../scoregame/game/"+insertData.data});
  }
}
  
    render()
     {
     
    return (
      
      <div className="match">
       {(this.state.redirect!=null) && (
                <MyGamesRedirect gameURL={this.state.redirect}/>
                )
                }
       {(this.props.match) && (
      <>
      <h4> Match {this.props.match.MATCH_NAME} Game 1 of {this.props.match.NUM_GAMES} </h4>
      {(this.props.games.length>0) && (
        <>
          {this.props.games.map((game, i) => {
            return (
              <>
              <Table className="myGame">
              <TableRow>
                <TableCell className="matchCell" align="center" colSpan={2}>
              
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell  className="matchCell" align="center" colSpan={2}>
                  Game {game.gameNum} :  {game.gameStatus }
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell className="matchCell" align="center" colSpan={2}>
                  Score
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell className="matchCell" align="center" colSpan={1}>
                
                {game.teams[0].teamName }: {game.team1Score }
                </TableCell>
                <TableCell className="matchCell" align="center" colSpan={1}>
                {game.teams[1].teamName }: {game.team2Score }
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell className="matchCell" colSpan={2}>

                <FormControl sx={{ m: 1, minWidth: 125 }}>
                <InputLabel id="team1Serving">Serving Team</InputLabel>
                <Select
                  labelId="team1Serving"
                  id="team1Serving"
                  value={this.team1Serving}
                  label="team1Serving"
                  name="team1Serving"
                  className="matchCellMedium"
                  onChange={this.handleChange}
                >
                  <MenuItem value={"true"}>{game.teams[0].teamName }</MenuItem>
                  <MenuItem value={"false"}>{game.teams[1].teamName }</MenuItem>
                 
                
                </Select>
                
                </FormControl>
                
                {(game.gameStatus=="In Progress" || game.gameStatus=="Game Available") ? (
                    <button className="Scorebutton" onClick={(e) => this.handleScoreGameClick(i)}>
                    <SportsHandballIcon />
                    Start                              
                    </button>
                )
                :
                (
                    <button className="Scorebutton" onClick={(e) => this.handleScoreGameClick(i)}>
                    <SportsHandballIcon />
                    Edit                               
                    </button>
                )}
                 <button className="Scorebutton" onClick={this.props.handleClickBack}>
                 <SettingsBackupRestoreIcon/>
                    Back                              
                    </button>
                </TableCell>
              </TableRow>
              </Table>
              </>
              );
              }
              
              )
              }
            </>
              )
            } 
      </>
       )
        
      }
        




        </div>
      )
    }
  
 
  }
    export default MyGames;