import React, { useState } from "react";
import Button from "../components/Button";
import SignUp from "../SignUp";

import { Link } from "react-router-dom";

import "../assets/css/style.css";


export default function SignUpRoute() {
 
  

 
  return (
    <div className="App">

    <SignUp />
    </div>
   
  );
}

