import { Aod } from "@mui/icons-material";
import React, { useState } from "react";
import { useParams } from "react-router-dom";

import dayjs from "dayjs";

import SettingsAppBar from "./ScoreMatchesSettingsAppBar";
import AppBar from "./appBar";
import Box from '@mui/material/Box';
import CloseIcon from '@mui/icons-material/Close';

import InputLabel from '@mui/material/InputLabel';

import FormControl from '@mui/material/FormControl';

import SportsHandballIcon from '@material-ui/icons/SportsHandball';

import Paper from '@mui/material/Paper';

import IosShareIcon from '@mui/icons-material/IosShare';

import DeleteIcon from '@mui/icons-material/Delete';


import Collapse from '@mui/material/Collapse';
import Alert from '@mui/material/Alert';
import IconButton from '@mui/material/IconButton';

import Userfront from "@userfront/react";

import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import { styled } from '@mui/material/styles';
import {
   Button, Table,
  TableBody, TableHead, TableRow,
} from "@material-ui/core";
import TableContainer from '@mui/material/TableContainer';

import MyGames from './MyGames';




function MyMatches (paramMatch) {
  const [defaultClub, setDefaultClub] = useState("");
  const [selectedClub, setSelectedClub] = useState(null);
  const [selectedTeam, setSelectedTeam] = useState("");
  const [matches, setMatches] = useState([]);
  const [match, setMatch] = useState([]);
  const [games, setGames] = useState([]);
  const [selectedMatch, setSelectedMatch] = useState(null);
  const [stateChanger, setStateChanger] = useState(true);
  const [selectedDate, setSelectedDate] = useState(null);
  const [prevSelectedDate, setPrevSelectedDate] = useState(null);
  const [players, setPlayers] = useState([]);
  const [urlOpen, setUrlOpen] = useState(false);
  const [urlRow, setUrlRow] = useState("");
  const [gameURL, setGameURL] = useState("");
  const [isInitialized,setIsInitialized] = React.useState(false);  
  const [settingAppBarLoaded, setSettingAppBarLoaded] = useState(false);
  const handleClick = (i) => {
  
    const match = matches[i];
    //console.log("Match Clicked: " + match.MATCH_UID + " name: " + match.MATCH_NAME)
    
      setSelectedMatch(match);
      buildGame(match);
    //console.log("we built the game");
  }; 

  const handleShare = (i) => {

  const gameURL="https://racquetball.fun/viewmatches/match/"+  matches[i].MATCH_UID
  navigator.clipboard.writeText("https://racquetball.fun/viewmatches/match/"+ matches[i].MATCH_UID)
  setGameURL(gameURL);
   setUrlOpen(true);
   setUrlRow(i);

}

const handleDeleteMatch = (i) => {


  const deleteMatch=matches[i];

  const requestOptions = {
    method: 'DELETE',
    headers: { 'Content-Type': 'application/json', 
    Authorization: `Bearer ${Userfront.tokens.accessToken}` },
    body: JSON.stringify(deleteMatch)
  };
  fetch('api/setupMatch', requestOptions)
   
    .then(data => {
      InitializeData();
    })
  } 

const buildGame = (match) => {
   
  const loggedInUser = Userfront.user;

  //fetch game is in progress
  const requestOptions = {
    method: 'GET',
    headers: { 'Content-Type': 'application/json', 
    Authorization: `Bearer ${Userfront.tokens.accessToken}` }
    };
  const retGamesData=[]
  //fetch game action is in progress
  fetch("/api/getScoreBoard?rbm="+match.MATCH_UID,requestOptions,
  )
  .then((res) => {
    if (res.status != 204)
    {
      return res.json()
    }
    else
    {
      return res;
    }
    }
  )
  .then((res)=> {
    if (res.status != 204)
    {
      
    
        setGames(res); 
      initializeGame(res,match) 
    }
    else
    {
      initializeGame([],match) 
    }  
        
    })
  .catch(console.log("maybe syntax error")) ;
  //this.initializeGame([],match) 
  
  
 
 
  
}

const handleScoreGameBackClick  = () => {
   setSelectedMatch(null);

}; 
const appendLeadingZeroes= (n)  => {
  if (n <= 9) {
    return "0" + n;
  }
  return n
  }


const InitializeData = () => {
   



  const loggedInUser = Userfront.user;
  const requestOptions = {
    method: 'GET',
    headers: { 'Content-Type': 'application/json', 
    Authorization: `Bearer ${Userfront.tokens.accessToken}` }
  };
  


  //fetch game action is in progress
  fetch("/api/setupmatch?rbo="+loggedInUser.userUuid + "&rbc=" + selectedClub + "&rbt="+ selectedTeam + "&rbDate="+ selectedDate,requestOptions)
  
        
       .then((res)  => {
        if (res.status != 404)
        {
          return res.json()
        }
        else
        {
          setMatches([]);
          return res;
        }
        }
      )
           .then((res)=> {
            if (res.status != 404)
            {
              setMatches(res);
            }
            
                
            })
        .catch(error => {
          //console.log("Set Matches throw me an error " +  error)
            }  )
  
if (paramMatch.toString.length>0)
 {
  //fetch game action is in progress
  fetch("/api/setupmatch?rbm="+ paramMatch,requestOptions)
  .then((res) => res.json())
  .then((matchesData)=> {
   
    
      setSelectedMatch(matchesData[0]);
        
       this.buildPlayers(matchesData[0]);
       this.buildGame(matchesData[0]);
      }) 
     
 }
}


const initializeGame =(gamesData,match)  => {
{
let gamesDataRowCount=0;

if (gamesData.length>0) {
if (gamesData[0].gameNum==null)
{
  gamesDataRowCount=0;
  gamesData.pop();
}
else
{
  gamesDataRowCount=gamesData.length;
}
}

if (gamesDataRowCount<match.NUM_GAMES) {
  var addGames=[];
  if (gamesData)
  {
   addGames=gamesData;
  }
  else
  {
     addGames=[];
  }  

  //****FOR */
  //****FOR */
  for (let index = gamesDataRowCount; index <match.NUM_GAMES; index++) 
  {
    var gameStatus;
    if (index==0)
    {

      gameStatus="Game Available"
    }
    else if (addGames[index-1].gameStatus=="Game Available" || addGames[index-1].gameStatus=="Game Not Available" || addGames[index-1].gameStatus=="In Progress"  )
    {
      gameStatus="Game Not Available"

    }
    else 
    {
      gameStatus="Game Available"

    }
    const teams=[];
    let tempTeams = {}
    let tempTeams1 = {}
    tempTeams.teamId = match.TEAM1_ID;
    tempTeams.teamName = match.TEAM1_NAME;
  teams.push(tempTeams);
    tempTeams1.teamId = match.TEAM2_ID;
    tempTeams1.teamName = match.TEAM2_NAME;
    teams.push(tempTeams1);

    addGames.push({
    gameID: null,
    gameNum: index+1,
    gameUID: null,
    gameStatus: gameStatus,
    gameType: null,
    matchID: null,
    players: players,
    pointsToWin: null,
    teams: teams,
    
    team1Score: 0,
    team1Serving: true,
    team2Score: 0
    });


   
    
   }
   //console.log(JSON.stringify(addGames))
  
    setGames(addGames); 
    setStateChanger(true);
}

}
const buildPlayers = (match) => {
  const players = [];
  
    const tempPlayer={}
    tempPlayer.playerID=match.TEAM1P1_ID;
    tempPlayer.playerName=match.TEAM1P1_NAME;
    players.pop(tempPlayer);
    tempPlayer.playerID=match.TEAM1P2_ID;
    tempPlayer.playerName=match.TEAM1P2_NAME;
    players.pop(tempPlayer);
    tempPlayer.playerID=match.TEAM2P1_ID;
    tempPlayer.playerName=match.TEAM2P1_NAME;
    players.pop(tempPlayer);
    tempPlayer.playerID=match.TEAM2P2_ID;
    tempPlayer.playerName=match.TEAM2P2_NAME;
    players.pop(tempPlayer);
    
      setPlayers(players);
  
}







  


  const handlematchCells = (actionType) => 
  {
    const clickedButtonName= actionType;
    switch (clickedButtonName) {
      case "Serve":
        //
        break;
    case "Back":
      this.setState({
        //
      })
    break;
 
     
    }        
  };


  const handleChange = (e) => {
  
  const { name, value } = e.target;
  const myMatch = match;
  myMatch[name] = value;
  //console.log("name: " + name + " value: " + value)
  
    setMatch(myMatch);
    
}; 
  
} 
if (stateChanger)
	{
	InitializeData();
	setStateChanger(false);
	}

  if (selectedDate != null && selectedDate!= prevSelectedDate)
  {
    setPrevSelectedDate(selectedDate);
  
    setStateChanger(true);
  }
return (
      
      <div className="match">
        
       
   <SettingsAppBar mStateChanger={setStateChanger} mSelectedClub={setSelectedClub} mSelectedTeam={setSelectedTeam} msetSettingAppBarLoaded={setSettingAppBarLoaded} mSelectedDate={setSelectedDate}/>
      
          <FormControl variant="standard">
            <InputLabel htmlFor="component-simple">Matches</InputLabel>
          
          </FormControl>
    
    {(!selectedMatch) && (
    <TableContainer component={Paper}>
      <Table className="myMatch" 
      size="small"
      aria-label="a dense table"
      stickyHeader padding="none">
        <TableHead>
          <TableRow >
            
            <TableCell  className="matchNameCell"> Match</TableCell>
                <TableCell className="matchCell" > Team 1</TableCell>
                <TableCell className="matchCell" > Team 2</TableCell>
                <TableCell  className="matchCell"> Status?</TableCell>
                <TableCell  className="matchCell"> Start</TableCell>
                <TableCell  className="matchCell"> Finish</TableCell>
                <TableCell  className="matchCell"> Singles?</TableCell>
                <TableCell  className="matchCell"> Games?</TableCell>
                <TableCell  className="matchCell"> To Win?</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
                
                
       
            {(matches) && (
              <>
                {matches.map((match, i) => {
                              return (
                                <>
                                <TableRow>

                                  <TableCell  className="matchNameCell" >
                                    {match.MATCH_NAME}
                                    <Button
                                       onClick={() => handleClick(i)}>
                                      
                                      
                                    
                                      <SportsHandballIcon />
                                    </Button>
                                    <Button
                                      onClick={() => handleShare(i)}
                                      >
                                      <IosShareIcon/>
                                      </Button>
                                      <Button
                                      onClick={() => handleDeleteMatch(i)}
                                      >
                                      <DeleteIcon/>
                                      </Button>
                                    
                                    
                                  </TableCell>
                                  <TableCell align="center"  className="matchCellMedium">
                                  {(match.MATCH_TYPE=="Singles") ? (
                                    <>
                                    {match.TEAM1_NAME + " - " + match.TEAM1P1_NAME}
                                    </>
                                  ):
                                  (
                                    <>
                                    {match.TEAM1_NAME + " - " + match.TEAM1P1_NAME + " / " + match.TEAM1P2_NAME}
                                    </>
                                  )
                                  }
                                  </TableCell>
                                  <TableCell align="center"  className="matchCellMedium">
                                  {(match.MATCH_TYPE=="Singles") ? (
                                    <>
                                    {match.TEAM2_NAME + " - " + match.TEAM2P1_NAME}
                                    </>
                                  ):
                                  (
                                    <>
                                    {match.TEAM2_NAME + " - " + match.TEAM2P1_NAME + " / " + match.TEAM2P2_NAME}
                                    </>
                                  )
                                  }
                                  </TableCell>
                                  <TableCell align="center"  className="matchCellMedium">
                                  {match.MATCH_STATUS}
                                  </TableCell>
                                  <TableCell align="center"  className="matchCellWide">
                                  {dayjs(match.START_TIME).format('M/D/YY h:m a')}
                                  </TableCell>
                                  <TableCell align="center"  className="matchCellWide">
                                  {match.END_TIME}
                                  </TableCell>
                                  <TableCell align="center"  className="matchCellMedium">
                                  {match.MATCH_TYPE}
                                  </TableCell>
                                  <TableCell align="center"  className="matchCell">
                                  {match.NUM_GAMES}
                                  </TableCell>
                                  <TableCell align="center"  className="matchCell">
                                  {match.POINTSTOWIN}
                                  </TableCell>

                                </TableRow>
                                <TableRow>
                                  <TableCell colSpan={8}>
               {(urlRow==i)  && (                     
                                  <Collapse in={urlOpen}>
        <Alert
          action={
            <IconButton
              aria-label="close"
              color="inherit"
              size="small"
              onClick={(e) => setUrlOpen(false)}
            >
              <CloseIcon fontSize="inherit" />
            </IconButton>
          }
          sx={{ mb: 2 }}
        >
          Match Link Copied to Clipboard
        </Alert>
      </Collapse> 
               )}

                                  </TableCell>
                                </TableRow>
             
                              </>
                              );
                            }
                        )
                    }
                   </>   
                   )
                  } 
                    </TableBody>
                </Table>
                </TableContainer>
       )} 
              <MyGames handleClickBack={handleScoreGameBackClick} match={selectedMatch} games={games} players={players}/>
              
      </div>
      )
    
  
 
  }

    export default MyMatches;