import React, { useState, useEffect } from "react";
import CreateIcon from "@material-ui/icons/Create";
import FormControl from '@mui/material/FormControl';
import AppBar from "./appBar";
import ClubAppBar from "./clubAppBar";
import Paper from '@mui/material/Paper';
import TableContainer from '@mui/material/TableContainer';
import TablePagination from '@mui/material/TablePagination';

import {
    Box, Button, Snackbar, Table,
    TableBody, TableCell, TableHead, TableRow
} from "@material-ui/core";

import MenuItem from '@mui/material/MenuItem';

import Select, { SelectChangeEvent } from '@mui/material/Select';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import DeleteOutlineIcon from "@material-ui/icons/DeleteOutline";
import AddBoxIcon from "@material-ui/icons/AddBox";
import DoneIcon from "@material-ui/icons/Done";
import ClearIcon from "@material-ui/icons/Clear";
import { makeStyles } from "@material-ui/core/styles";
import Alert from "@material-ui/lab/Alert";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import SportsHandballIcon from '@material-ui/icons/SportsHandball';
import AutorenewIcon from '@material-ui/icons/Autorenew';
import DeleteIcon from '@material-ui/icons/Delete';
import SportsTennisIcon from '@material-ui/icons/SportsTennis';
import EmojiPeopleIcon from '@material-ui/icons/EmojiPeople';
import EditIcon from '@material-ui/icons/Edit';
import AddIcon from '@material-ui/icons/Add';
import SaveIcon from '@material-ui/icons//Save';

import Userfront from "@userfront/react";


const requestOptions = {
    method: 'GET',
    headers: { 'Content-Type': 'application/json', 
    Authorization: `Bearer ${Userfront.tokens.accessToken}` }
  };
  
// Creating styles
const useStyles = makeStyles({
	root: {
		"& > *": {
			borderBottom: "unset",
		},
	},

	snackbar: {
		bottom: "104px",
	},
});

function MyClubAccess() {
	// Creating style object
	const classes = useStyles();

	// Defining a state named rows
	// which we can update by calling on setRows function
	const [rows, setRows] = useState([]);
  const [sRows, setSRows] = useState([]);
  const [allUsersRows, setAllUsersRows] = useState([]);
  const [allUsersSearch, setAllUsersSearch] = useState([]);

  

 
  //const [rows, setRows] = useState([
//		{ id: 1, firstname: "", lastname: "", city: "" },
//	]);

	// Initial states
	const [open, setOpen] = React.useState(false);
	const [notFoundopen, setNotFoundopen] = React.useState(false);
	
	const [isEdit, setEdit] = React.useState(false);
  const [editRow, setEditRow] = React.useState(-1);
  const [isAdd, setIsAdd] = React.useState(false);
	const [disable, setDisable] = React.useState(true);
	const [showConfirm, setShowConfirm] = React.useState(false);
	const [page, setPage] = React.useState(0);
	const [loggedInUser,setLoggedInUser] = React.useState(Userfront.user);
	const [isInitialized,setIsInitialized] = React.useState(false);
	const [selectedClub, setSelectedClub] = useState("");
	const [selectedClubRole, setSelectedClubRole] = useState("");
	const [selectedTeam, setSelectedTeam] = useState(null);
	const [stateChanger, setStateChanger] = useState(false);
	const [settingAppBarLoaded, setSettingAppBarLoaded] = useState(false);

	//useEffect(() => {
	//	InitializeData()
	//	})
	
	// Showing delete confirmation to users
	const handleInitialized = () => {
		setIsInitialized(!isInitialized);
	};
	const InitializeData = () => {
   
		const requestOptions = {
			method: 'GET',
			headers: { 'Content-Type': 'application/json', 
			Authorization: `Bearer ${Userfront.tokens.accessToken}` }
		  };
	  

		//fetch default club id
		fetch("/api/myUSERs?rbo="+loggedInUser.userUuid,requestOptions)
		.then((res)  => {
			if (res.status != 404)
			{
			  return res.json()
			}
			else
			{
				setSRows([]);
			  return res;
			}
			}
		  )
		.then((res)  => {
			if (res.status != 404)
			{
				const USERsData=res;
			  
		 
				setSRows(res);
				
				//setSelectedClub(USERsData[0].DEFAULT_CLUB_ID);
				setSelectedTeam(USERsData[0].DEFAULT_TEAM_ID);  
				//fetch game action is in progress
				fetch("/api/myClubAccess?rbc="+selectedClub,requestOptions)
				.then((res) => res.json())
				.then((ClubAccessData)=> {
				
				setSRows(ClubAccessData);
				
			
			}
		)	
			}
			else
			{
				setSRows([]);
			  return res;
			}
			}
		  )
		
		
		


				fetch("/api/myUSERs?rballusers=true",requestOptions)
		.then((res) => res.json())
		.then((USERsData)=> {
			const allUserSearch =[]
					setAllUsersRows(USERsData);
					for (let i = 0; i < allUsersRows.length; i++) {
							const userSearch={ label: allUsersRows[i].USER_EMAIL, year: allUsersRows[i].USER_ID}
							
							allUserSearch.push(userSearch);

					}
					setAllUsersSearch(allUserSearch);
					
					
				})
		}
			
		
	
	  const appendLeadingZeroes= (n)  => {
		if (n <= 9) {
		  return "0" + n;
		}
		return n
	  }
const insertClubAccess = (ClubAccess) => {


		const insertClubAccess=ClubAccess;
		var current_datetime = new Date();
		var formatted_date = current_datetime.getFullYear() + "-" +
		  appendLeadingZeroes(current_datetime.getMonth() + 1) + "-" +
		  appendLeadingZeroes(current_datetime.getDate()) + "_" +
		  appendLeadingZeroes(current_datetime.getHours()) + ":" +
		  appendLeadingZeroes(current_datetime.getMinutes()) + ":" +
		  appendLeadingZeroes(current_datetime.getSeconds());
	
		  //console.log(formatted_date)
		  insertClubAccess.DATE_CREATED=formatted_date

		const requestOptions = {
		  method: 'POST',
		  headers: { 'Content-Type': 'application/json', 
		  Authorization: `Bearer ${Userfront.tokens.accessToken}` },
		  body: JSON.stringify(insertClubAccess)
	  };
	  fetch('api/myClubAccess', requestOptions)
	  .then(response => response.json())
		  .then(data => {
			  //console.log(data.ClubAccess_ID)
			  InitializeData();
		  })
	  }  

const deleteClubAccess = (ClubAccess) => {


		const deleteClubAccess=ClubAccess;

		const requestOptions = {
		  method: 'DELETE',
		  headers: { 'Content-Type': 'application/json', 
		  Authorization: `Bearer ${Userfront.tokens.accessToken}` },
		  body: JSON.stringify(deleteClubAccess)
	  };
	  fetch('api/myClubAccess', requestOptions)
		  .then(response => response.json())
		  .then(data => {
			  InitializeData();
		  })
	  }  
const updateClubAccess = (ClubAccess) => {


		const updateClubAccess=ClubAccess;
		var current_datetime = new Date();
		var formatted_date = current_datetime.getFullYear() + "-" +
		  appendLeadingZeroes(current_datetime.getMonth() + 1) + "-" +
		  appendLeadingZeroes(current_datetime.getDate()) + "_" +
		  appendLeadingZeroes(current_datetime.getHours()) + ":" +
		  appendLeadingZeroes(current_datetime.getMinutes()) + ":" +
		  appendLeadingZeroes(current_datetime.getSeconds());
	
		  //console.log(formatted_date)
		  updateClubAccess.DATE_UPDATED=formatted_date

		const requestOptions = {
		  method: 'PUT',
		  headers: { 'Content-Type': 'application/json', 
		  Authorization: `Bearer ${Userfront.tokens.accessToken}` },
		  body: JSON.stringify(updateClubAccess)
	  };
	  fetch('api/myClubAccess', requestOptions)
		  .then(response => response.json())
		  .then(data => {
			  InitializeData();
		  })
	  }  


	const handleChangePage = (event, newPage) => {
		setPage(newPage);
	  };


	  const handleChangeRowsPerPage = (event) => {
		setRowsPerPage(parseInt(event.target.value, 10));
		setPage(0);
	  };

	  const [rowsPerPage, setRowsPerPage] = React.useState(5);
  // Function For closing the alert snackbar
	const handleClose = (event, reason) => {
		if (reason === "clickaway") {
			return;
		}
		setOpen(false);
		setNotFoundopen(false);
	};

	// Function For adding new row object
	const handleAdd = () => {
		setIsAdd(true);
    if (rows)
    {
    setRows([
			...rows,
			{
				id: rows.length + 1,CLUB_ID:selectedClub,USER_ROLE_ID:1000,ROLE_NAME:"",USER_ID:"",USER_NAME: "", USER_EMAIL: "",DATE_CREATED: ""
			},
		]);
   }
    else
    {
      setRows([
        rows,
        {
			id: rows.length + 1,CLUB_ID:selectedClub,USER_ROLE_ID:1000,ROLE_NAME:"",USER_ID:"",USER_NAME: "", USER_EMAIL: "",DATE_CREATED: ""
        },
      ]);
    }
		setEdit(true);
    
	};
	// Function to handle cancel of edit
	const handleCancel = () => {
		// If edit mode is true setEdit will
		// set it to false and vice versa
		setEdit(!isEdit);
    setEditRow(-1)

	};

	// Function to handle edit
	const handleEdit = (i) => {
		// If edit mode is true setEdit will
		// set it to false and vice versa
		setEdit(!isEdit);
    setEditRow(i)

	};
  

	// Function to handle save
	const handleSave = () => {
		
	//verify user exists
	const requestOptions = {
		method: 'GET',
		headers: { 'Content-Type': 'application/json', 
		Authorization: `Bearer ${Userfront.tokens.accessToken}` }
	  };
  

	//fetch default club id
	fetch("/api/myUSERs?rbemail="+rows[0].USER_EMAIL,requestOptions)
	.then((res) => res.json())
	.then((USERsData)=> {
			if (USERsData.Retmessage=="No User Found") 
			{
				setNotFoundopen(true);
			}
			else
			{
			//console.log("USERsData : ", USERsData);
		
			setEdit(false);
			setEditRow(-1);
			setIsAdd(false);
			const list = [...rows];
			list[0].USER_ID =USERsData[0].USER_ID;
			
			var current_datetime = new Date();
			var formatted_date = current_datetime.getFullYear() + "-" +
			  appendLeadingZeroes(current_datetime.getMonth() + 1) + "-" +
			  appendLeadingZeroes(current_datetime.getDate()) + "_" +
			  appendLeadingZeroes(current_datetime.getHours()) + ":" +
			  appendLeadingZeroes(current_datetime.getMinutes()) + ":" +
			  appendLeadingZeroes(current_datetime.getSeconds());
	
			  //console.log(formatted_date);
			  list[0].DATE_CREATED=formatted_date;


			setRows(list);
			//console.log("Cleared : ", rows);
			insertClubAccess(rows[0]);
			setRows([]);
			}
		})
		}
			
	
	
	

  // Function to handle saveClubAccess
	const handleSaveClubAccess = (i) => {
	
			
	
	setEdit(false);
    setEditRow(-1);
    setIsAdd(false); 

	//console.log("saved : ", sRows[i]);
	setDisable(true);
	setOpen(true);
    
    setRows([]);
    //console.log("Cleared : ", rows);
	updateClubAccess(sRows[i]);
	InitializeData();
	};

	// The handleInputChange handler can be set up to handle
	// many different inputs in the form, listen for changes
	// to input elements and record their values in state
	const handleInputChange = (e, index) => {
		setDisable(false);
		const { name, value } = e.target;
		const list = [...rows];
		if (value=="400")
		{
		list[index][name] = 400;
		list[index].USER_ROLE_ID = 400;
		}
		else
		{
		list[index][name] = 1000;
		list[index].USER_ROLE_ID= 1000;
		}
		setRows(list);
	};

		// The handleInputChange handler can be set up to handle
	// many different inputs in the form, listen for changes
	// to input elements and record their values in state
	const handleInputChangeEmail = (e,index) => {
		setDisable(false);
		const  value  = e.target.innerHTML;
		const list = [...rows];
		list[index].USER_EMAIL = value;
		setRows(list);
	};
  	// The handleInputChange handler can be set up to handle
	// many different inputs in the form, listen for changes
	// to input elements and record their values in state
	const handleInputChangeClubAccess = (e, index) => {
		setDisable(false);
		const { name, value } = e.target;
		const list = [...sRows];
		list[index][name] = value;
		setSRows(list);
	};


	// Showing delete confirmation to users
	const handleConfirm = (i) => {
			//setShowConfirm(true);
			handleRemoveClubAccessClick(i);

	};

	// Handle the case of delete confirmation where
	// user click yes delete a specific row of id:i
	const handleRemoveClick = (i) => {
		const list = [...rows];
		list.splice(i, 1);
		setRows(list);
		setShowConfirm(false);
    setIsAdd(false);
	};


  const handleRemoveClubAccessClick = (i) => {
		const list = [...sRows];
		deleteClubAccess(sRows[i])
		list.splice(i, 1);
		setSRows(list);
		setShowConfirm(false);
    setIsAdd(false);

	InitializeData();
	};

	// Handle the case of delete confirmation
	// where user click no
	const handleNo = () => {
		setShowConfirm(false);
	};
	const emptyRows =
    page > 0 ? Math.max(0, (1 + page) * rowsPerPage - rows.length) : 0;

	if ((!isInitialized) || (stateChanger))
	{
	InitializeData();
	setIsInitialized(true);
	setStateChanger(false);
	}
return (
  
 <div >
  
  
 
  <ClubAppBar mStateChanger={setStateChanger} mSelectedClub={setSelectedClub}  msetSettingAppBarLoaded={setSettingAppBarLoaded}/>
  <Table className="myMatch">
	<TableBody >
	<Snackbar
		open={notFoundopen}
		autoHideDuration={4000}
		onClose={() => handleClose()}
		className={classes.snackbar}
	>
		<Alert onClose={() => handleClose()} severity="error">
		User not found!!
		</Alert>
	</Snackbar>
	<Snackbar
		open={open}
		autoHideDuration={2000}
		onClose={() => handleClose()}
		className={classes.snackbar}
	>
		<Alert onClose={() => handleClose()} severity="success">
		Record saved successfully!
		</Alert>
	</Snackbar>
  <TableRow align="center">
    <TableCell  className="ClubAccessCell">
    ClubAccess
    </TableCell>
    <TableCell className="addButton" align="left">
	
      
      
      
			{isEdit ? (
			<>
        {isAdd ? (
					<Button disabled align="right" onClick={() => handleAdd()}>
					<AddBoxIcon onClick={() => handleAdd()}/>
				ADD ClubAccess
				</Button>
					) : (
            <Button onClick={() => handleAdd()}>
            <AddBoxIcon onClick={() => handleAdd()}/>
            ADD ClubAccess
            </Button>
					)}
			
			</>	
			
			) : (
			<>
				<Button  onClick={() => handleAdd()}>
				<AddBoxIcon onClick={() => handleAdd()}/>
				ADD ClubAccess
				</Button>
		
			</>
			)}
		
		</TableCell>
    </TableRow>
    
    
		<TableRow align="center"></TableRow>
    <TableRow><TableCell colSpan={2}>
    {isAdd && (
      <>    
		<Table>
     
		<TableHead>
			<TableRow>
			
			<TableCell>Role Name</TableCell>
			<TableCell>User Name</TableCell>
			<TableCell>User Email</TableCell>
			<TableCell align="center"></TableCell>
			</TableRow>
		</TableHead>
		<TableBody>
      {(rows) && (
        <>
			{rows.map((row, ii) => {
			return (
				<>
				<TableRow>
					{isEdit ? (
					<>
					
						<TableCell >
				
						
						 <Select
						labelId="ROLE_NAME"
						id="ROLE_NAME"
						value={row.ROLE_NAME}
						name="ROLE_NAME"
						label="Role"
						sx={{ minWidth: 300 }}
						onChange={(e) => handleInputChange(e, ii)}
						>
						
						<MenuItem value={1000}>	ADMIN</MenuItem>
						
						<MenuItem value={400}>SCOREKEEPER</MenuItem>	
						
						</Select>
					
						</TableCell>
						<TableCell padding="none">
						
						</TableCell>
						<TableCell padding="none">
						
						 <Autocomplete
						disablePortal
						id="combo-box-emails"
						
						options={allUsersSearch}
						sx={{ width: 300 }}
						renderInput={(params) => <TextField {...params}  name="USER_EMAIL" label="Email" />}
						onChange={(e) => handleInputChangeEmail(e, ii)}/>
            {rows.length !== 0 && (
				<>
					{disable ? (
					<Button disabled align="right" onClick={() => handleSave()}>
						<DoneIcon />
						SAVE
					</Button>
					) : (
					<Button align="right" onClick={() => handleSave()}>
						<DoneIcon />
						SAVE
					</Button>
					)}
				</>
				)}

          {isEdit  ? (
					<Button className="mr10" onClick={() => handleRemoveClick(ii)}>
						<ClearIcon />
					</Button>
					) : (
					<Button className="mr10" onClick={() => handleRemoveClick(ii)}>
						<DeleteOutlineIcon />
					</Button>
					)}

						</TableCell>
					
            </>
					) : (
					<>
						<TableCell component="th" scope="row">
						{row.CLUB_NAME}
						</TableCell>
						<TableCell component="th" scope="row">
						{row.ROLE_NAME}
						</TableCell>
						<TableCell component="th" scope="row">
						{row.USER_NAME}
						</TableCell>
						<TableCell component="th" scope="row">
						{row.USER_EMAIL}
						</TableCell>
						<TableCell
						component="th"
						scope="row"
						align="center"
						>

            </TableCell>
					</>
					)}
				
					
				</TableRow>
				</>
			);
			})}
      </>
      )}
		</TableBody>
		</Table>
    </>
    )
  }
  </TableCell>
  </TableRow>
  <TableRow>
    <TableCell className="matchCell" colSpan={2}>
   

    <Box sx={{ width: '100%' }}>
      <Paper sx={{ width: '100%', mb: 2 }}>
        
        <TableContainer>
    <Table
		 className="myMatch"
		size="small"
		aria-label="a dense table"
		>
		<TableHead>
			<TableRow>
			<TableCell>Club Name</TableCell>
			<TableCell>Role	Name</TableCell>
			<TableCell>User Name</TableCell>
			<TableCell>User Email</TableCell>
			<TableCell align="center"></TableCell>
			</TableRow>
		</TableHead>
		<TableBody>
      {(sRows) && (
        <> {}
			{sRows.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row, i) => {
			return (
				<>
				<TableRow>
					{isEdit   && i==editRow ? (
					<>
						<TableCell padding="none">
						{row.CLUB_NAME}
						</TableCell>
						<TableCell padding="none">
						<input
							value={row.ROLE_NAME}
							name="ROLE_NAME"
							 onChange={(e) => handleInputChangeClubAccess(e, i)}
						/>
						</TableCell>
						<TableCell padding="none" colSpan={2}>
						{row.USER_NAME}
							
						</TableCell>
						<TableCell padding="none" colSpan={2}>
						{row.USER_EMAIL}
							
						<Button align="right" onClick={() => handleSaveClubAccess(i)}>
						<DoneIcon />
					
					</Button>
          <Button className="mr10" onClick={() => handleCancel()}>
						<ClearIcon />
					</Button>
          </TableCell>
            </>
					) : (
					<>
						<TableCell component="th" scope="row">
						{row.CLUB_NAME}
						</TableCell>
						<TableCell component="th" scope="row">
						{row.ROLE_NAME}
						</TableCell>
						<TableCell component="th" scope="row">
						{row.USER_NAME}
						</TableCell>
						<TableCell component="th" scope="row">
						{row.USER_EMAIL}
						</TableCell>
						<TableCell
						component="th"
						scope="row"
						align="center"
						>
		<Button align="right" onClick={(e) => handleEdit( i)}>
				<CreateIcon />
				EDIT
				</Button>
          
        <Button className="mr10" onClick={() => handleConfirm(i)}>
						<DeleteOutlineIcon />
					</Button>

					{showConfirm && (
					<>
						<Dialog
						open={showConfirm}
						onClose={handleNo}
						aria-labelledby="alert-dialog-title"
						aria-describedby="alert-dialog-description"
						>
						<DialogTitle id="alert-dialog-title">
							{"Confirm Delete"}
						</DialogTitle>
						<DialogContent>
							<DialogContentText id="alert-dialog-description">
							Are you sure to delete
							</DialogContentText>
						</DialogContent>
						<DialogActions>
							<Button
							onClick={() => handleRemoveClubAccessClick(i)}
							color="primary"
							autoFocus
							>
							Yes
							</Button>
							<Button
							onClick={handleNo}
							color="primary"
							autoFocus
							>
							No
							</Button>
						</DialogActions>
						</Dialog>
					</>
					)}

          </TableCell>
          
          </>

					)}
				
					
				</TableRow>
				</>
			);
			})}
      </>
      )}
		</TableBody>
		</Table>
		</TableContainer>
        <TablePagination
          rowsPerPageOptions={[5, 10, 25]}
          component="div"
          count={sRows.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </Paper>
     
    </Box>
    </TableCell>
  </TableRow>
	</TableBody>
  </Table>

  </div>
);
}
    export default MyClubAccess;