
import React, { useState, useEffect } from "react";
import { useNavigate } from 'react-router-dom';

import AdbIcon from '@mui/icons-material/Adb';
import AppBar from '@mui/material/AppBar';
import {
  Box, Button, Snackbar, Table,
  TableBody, TableCell, TableHead, TableRow
} from "@material-ui/core";
import Switch from '@mui/material/Switch';

import Grid from '@mui/material/Unstable_Grid2';
import dayjs, { Dayjs } from "dayjs";
import TextField from '@mui/material/TextField';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import Menu from '@mui/material/Menu';
import MenuIcon from '@mui/icons-material/Menu';
import Container from '@mui/material/Container';
import Avatar from '@mui/material/Avatar';
import FavoriteIcon from '@mui/icons-material/Favorite';
import FavoriteBorderIcon from '@mui/icons-material/FavoriteBorder';

import Chip from '@mui/material/Chip';
import Tooltip from '@mui/material/Tooltip';
import MenuItem from '@mui/material/MenuItem';
import FormLabel from '@mui/material/FormLabel';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormGroup from '@mui/material/FormGroup';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import InputLabel from '@mui/material/InputLabel';

import Userfront from "@userfront/react";
import  { DatePicker }  from '@mui/x-date-pickers/DatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
// or for Day.js
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';

const notLoggedInPages = [ {pageName: "Login", pageKey: "login"},
                {pageName: "Sign Up", pageKey: "signup"}
                
              ];
const pages = [ 
                {pageName: "Quick Match", pageKey: "setupmatch"},
                {pageName: "My Matches", pageKey: "mymatches"},
                {pageName: "My Teams", pageKey: "myteams"},
                {pageName: "My Players", pageKey: "myplayers"}
              ];
const settings = ['Profile', 'Account', 'Dashboard', 'Logout'];





function SettingsAppBarAll({mStateChanger,mSelectedClub,mSelectedTeam,mSelectedDate}) {
  const [anchorElNav, setAnchorElNav] = React.useState(null);
  const [anchorElUser, setAnchorElUser] = React.useState(null);
  const [rows, setRows] = useState([]);
  const [sRows, setSRows] = useState([]);
  const [clubRows, setClubRows]= useState([]);
  const [teamRows, setTeamRows]= useState([]);
  const navigate = useNavigate();
  const [isInitialized,setIsInitialized] = React.useState(false);
  const [defaultClub, setDefaultClub] = useState("");
  const [selectedClub, setSelectedClub] = useState("");
  const [selectedTeam, setSelectedTeam] = useState(null);
  const [myDateValue, setMyDateValue] = React.useState(    dayjs(new Date())  );
  const [page, setPage] = React.useState(0);
  const [mUser, setMUser] = React.useState(0);
  const [showFavorites,setShowFavorites] = React.useState(false);
  
 


  
  const handleDateChange = (newValue: Dayjs | null) => {
    setMyDateValue(newValue);
    
    mSelectedDate(dayjs(newValue).format('YYYY-MM-DD'));
    mStateChanger(true);
    
  };

  const handleShowFavorites = () => {
setShowFavorites(!showFavorites);
setIsInitialized(false);
  } 
  
  const handleChangePage = (event, newPage) => {
		setPage(newPage);
	  };


	  const handleChange = (e) => {
		
		const { name, value } = e.target;
		
		setDefaultClub(value);
     
		//console.log("Default Club: " + value)	
		//Reset TEAM Drop Down
    InitializeData();
		  
	  }; 

	  const handleChangeClub = (e) => {
		
		const { name, value } = e.target;
		setSelectedClub(value);
    
    mSelectedClub(value);
    
		mStateChanger(true);


    InitializeTeamsData(value);
   
	  }; 
 
    const appendLeadingZeroes= (n)  => {
      if (n <= 9) {
        return "0" + n;
      }
      return n
      }

    
  
   const InitializeTeamsData = (clubID) => {
   
		
          const requestOptions = {
            method: 'GET',
            headers: { 'Content-Type': 'application/json', 
            Authorization: `Bearer ${Userfront.tokens.accessToken}` }
            };
          
           //fetch all teams
           fetch("/api/allTeams?rbc="+clubID,requestOptions)
           .then((res) => {
            if (res.status != 404)
            {
              return res.json()
            }
            else
            {
              setTeamRows([]);
              return res;
            }
            }
          )
               .then((res)=> {
                if (res.status != 404)
                {
                  setTeamRows(res);
                }
                
                    
                })
            .catch(error => {
              //console.log("throw me an error " +  error)
                }  )
          
            } ///end initialize teams data

        

const InitializeData = () => {
   
  //mSelectedDate(dayjs(myDateValue).format('MMMM D, YYYY h:mm A'));
  var current_datetime = new Date(myDateValue);
  var formatted_date = current_datetime.getFullYear() + "-" +
    appendLeadingZeroes(current_datetime.getMonth() + 1) + "-" +
    appendLeadingZeroes(current_datetime.getDate()) + "_" +
    appendLeadingZeroes(current_datetime.getHours()) + ":" +
    appendLeadingZeroes(current_datetime.getMinutes()) + ":" +
    appendLeadingZeroes(current_datetime.getSeconds());

    //console.log(formatted_date)
    mSelectedDate(formatted_date);
    mStateChanger(true);
	  const requestOptions = {
			method: 'GET',
			headers: { 'Content-Type': 'application/json', 
			Authorization: `Bearer ${Userfront.tokens.accessToken}` }
		  };
	  
		
      let fetcURL= null;

      //fetch all clubs
     if (showFavorites==true)
     {
      fetcURL="/api/allClubs?rbfc=true"
    }
      else
     {
      fetcURL="/api/allClubs?rbfc=false"
     }

     fetch(fetcURL,requestOptions)
      .then((res) => res.json())
      .then((clubData)=> {
     
     setClubRows(clubData);
       
        //fetch all teams
        fetch("/api/allTeams",requestOptions)
        
        //.then((res)=> res.json())
        .then((res) => {
          if (res.status != 404)
          {
            return res.json()
          }
          else
          {
            return res;
          }
          }
        )
        .then((res)=> {
          if (res.status != 404)
          {
            setTeamRows(res);
          }
          
              
          })
      .catch(error => {
       //console.log("throw me an error on team rows")
         }  )

     })
      
	    
    
    
    
      } ///end initialize data
	 
 
      const handleChangeTeam = (e) => {
		
        const { name, value } = e.target;
        setSelectedTeam(value);
        mSelectedTeam(value);
        mStateChanger(true);
        
        //console.log(selectedTeam)
        }; 	 
  
  const handleOpenNavMenu = (event) => {
    setAnchorElNav(event.currentTarget);
    const name = event.target.innerText;
    //console.log("name: " + name )
    switch (name) {
      case "Logout":
        Userfront.logout();
        break;
    }
  };
  
  const handleOpenUserMenu = (event) => {
    setAnchorElUser(event.currentTarget);
    const name = event.target.innerText;
    //console.log("name: " + name )
    switch (name) {
      case "Logout":
        Userfront.logout();
        break;
    }
  };
    
  

  const handleCloseNavMenu = (event) => {
    setAnchorElNav(null);
    const name = event.target.innerText;
    //console.log("name: " + name )
    switch (name) {
      case "Logout":
        Userfront.logout();
        break;
    }
  };
  

  const handleCloseUserMenu = (event) => {
    setAnchorElUser(null);
    const name = event.target.innerText;
    //console.log("name: " + name )
    switch (name) {
      case "Logout":
        Userfront.logout();
        break;
      default:
        navigate("../"+name);

    }
  };
  const loggedInUser = Userfront.user;
 // alert (loggedInUser.name)
  
  function stringToColor(string) {
    let hash = 0;
    let i;
  
    /* eslint-disable no-bitwise */
    for (i = 0; i < string.length; i += 1) {
      hash = string.charCodeAt(i) + ((hash << 5) - hash);
    }
  
    let color = '#';
  
    for (i = 0; i < 3; i += 1) {
      const value = (hash >> (i * 8)) & 0xff;
      color += `00${value.toString(16)}`.substr(-2);
    }
    /* eslint-enable no-bitwise */
  
    return color;
  }
  
  
  
  if (!isInitialized)
	{
	InitializeData();
	setIsInitialized(true);
	}
  return (    
  
  <AppBar  class="myAppBar" position="static">
      <LocalizationProvider dateAdapter={AdapterDayjs}>
      <Container maxWidth="false" disableGutters="true">
        <Toolbar>
        <Box sx={{ flexGrow: 1 }}>
     <Grid container spacing={1}>
               
                
     <FormControl sx={{ m:1,minWidth: 85 }}>
                <DatePicker 
                className="datePicker"
          label="Match Date"
          inputFormat="MM/DD/YYYY"
          value={myDateValue}
          onChange={handleDateChange}
          renderInput={(params) => <TextField {...params} />}
        />


                </FormControl>   
              <FormControl sx={{ m:1,minWidth: 80 }}>
              <InputLabel size="small" id="DEFAULT_CLUB_UID">Club</InputLabel>
                <Select
                  size="small"
                  labelId="DEFAULT_CLUB_UID"
                  id="DEFAULT_CLUB_UID"
                  value={selectedClub}
                  label="DEFAULT_CLUB_UID"
                  name="DEFAULT_CLUB_UID"
                  onChange={(e) => handleChangeClub(e)}
                  
                >
                  {clubRows.map((club, i) => {
                                      return (
                  <MenuItem value={club.CLUB_ID}>{club.CLUB_NAME}</MenuItem>
                                        
                                      );
                  }
                  )
                  }
                
                </Select>
                
                
               
                 
          
    </FormControl>	
    
                  <FormControl sx={{ m:1,minWidth: 80 }}>
              <InputLabel size="small" id="DEFAULT_TEAM_UID">Team</InputLabel>
                <Select
                  size="small"
                  labelId="DEFAULT_TEAM_UID"
                  id="DEFAULT_TEAM_UID"
                  value={((selectedTeam == null)? "" : selectedTeam)}
                  label="DEFAULT_TEAM_UID"
                  name="DEFAULT_TEAM_UID"
                    onChange={(e) => handleChangeTeam(e)}
                  
                >
                 
                    {teamRows.map((team, i) => {
                                      return (
                  <MenuItem value={team.TEAM_ID}>{team.TEAM_NAME}</MenuItem>
                                        
                                      );
                  }
                
                    )
                  }
                  
                
                </Select>
                </FormControl>
                 {(showFavorites) ? (
               <Chip icon={<FavoriteIcon color="#accce3"/>} label="" onClick={() => handleShowFavorites()}/> 
                )
                :(
                  <Chip  icon={<FavoriteBorderIcon color="#accce3" />} label="Show Favs" onClick={() => handleShowFavorites()}/>
                )
                }
                </Grid >
                </Box>
          
        </Toolbar>
      </Container>
      </LocalizationProvider>
    </AppBar>
  );
};
export default SettingsAppBarAll;