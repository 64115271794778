import React, { useState } from "react";
import Button from "../components/Button";
import ViewGame from "../ViewGame";

import { Link } from "react-router-dom";

import "../assets/css/style.css";

export default function ViewGameRoute() {
 
  

 
  return (
    <div className="App">
   

     
    
  
    <ViewGame />
    </div>
   
  );
}

