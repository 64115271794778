
import React, { useState, useEffect } from "react";
import ImageList from '@mui/material/ImageList';
import ImageListItem from '@mui/material/ImageListItem';
import Userfront from "@userfront/react";
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Unstable_Grid2';

function srcset(image: string, size: number, rows = 1, cols = 1) {
  return {
    src: `${image}?w=${size * cols}&h=${size * rows}&fit=crop&auto=format`,
    srcSet: `${image}?w=${size * cols}&h=${
      size * rows
    }&fit=crop&auto=format&dpr=2 2x`,
  };
}

export default function QuiltedImageList() {
  const [sRows, setSRows] = useState([]);
  const [isInitialized,setIsInitialized] = React.useState(false);
  
  const snowContainer = document.getElementById("snow-container");
const snowContent = ['&#10052', '&#10053', '&#10054']

const random = (num) => {
  return Math.floor(Math.random() * num);
}

const getRandomStyles = () => {
  const top = random(100);
  const left = random(100);
  const dur = random(10) + 10;
  const size = random(25) + 25;
  return `
    top: -${top}%;
    left: ${left}%;
    font-size: ${size}px;
    animation-duration: ${dur}s;
  `;
}

const createSnow = (num) => {
  for (var i = num; i > 0; i--) {
    var snow = document.createElement("div");
    snow.className = "snow";
    snow.style.cssText = getRandomStyles();
    snow.innerHTML = snowContent[random(3)]
   if (snowContainer )
   snowContainer.append(snow);
  }
}
  
  const InitializeData = () => {
   
		const requestOptions = {
			method: 'GET',
			headers: { 'Content-Type': 'application/json', 
			Authorization: `Bearer ${Userfront.tokens.accessToken}` }
		  };
	  
		//fetch game action is in progress

		//fetch game action is in progress
	fetch("/api/rballGetHomeImages",requestOptions)
		.then((res)  => {
			if (res.status != 404)
			{
			  return res.json()
			}
			else
			{
				setSRows([]);
			  return res;
			}
			}
		  )
			   .then((res)=> {
				if (res.status != 404)
				{
					setSRows(res);
					
				}
				
					
				})
			.catch(error => {
			  //console.log("Set Matches throw me an error " +  error)
				}  )

		 
		



	  }
 
 
    if ((!isInitialized) )
    {
    InitializeData();
    setIsInitialized(true);	
    }
    // uncommebnt to add snow
    /// createSnow(30);
    //const [seconds, setSeconds] = useState(0);
    //useEffect(() => {
     // const interval = setInterval(() => {
     //   createSnow(30);
     // }, 60000);
    //  return () => clearInterval(interval);
   /// }, []);
  return (
    <>
 <div className="match">
<div id="snow-container" >

    <Container maxWidth="xl">
    
     <Box sx={{ flexGrow: 2, display: { xs: 'flex', md: 'flex' } }}>
     <Grid >
     <item >
     <Box display="flex" justifyContent="center">
     <Grid >
     <item >
     


     <Typography
            variant="h5"
            Wrap
          
           
            >
                      Welcome to Racquetball.fun! 
                      </Typography>
                      </item>
                      
     <item >
          <Typography>           
                      The funnest racquetball site in the world! 
          </Typography>
          
          </item>
          </Grid>
          </Box>
          </item>  
          <main>
  <h1>Join us this weekend @MIZZOU</h1>
</main>
          <item>
       <ImageList
      sx={{ height: 650 }}
      variant="quilted"
      cols={4}
      rowHeight={160}
    >
      {sRows.map((item) => (
      <>
      
      <ImageListItem key={item.img} cols={item.cols || 1} rows={item.rows || 1}>
          <img
             {...srcset(item.img, 500, item.rows, item.cols)}
            alt={item.title}
            loading="lazy"
          />
        </ImageListItem>
             </>
      ))}
    </ImageList>
    </item>
    </Grid>
    
    </Box>
    </Container>
    </div>
    </div>
    </>
  );
}

