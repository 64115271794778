import React, { useState, useEffect } from "react";
import CreateIcon from "@material-ui/icons/Create";

import AppBar from "./appBar";

import Paper from '@mui/material/Paper';
import TableContainer from '@mui/material/TableContainer';
import TablePagination from '@mui/material/TablePagination';

import {
    Box, Button, Snackbar, Table,
    TableBody, TableCell, TableHead, TableRow
} from "@material-ui/core";
import DeleteOutlineIcon from "@material-ui/icons/DeleteOutline";
import AddBoxIcon from "@material-ui/icons/AddBox";
import DoneIcon from "@material-ui/icons/Done";
import ClearIcon from "@material-ui/icons/Clear";
import { makeStyles } from "@material-ui/core/styles";
import Alert from "@material-ui/lab/Alert";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import SportsHandballIcon from '@material-ui/icons/SportsHandball';
import AutorenewIcon from '@material-ui/icons/Autorenew';
import DeleteIcon from '@material-ui/icons/Delete';
import SportsTennisIcon from '@material-ui/icons/SportsTennis';
import EmojiPeopleIcon from '@material-ui/icons/EmojiPeople';
import EditIcon from '@material-ui/icons/Edit';
import AddIcon from '@material-ui/icons/Add';
import SaveIcon from '@material-ui/icons//Save';

import FavoriteIcon from '@mui/icons-material/Favorite';
import FavoriteBorderIcon from '@mui/icons-material/FavoriteBorder';

import Chip from '@mui/material/Chip';

import Userfront from "@userfront/react";

// Creating styles
const useStyles = makeStyles({
	root: {
		"& > *": {
			borderBottom: "unset",
		},
	},

	snackbar: {
		bottom: "104px",
	},
});

function MyFavoriteClubs() {
	// Creating style object
	const classes = useStyles();

	// Defining a state named rows
	// which we can update by calling on setRows function
	const [rows, setRows] = useState([]);
  const [sRows, setSRows] = useState([])
  

 
  //const [rows, setRows] = useState([
//		{ id: 1, firstname: "", lastname: "", city: "" },
//	]);

	// Initial states
	const [open, setOpen] = React.useState(false);
	const [isEdit, setEdit] = React.useState(false);
  const [editRow, setEditRow] = React.useState(-1);
  const [isAdd, setIsAdd] = React.useState(false);
	const [disable, setDisable] = React.useState(true);
	const [showConfirm, setShowConfirm] = React.useState(false);
	const [page, setPage] = React.useState(0);
	const [loggedInUser,setLoggedInUser] = React.useState(Userfront.user);
	const [isInitialized,setIsInitialized] = React.useState(false);
	const [showFavorites,setShowFavorites] = React.useState(true);



	const handleShowFavorites = (i) => {
		setShowFavorites(!showFavorites);
		const list = [...sRows];
		
		list[i].IS_FAVORITE = !sRows[i].IS_FAVORITE;
		if (list[i].IS_FAVORITE ==true)
		setFavoriteClub(list[i]);
		else
		unsetFavoriteClub(list[i]);

		setSRows(list);
		  } 
	
	// Showing delete confirmation to users
	const handleInitialized = () => {
		setIsInitialized(!isInitialized);
	};
	const InitializeData = () => {
   
		
	  
		//fetch game action is in progress
		fetch("/api/myFavoriteClubs",
		{
		  method: "GET",
		  headers: {
			"Content-Type": "application/json",
			Authorization:`Bearer ${Userfront.tokens.accessToken}`
			  
		  },
		})
		.then((res) => {
			return res.json();
		})
		.then((FavoriteClubsData)=> {
		
		 setSRows(FavoriteClubsData);
		   
	   })
	   .catch(error => {
		   //console.log("throw me an error")
	   		}  
		   );
	  }
	  
	  const appendLeadingZeroes= (n)  => {
		if (n <= 9) {
		  return "0" + n;
		}
		return n
	  }
const setFavoriteClub = (FavoriteClub) => {


		const setFavoriteClub=FavoriteClub;
		var current_datetime = new Date();
		var formatted_date = current_datetime.getFullYear() + "-" +
		  appendLeadingZeroes(current_datetime.getMonth() + 1) + "-" +
		  appendLeadingZeroes(current_datetime.getDate()) + "_" +
		  appendLeadingZeroes(current_datetime.getHours()) + ":" +
		  appendLeadingZeroes(current_datetime.getMinutes()) + ":" +
		  appendLeadingZeroes(current_datetime.getSeconds());
	
		  //console.log(formatted_date)
		  setFavoriteClub.DATE_CREATED=formatted_date

		const requestOptions = {
		  method: 'POST',
		  headers: { 'Content-Type': 'application/json', 
		  Authorization: `Bearer ${Userfront.tokens.accessToken}` },
		  body: JSON.stringify(setFavoriteClub)
	  };
	  fetch('api/myFavoriteClubs', requestOptions)
	 
		  .then(response => response.json())
		  .then(data => {
			  //console.log(data.FavoriteClubs_ID)
			  //still dont think we need this 12/8/22
			  //InitializeData();
		  })
	  }  

const unsetFavoriteClub = (FavoriteClub) => {


		const unsetFavoriteClub=FavoriteClub;

		const requestOptions = {
		  method: 'DELETE',
		  headers: { 'Content-Type': 'application/json', 
		  Authorization: `Bearer ${Userfront.tokens.accessToken}` },
		  body: JSON.stringify(unsetFavoriteClub)
	  };
	  fetch('api/myFavoriteClubs', requestOptions)
		  .then(response => response.json())
		  .then(data => {
			  InitializeData();
		  })
	  }  
const updateFavoriteClubs = (FavoriteClub) => {


		const updateFavoriteClubs=FavoriteClub;
		var current_datetime = new Date();
		var formatted_date = current_datetime.getFullYear() + "-" +
		  appendLeadingZeroes(current_datetime.getMonth() + 1) + "-" +
		  appendLeadingZeroes(current_datetime.getDate()) + "_" +
		  appendLeadingZeroes(current_datetime.getHours()) + ":" +
		  appendLeadingZeroes(current_datetime.getMinutes()) + ":" +
		  appendLeadingZeroes(current_datetime.getSeconds());
	
		  //console.log(formatted_date)
		  updateFavoriteClubs.DATE_UPDATED=formatted_date

		const requestOptions = {
		  method: 'PUT',
		  headers: { 'Content-Type': 'application/json', 
		  Authorization: `Bearer ${Userfront.tokens.accessToken}` },
		  body: JSON.stringify(updateFavoriteClubs)
	  };
	  fetch('api/myFavoriteClubs', requestOptions)
		  .then(response => response.json())
		  .then(data => {
			  InitializeData();
		  })
	  }  


	const handleChangePage = (event, newPage) => {
		setPage(newPage);
	  };


	  const handleChangeRowsPerPage = (event) => {
		setRowsPerPage(parseInt(event.target.value, 10));
		setPage(0);
	  };

	  const [rowsPerPage, setRowsPerPage] = React.useState(5);
  // Function For closing the alert snackbar
	const handleClose = (event, reason) => {
		if (reason === "clickaway") {
			return;
		}
		setOpen(false);
	};


  



  // Function to handle saveFavoriteClubss
	const handleSetFavoriteClubs = (i) => {
		
	setFavoriteClub(sRows[i]);
	// I dont think we need this 12/8/22
	//InitializeData();
	};






	const emptyRows =
    page > 0 ? Math.max(0, (1 + page) * rowsPerPage - rows.length) : 0;

	if (!isInitialized)
	{
	InitializeData();
	setIsInitialized(true);
	}
return (
  
 <div >
  
  <Table className="myMatch">
	<TableBody >
	<Snackbar
		open={open}
		autoHideDuration={2000}
		onClose={() => handleClose()}
		className={classes.snackbar}
	>
		<Alert onClose={() => handleClose()} severity="success">
		Record saved successfully!
		</Alert>
	</Snackbar>
 
    
    
		<TableRow align="center"></TableRow>
  
  <TableRow>
    <TableCell className="matchCell" colSpan={2}>
   

    <Box sx={{ width: '100%' }}>
      <Paper sx={{ width: '100%', mb: 2 }}>
        
        <TableContainer>
    <Table
		 className="myMatch"
		size="small"
		aria-label="a dense table"
		>
		<TableHead>
			<TableRow>
			<TableCell>Favorite Clubs</TableCell>
			<TableCell align="center"></TableCell>
			</TableRow>
		</TableHead>
		<TableBody>
      {(sRows) && (
        <> 
			{sRows.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row, i) => {
			return (
				<>
				<TableRow>
					
					<>
						<TableCell component="th" scope="row">
						{row.CLUB_NAME}
						
						
					
						
		{(row.IS_FAVORITE) ? (
                <Chip icon={<FavoriteIcon />} label="" onClick={() => handleShowFavorites(i + (page * rowsPerPage ))}/>
                )
                :(
                
				<Chip icon={<FavoriteBorderIcon />} label="Add to favorites" onClick={() => handleShowFavorites(i + (page * rowsPerPage ))}/>
                )
                }
		
          </TableCell>
          
          </>

					
				
					
				</TableRow>
				</>
			);
			})}
      </>
      )}
		</TableBody>
		</Table>
		</TableContainer>
        <TablePagination
          rowsPerPageOptions={[5, 10, 25]}
          component="div"
          count={(sRows) ? (sRows.length) : (0)}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </Paper>
     
    </Box>
    </TableCell>
  </TableRow>
	</TableBody>
  </Table>

  </div>
);
}
    export default MyFavoriteClubs;