import React, { useState } from "react";
import Button from "../components/Button";
import MyGameDashboard from "../MyGameDashboard";

import { Link } from "react-router-dom";

import "../assets/css/style.css";


export default function MyGameDashboardRoute() {
 
  

 
  return (
    <div className="App">

    <MyGameDashboard />
    </div>
   
  );
}

