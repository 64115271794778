import React, { useState, useEffect } from "react";
import CreateIcon from "@material-ui/icons/Create";

import AppBar from "./appBar";
import SettingsAppBar from "./SettingsAppBar";

import Paper from '@mui/material/Paper';
import TableContainer from '@mui/material/TableContainer';
import TablePagination from '@mui/material/TablePagination';

import {
    Box, Button, Snackbar, Table,
    TableBody, TableCell, TableHead, TableRow
} from "@material-ui/core";
import Avatar from '@mui/material/Avatar';
import IconButton from '@material-ui/core/IconButton';
import PhotoCamera from '@material-ui/icons/PhotoCamera';
import DeleteOutlineIcon from "@material-ui/icons/DeleteOutline";
import AddBoxIcon from "@material-ui/icons/AddBox";
import DoneIcon from "@material-ui/icons/Done";
import ClearIcon from "@material-ui/icons/Clear";
import { makeStyles } from "@material-ui/core/styles";
import Alert from "@material-ui/lab/Alert";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import SportsHandballIcon from '@material-ui/icons/SportsHandball';
import AutorenewIcon from '@material-ui/icons/Autorenew';
import DeleteIcon from '@material-ui/icons/Delete';
import SportsTennisIcon from '@material-ui/icons/SportsTennis';
import EmojiPeopleIcon from '@material-ui/icons/EmojiPeople';
import EditIcon from '@material-ui/icons/Edit';
import AddIcon from '@material-ui/icons/Add';
import SaveIcon from '@material-ui/icons//Save';

import Userfront from "@userfront/react";

const requestOptions = {
    method: 'GET',
    headers: { 'Content-Type': 'application/json', 
    Authorization: `Bearer ${Userfront.tokens.accessToken}` }
  };
  
// Creating styles
const useStyles = makeStyles({
	root: {
		"& > *": {
			borderBottom: "unset",
		},
	},

	snackbar: {
		bottom: "104px",
	},
});

function MyPlayers() {
	// Creating style object
	const classes = useStyles();

	// Defining a state named rows
	// which we can update by calling on setRows function
	const [rows, setRows] = useState([]);
  const [sRows, setSRows] = useState([])
  

 
  //const [rows, setRows] = useState([
//		{ id: 1, firstname: "", lastname: "", city: "" },
//	]);

	// Initial states
	const [open, setOpen] = React.useState(false);
	const [isEdit, setEdit] = React.useState(false);
  const [editRow, setEditRow] = React.useState(-1);
  const [isAdd, setIsAdd] = React.useState(false);
	const [disable, setDisable] = React.useState(true);
	const [showConfirm, setShowConfirm] = React.useState(false);
	const [page, setPage] = React.useState(0);
	const [loggedInUser,setLoggedInUser] = React.useState(Userfront.user);
	const [isInitialized,setIsInitialized] = React.useState(false);

const [selectedMatch, setSelectedMatch] = useState(null);
const [stateChanger, setStateChanger] = useState(false);
const [selectedTeam, setSelectedTeam] = useState(null);
const [selectedClub, setSelectedClub] = useState(null);
const [imageUpload, setImageUpload] = useState(null);
const [settingAppBarLoaded, setSettingAppBarLoaded] = useState(false);



	//useEffect(() => {
	//	InitializeData()
	//	})
	
	// Showing delete confirmation to users
	const handleInitialized = () => {
		setIsInitialized(!isInitialized);
	};

	const stringToColor=(string) =>{
		let hash = 0;
		let i;
	  
		/* eslint-disable no-bitwise */
		for (i = 0; i < string.length; i += 1) {
		  hash = string.charCodeAt(i) + ((hash << 5) - hash);
		}
	  
		let color = '#';
	  
		for (i = 0; i < 3; i += 1) {
		  const value = (hash >> (i * 8)) & 0xff;
		  color += `00${value.toString(16)}`.substr(-2);
		}
		/* eslint-enable no-bitwise */
	  
		return color;
	  };

	  const stringAvatar=(name) =>{
		{
		  
		  var avatarInitials
		 if (name)
		 {
			if (name.includes(' ')) 
			{
			avatarInitials=`${name.split(' ')[0][0]}${name.split(' ')[1][0]}`;
			}
			else
			{
	  
			  avatarInitials=name.charAt(0)
			}
	  
		 }
		 
		  else
		  {
		  name="unknown";
		  avatarInitials="?";
		  }
	  
		  return {
			sx: {
			  bgcolor: stringToColor(name),
			},
			children: avatarInitials,
		  };
		}
	  };
	const InitializeData = () => {
   
		const requestOptions = {
			method: 'GET',
			headers: { 'Content-Type': 'application/json', 
			Authorization: `Bearer ${Userfront.tokens.accessToken}` }
		  };
	  
		//fetch game action is in progress
		fetch("/api/myPlayers?rbt="+selectedTeam,requestOptions)
		.then((res)  => {
            if (res.status != 404)
            {
              return res.json()
            }
            else
            {
				setSRows([]);
              return res;
            }
            }
          )
               .then((res)=> {
                if (res.status != 404)
                {
						setSRows(res);
                }
                
                    
                })
            .catch(error => {
              //console.log("throw me an error on players data" +  error)
                }  )
		  }
	  
	  const appendLeadingZeroes= (n)  => {
		if (n <= 9) {
		  return "0" + n;
		}
		return n
	  }
const insertPLAYER = (PLAYER) => {


		const insertPLAYER=PLAYER;
		var current_datetime = new Date();
		var formatted_date = current_datetime.getFullYear() + "-" +
		  appendLeadingZeroes(current_datetime.getMonth() + 1) + "-" +
		  appendLeadingZeroes(current_datetime.getDate()) + "_" +
		  appendLeadingZeroes(current_datetime.getHours()) + ":" +
		  appendLeadingZeroes(current_datetime.getMinutes()) + ":" +
		  appendLeadingZeroes(current_datetime.getSeconds());
	
		  //console.log(formatted_date)
		  insertPLAYER.DATE_CREATED=formatted_date

		const requestOptions = {
		  method: 'POST',
		  headers: { 'Content-Type': 'application/json',  
		  Authorization: `Bearer ${Userfront.tokens.accessToken}` },
		  body: JSON.stringify(insertPLAYER)
	  };
	  const formData = new FormData();
        formData.append("file", insertPLAYER.IMAGE_UPLOAD);
		formData.append("insertPLAYER", JSON.stringify(insertPLAYER));
	  const requestOptions2 = {
		method: 'POST',
		headers: {   
		  Authorization: `Bearer ${Userfront.tokens.accessToken}` },
		body: formData
	};
	//  fetch('api/rballUploadImage', requestOptions2)
	//  .then(response => response.json())
	//	  .then(data => {
	//		  console.log(data.PLAYER_ID)
	//		  InitializeData();
	//	  })
	    
	  fetch('api/myPlayers', requestOptions2)
	  
		  .then(data => {
			  ////console.log(data.PLAYER_ID)
			  InitializeData();
		  })
	  }  

const deletePLAYER = (PLAYER) => {


		const deletePLAYER=PLAYER;

		const requestOptions = {
		  method: 'DELETE',
		  headers: { 'Content-Type': 'application/json', 
		  Authorization: `Bearer ${Userfront.tokens.accessToken}` },
		  body: JSON.stringify(deletePLAYER)
	  };
	  fetch('api/myPlayers', requestOptions)
		  
		  .then(data => {
			  InitializeData();
		  })
	  }  
const updatePLAYER = (PLAYER) => {


		const updatePLAYER=PLAYER;
		var current_datetime = new Date();
		var formatted_date = current_datetime.getFullYear() + "-" +
		  appendLeadingZeroes(current_datetime.getMonth() + 1) + "-" +
		  appendLeadingZeroes(current_datetime.getDate()) + "_" +
		  appendLeadingZeroes(current_datetime.getHours()) + ":" +
		  appendLeadingZeroes(current_datetime.getMinutes()) + ":" +
		  appendLeadingZeroes(current_datetime.getSeconds());
	
		  //console.log(formatted_date)
		  updatePLAYER.DATE_UPDATED=formatted_date
		  const formData = new FormData();
		  formData.append("file", PLAYER.IMAGE_UPLOAD);
		  formData.append("insertPLAYER", JSON.stringify(PLAYER));
		const requestOptions = {
		  method: 'PUT',
		  headers: { 
		  Authorization: `Bearer ${Userfront.tokens.accessToken}` },
		  body: formData
	  };
	  fetch("api/myPlayers?rbt="+selectedTeam,requestOptions)
		  .then(response => response.json())
		  .then(data => {
			isInitialized(false);
		  })
	  }  


	const handleChangePage = (event, newPage) => {
		setPage(newPage);
	  };


	  const handleChangeRowsPerPage = (event) => {
		setRowsPerPage(parseInt(event.target.value, 10));
		setPage(0);
	  };

	  const [rowsPerPage, setRowsPerPage] = React.useState(25);
  // Function For closing the alert snackbar
	const handleClose = (event, reason) => {
		if (reason === "clickaway") {
			return;
		}
		setOpen(false);
	};

	// Function For adding new row object
	const handleAdd = () => {
		setIsAdd(true);
    if (rows)
    {
    setRows([
			...rows,
			{
				id: rows.length + 1,PLAYER_FIRST_NAME:"",PLAYER_LAST_NAME:"",PLAYER_SHORT_NAME: "", PLAYER_UID: null,DATE_CREATED: "",CREATEDBY_UID: null,PLAYER_OWNER_UID:loggedInUser.userUuid,TEAM_ID:selectedTeam,PLAYER_ID: null, IMAGE_UPLOAD: null
			},
		]);
   }
    else
    {
      setRows([
        rows,
        {
			id: rows.length + 1,PLAYER_FIRST_NAME:"",PLAYER_LAST_NAME:"",PLAYER_SHORT_NAME: "", PLAYER_UID: null,DATE_CREATED: "",CREATEDBY_UID: null,PLAYER_OWNER_UID:loggedInUser.userUuid,TEAM_ID:selectedTeam,PLAYER_ID: null, IMAGE_UPLOAD: null
        },
      ]);
    }
		setEdit(true);
    
	};
	// Function to handle cancel of edit
	const handleCancel = () => {
		// If edit mode is true setEdit will
		// set it to false and vice versa
		setEdit(!isEdit);
    setEditRow(-1)

	};

	// Function to handle edit
	const handleEdit = (i) => {
		// If edit mode is true setEdit will
		// set it to false and vice versa
		setEdit(!isEdit);
    setEditRow(i)

	};
  

	// Function to handle save
	const handleSave = () => {
		setEdit(false);
    setEditRow(-1);
    setIsAdd(false);
	 
    
    
    //console.log("Cleared : ", rows);
	insertPLAYER(rows[0]);
	setRows([]);
	
	};

  // Function to handle savePlayers
	const handleSavePlayers = (i) => {
		setEdit(false);
    setEditRow(-1);
    setIsAdd(false); 

	//console.log("saved : ", sRows[i]);
	setDisable(true);
	setOpen(true);
    
    setRows([]);
    //console.log("Cleared : ", rows);
	updatePLAYER(sRows[i]);	
	isInitialized(false);
	};

	  // Function to handle imageChange
	  const handleImageChange = (e, index) => {
		
		const name=  e.target.name;//files[0]
		const value=  e.target.files[0];//files[0]
			const list = [...rows];
		list[index][name] = value;
		setSRows(list);
	};
	 // Function to handle imageEdit
	 const handleImageEdit = (e, index) => {
		
		const name=  e.target.name;//files[0]
		const value=  e.target.files[0];//files[0]
			const list = [...sRows];
		list[index][name] = value;
		setSRows(list);
	};
	// The handleInputChange handler can be set up to handle
	// many different inputs in the form, listen for changes
	// to input elements and record their values in state
	const handleInputChange = (e, index) => {
		setDisable(false);
		const { name, value } = e.target;
		const list = [...rows];
		list[index][name] = value;
		setRows(list);
	};

  	// The handleInputChange handler can be set up to handle
	// many different inputs in the form, listen for changes
	// to input elements and record their values in state
	const handleInputChangePlayers = (e, index) => {
		setDisable(false);
		const { name, value } = e.target;
		const list = [...sRows];
		list[index][name] = value;
		setSRows(list);
	};


	// Showing delete confirmation to users
	const handleConfirm = () => {
		setShowConfirm(true);
	};

	// Handle the case of delete confirmation where
	// user click yes delete a specific row of id:i
	const handleRemoveClick = (i) => {
		const list = [...rows];
		list.splice(i, 1);
		setRows(list);
		setShowConfirm(false);
    setIsAdd(false);
	};


  const handleRemovePlayersClick = (i) => {
		const list = [...sRows];
		deletePLAYER(sRows[i])
		list.splice(i, 1);
		setSRows(list);
		setShowConfirm(false);
    setIsAdd(false);

	InitializeData();
	};

	// Handle the case of delete confirmation
	// where user click no
	const handleNo = () => {
		setShowConfirm(false);
	};
	const emptyRows =
    page > 0 ? Math.max(0, (1 + page) * rowsPerPage - rows.length) : 0;

	if ((!isInitialized) || (stateChanger))
	{
	InitializeData();
	setIsInitialized(true);	
	setStateChanger(false);
	}
	
return (
  
 <div >
  

  <SettingsAppBar mStateChanger={setStateChanger} mSelectedClub={setSelectedClub} mSelectedTeam={setSelectedTeam} msetSettingAppBarLoaded={setSettingAppBarLoaded}/>

  <Table className="myMatch">
	<TableBody >
	<Snackbar
		open={open}
		autoHideDuration={2000}
		onClose={() => handleClose()}
		className={classes.snackbar}
	>
		<Alert onClose={() => handleClose()} severity="success">
		Record saved successfully!
		</Alert>
	</Snackbar>
  <TableRow align="center">
    <TableCell  className="PLAYERCell">
    Players
    </TableCell>
    <TableCell className="addButton" align="left">
	
      
      
      
			{isEdit ? (
			<>
        {(isAdd == true )? (
					<Button disabled align="right" onClick={() => handleAdd()}>
					<AddBoxIcon onClick={() => handleAdd()}/>
				ADD PLAYER
				</Button>
					) : (
            <Button onClick={() => handleAdd()}>
            <AddBoxIcon onClick={() => handleAdd()}/>
            ADD PLAYER
            </Button>
					)}
			
			</>	
			
			) : (	
			<>
				{(settingAppBarLoaded== false )? (
					<Button disabled align="right" onClick={() => handleAdd()}>
					<AddBoxIcon onClick={() => handleAdd()}/>
				ADD PLAYER
				</Button>
					) : (
            <Button onClick={() => handleAdd()}>
            <AddBoxIcon onClick={() => handleAdd()}/>
            ADD PLAYER
            </Button>
					)}
		
			</>
			)}
		
		</TableCell>
    </TableRow>
    
    
		<TableRow align="center"></TableRow>
    <TableRow><TableCell colSpan={2}>
    {isAdd && (
      <>    
		<Table
		className="myMatch"
		size="small"
		aria-label="a dense table"
		>
     
		<TableHead>
			<TableRow>
			<TableCell>PLAYER Fist Name</TableCell>
			<TableCell>PLAYER Last Name</TableCell>
			<TableCell>PLAYER Nick Name</TableCell>
			<TableCell align="center"></TableCell>
			</TableRow>
		</TableHead>
		<TableBody>
      {(rows) && (
        <>
			{rows.map((row, ii) => {
			return (
				<>
				<TableRow>
					{isEdit ? (
					<>
						<TableCell padding="none">
						<input
							value={row.PLAYER_FRIST_NAME}
							name="PLAYER_FIRST_NAME"
							 onChange={(e) => handleInputChange(e, ii)}
						/>
						</TableCell>
						<TableCell padding="none">
						<input
							value={row.PLAYER_LAST_NAME}
							name="PLAYER_LAST_NAME"
							 onChange={(e) => handleInputChange(e, ii)}
						/>
						</TableCell>
						<TableCell padding="none">
						<input
							value={row.PLAYER_SHORT_NAME}
							name="PLAYER_SHORT_NAME"
							onChange={(e) => handleInputChange(e, ii)}
						/>
						 
						<IconButton className="mr10" name="file_BUTTON"aria-label="upload picture" component="label" onChange={(e) => handleImageChange(e, ii)}>
							<input hidden name="IMAGE_UPLOAD"  type="file" />
							<PhotoCamera />
						</IconButton>
            {rows.length !== 0 && (
				<>
					{disable ? (
					<Button disabled align="right" onClick={() => handleSave()}>
						<DoneIcon />
						SAVE
					</Button>
					) : (
					<Button align="right" onClick={() => handleSave()}>
						<DoneIcon />
						SAVE
					</Button>
					)}
				</>
				)}

          {isEdit  ? (
					<Button className="mr10" onClick={() => handleRemoveClick(ii)}>
						<ClearIcon />
					</Button>
					) : (
					<Button className="mr10" onClick={() => handleRemoveClick(ii)}>
						<DeleteOutlineIcon />
					</Button>
					)}

						</TableCell>
					
            </>
					) : (
					<>
						<TableCell component="th" scope="row">
						<Avatar {...stringAvatar(row.PLAYER_FIRST_NAME)} src={"/static/public/" + row.PLAYER_UID + ".JPEG"}/>	
						{row.PLAYER_FIRST_NAME}
						</TableCell>
						<TableCell component="th" scope="row">
						{row.PLAYER_LAST_NAME}
						</TableCell>
						<TableCell component="th" scope="row">
						{row.PLAYER_SHORT_NAME}
						</TableCell>
					
						<TableCell
						component="th"
						scope="row"
						align="center"
						>

            </TableCell>
					</>
					)}
				
					
				</TableRow>
				</>
			);
			})}
      </>
      )}
		</TableBody>
		</Table>
    </>
    )
  }
  </TableCell>
  </TableRow>
  <TableRow>
    <TableCell className="matchCell" colSpan={2}>
   

    <Box sx={{ width: '100%' }}>
      <Paper sx={{ width: '100%', mb: 2 }}>
        
        <TableContainer>
    <Table
		 className="myMatch"
		size="small"
		aria-label="a dense table"
		>
		<TableHead>
			<TableRow>
			<TableCell>PLAYER Name</TableCell>
			<TableCell>PLAYER Last Name</TableCell>
			<TableCell>PLAYER Nick Name</TableCell>
			<TableCell align="center"></TableCell>
			</TableRow>
		</TableHead>
		<TableBody>
      {(sRows) && (
        <> {}
			{sRows.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row, i) => {
			return (
				<>
				<TableRow>
					{isEdit   && i==editRow ? (
					<>
						<TableCell padding="none">
						<input
							value={row.PLAYER_FIRST_NAME}
							name="PLAYER_FIRST_NAME"
							 onChange={(e) => handleInputChangePlayers(e, i + (page * rowsPerPage ))}
						/>
						</TableCell>
						<TableCell padding="none">
						<input
							value={row.PLAYER_LAST_NAME}
							name="PLAYER_LAST_NAME"
							 onChange={(e) => handleInputChangePlayers(e, i + (page * rowsPerPage ))}
						/>
						</TableCell>
						<TableCell padding="none" colSpan={2}>
						<input
							value={row.PLAYER_SHORT_NAME}
							name="PLAYER_SHORT_NAME"
							onChange={(e) => handleInputChangePlayers(e, i + (page * rowsPerPage ))}
						/>
						
						<IconButton className="mr10" name="IMAGE_UPLOAD_BUTTON" aria-label="upload picture" component="label" onChange={(e) => handleImageEdit(e, i + (page * rowsPerPage ))}>
							<input hidden name="IMAGE_UPLOAD"  type="file" />
							<PhotoCamera />
						</IconButton>
						<Button align="right" onClick={() => handleSavePlayers(i + (page * rowsPerPage ))}>
						<DoneIcon />
					
					</Button>
          <Button className="mr10" onClick={() => handleCancel()}>
						<ClearIcon />
					</Button>
          </TableCell>
            </>
					) : (
					<>
						<TableCell component="th" scope="row">
						<img src={"static/public/" +row.PLAYER_UID + ".JPEG"} alt="Italian Trulli" hidden/>
						<Avatar {...stringAvatar(row.PLAYER_FIRST_NAME)} src={"static/public/" +row.PLAYER_UID + ".JPEG"}/>	
						
						{row.PLAYER_FIRST_NAME}
						</TableCell>
						<TableCell component="th" scope="row">
						{row.PLAYER_LAST_NAME}
						</TableCell>
						<TableCell component="th" scope="row">
						{row.PLAYER_SHORT_NAME}
						</TableCell>
					
						<TableCell
						component="th"
						scope="row"
						align="center"
						>
		<Button align="right" onClick={(e) => handleEdit( i + (page * rowsPerPage ))}>
				<CreateIcon />
				EDIT
				</Button>
          
        <Button className="mr10" onClick={() => handleConfirm()}>
						<DeleteOutlineIcon />
					</Button>
          </TableCell>
          
          </>

					)}
				
					{showConfirm && (
					<>
						<Dialog
						open={showConfirm}
						onClose={handleNo}
						aria-labelledby="alert-dialog-title"
						aria-describedby="alert-dialog-description"
						>
						<DialogTitle id="alert-dialog-title">
							{"Confirm Delete"}
						</DialogTitle>
						<DialogContent>
							<DialogContentText id="alert-dialog-description">
							Are you sure to delete
							</DialogContentText>
						</DialogContent>
						<DialogActions>
							<Button
							onClick={() => handleRemovePlayersClick(i + (page * rowsPerPage ))}
							color="primary"
							autoFocus
							>
							Yes
							</Button>
							<Button
							onClick={handleNo}
							color="primary"
							autoFocus
							>
							No
							</Button>
						</DialogActions>
						</Dialog>
					</>
					)}
				</TableRow>
				</>
			);
			})}
      </>
      )}
		</TableBody>
		</Table>
		</TableContainer>
        <TablePagination
          rowsPerPageOptions={[5, 10, 25]}
          component="div"
          count={sRows.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </Paper>
     
    </Box>
    </TableCell>
  </TableRow>
	</TableBody>
  </Table>

  </div>
);
}
    export default MyPlayers;