import React, { useState } from "react";
import { useParams } from "react-router-dom";
import CreateIcon from "@material-ui/icons/Create";
import Userfront from "@userfront/react";



import MyGamesRedirect from "./MyGamesRedirect";


import {
    Box, Button, Snackbar, Table,
    TableBody, TableCell, TableHead, TableRow,TextField
} from "@material-ui/core";

import Avatar from '@mui/material/Avatar';

import DeleteOutlineIcon from "@material-ui/icons/DeleteOutline";
import AddBoxIcon from "@material-ui/icons/AddBox";
import DoneIcon from "@material-ui/icons/Done";
import SendIcon from '@mui/icons-material/Send';

import PhotoCamera from '@material-ui/icons/PhotoCamera';
import ClearIcon from "@material-ui/icons/Clear";
import { makeStyles } from "@material-ui/core/styles";
import Alert from '@mui/material/Alert';
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import SportsHandballIcon from '@material-ui/icons/SportsHandball';
import AutorenewIcon from '@material-ui/icons/Autorenew';
import DeleteIcon from '@material-ui/icons/Delete';
import SportsTennisIcon from '@material-ui/icons/SportsTennis';
import EmojiPeopleIcon from '@material-ui/icons/EmojiPeople';
import FmdBadIcon from '@mui/icons-material/FmdBad';
import DoneAllIcon from '@material-ui/icons/DoneAll';
import PriorityHighIcon from '@material-ui/icons/PriorityHigh';
import TrendingDownIcon from '@material-ui/icons/TrendingDown';
import PinchIcon from '@mui/icons-material/Pinch';
import InsightsIcon from '@mui/icons-material/Insights';
import Checkbox from '@mui/material/Checkbox';
import LocalFireDepartmentIcon from '@mui/icons-material/LocalFireDepartment';  
import FormControlLabel from '@mui/material/FormControlLabel';
import OutboundIcon from '@mui/icons-material/Outbound';
import SettingsBackupRestoreIcon from '@mui/icons-material/SettingsBackupRestore';
import { fabClasses } from "@mui/material";
import ToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';
import IconButton from '@mui/material/IconButton';
import IosShareIcon from '@mui/icons-material/IosShare';
import Collapse from '@mui/material/Collapse';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';

import MenuIcon from '@mui/icons-material/Menu';
import CloseIcon from '@mui/icons-material/Close';

import AppBar from "./appBar";

function withParams(Component) {
  return props => <Component {...props} params={useParams()} />;
}

class ScoreGame extends React.Component {
  constructor(props) {
      super(props);
      this.state = {
        rballUser: Userfront.user,
          rows: [],
          raction_types: [],
          team1Score: 0,
          team2Score: 0,
          open: false,
          isEdit: false,
          disable: false,
          showConfirm: false,
          showText: true,
          isServing: false,
          firstServe: true,
          scoringAdvanced: false,
          pointAdvanced: false,
          sideoutAdcanced: false,
          scoring1stClick:true,
          serveNum: 0,
          actionType: "IDLE",
          servingPlayer: { playerUID: 0, playerID: 0, index: 0,playerName: "T1p1"},
          defendingPlayer: { playerUID: 0, playerID: 0, index: 0,playerName: "T2p1"},
          currentHostName: window.location.host,
          gameComment: null,
          commentFile: null,
          
          
          
          
          Game: 
          
         {
             gameID: 1,
             matchUID: null,
             numGames:3,
             gameNum:1,
             gameType: null,
             gameStatus: "InProgress",
             team1Serving: true,
             team1Score: null,
             team2Score: null,
             pointsToWin: null,
             teams: 
               [
              {
                  teamId: null,
                  teamUid: null,
                  teamName: null
              }
              ,  
              {
                teamId: null,
                teamUid: null,
                teamName: "null"
              }
              ],
            players: 
              [
              {
                  playerID: null,
                  playerUID: null,
                  index:0,
                  playerName: "null"               
              }
              ,  
              {
                playerID: null,
                playerUID: null,
                index:1,
                playerName: "null"                  
              }
              ,  
              {
                playerID: null,
                playerUID: null,
                index:2,
                playerName: "null"                
              }
              ,  
              {
                playerID: null,
                playerUID: null,
                index:3,
                playerName: "null"               
              }
              ]
            
         }
          ,
          team1Serving: true,
          game_id:0,
          rkey: 0,
          redirect: null,
          urlOpen: false,
          gameURL: null
                  };

  

  }
  
  
   appendLeadingZeroes= (n)  => {
		if (n <= 9) {
		  return "0" + n;
		}
		return n
	  }

  //  Initialize Data once
  handleInputCommentChange = (e) => {
		const { name, value } = e.target;
    this.setState({
      
      gameComment: value
      
        }); 
    
	
	};


 handleImageChange = (e) => {
		
    
   
  this.setState({
      
    setCommentFile: e.target.files[0]
    
      }); 
   
     
   };

   handleGameComment = () => {
		var Filter = require('bad-words'),
  filter = new Filter();
    const gameComments={};
		var current_datetime = new Date();
		var formatted_date = current_datetime.getFullYear() + "-" +
    this.appendLeadingZeroes(current_datetime.getMonth() + 1) + "-" +
    this.appendLeadingZeroes(current_datetime.getDate()) + "_" +
    this.appendLeadingZeroes(current_datetime.getHours()) + ":" +
    this.appendLeadingZeroes(current_datetime.getMinutes()) + ":" +
    this.appendLeadingZeroes(current_datetime.getSeconds());
	
		  //console.log(formatted_date)
		  gameComments.DATE_CREATED=formatted_date;
      gameComments.GAME_ID=this.state.Game.gameID;
      gameComments.GAME_COMMENT=filter.clean(this.state.gameComment);
      
		  const formData = new FormData();
		  formData.append("file", this.state.commentFile);
		  formData.append("insertPLAYER", JSON.stringify(gameComments));
		const requestOptions = {
		  method: 'POST',
		  headers: { 
		  Authorization: `Bearer ${Userfront.tokens.accessToken}` },
		  body: formData
	  };
	  fetch("/api/rballGameComments",requestOptions)
		  
		  .then(res => {
        this.setState({
          open: true,
          commentFile: null,
          gameComment: ""
          
            }); 
			  
            this.InitializeData();
		  })
     
	  }    


  InitializeData = () => {
   


      //fetch game action is in progress gameView
      fetch("/api/gameView?rbg="+this.props.params.game,
  {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization:`Bearer ${Userfront.tokens.accessToken}`
        
    },
  })
      .then((res) => res.json())
      .then((GameData)=> {
       
          this.setState({
        rows:GameData.raction,
        rkey: GameData.raction[GameData.raction.length-1].raction_id+1
           }); 
         })
      
         //fetch if a game is in progress
         //console.log("geeting: " + "/api/getScoreBoard?rbg="+this.props.params.game)
         fetch("/api/getScoreBoard?rbg="+this.props.params.game,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization:`Bearer ${Userfront.tokens.accessToken}`
            
        },
      }
      
      )
      .then((res) => {
        //console.log("here I am ");
        if (res.status=="403")
        {
          alert("Not Authorized. Please request access to this club.")
        }
        else
        return res.json();
      })
      .then((GameData)=> {
     //console.log("fetch returned");
     
     if (GameData[0].team1Serving)
     {
     
     this.setState({
        servingPlayer:{ playerUID: GameData[0].players[0].playerUID, playerID: GameData[0].players[0].playerID, index: 0, playerName: GameData[0].players[0].playerName},
        defendingPlayer: { playerUID: GameData[0].players[2].playerUID,playerID: GameData[0].players[2].playerID, index: 2, playerName: GameData[0].players[2].playerName},
        Game: GameData[0],
        team1Serving: GameData[0].team1Serving
        
          }); 
        }
     else
     {
      this.setState({
        servingPlayer:{ playerUID: GameData[0].players[2].playerUID,playerID: GameData[0].players[2].playerID, index: 2, playerName: GameData[0].players[2].playerName},
        defendingPlayer: { playerUID: GameData[0].players[0].playerUID, index: 0, playerName: GameData[0].players[0].playerName},
        Game: GameData[0],
        team1Serving: GameData[0].team1Serving
        
          }); 

     }

        })
      

//MAKE SURE TO SET AFTER GAME IS FETCHED


}

 stringToColor(string) {
  let hash = 0;
  let i;
  
  /* eslint-disable no-bitwise */
  for (i = 0; i < string.length; i += 1) {
    hash = string.charCodeAt(i) + ((hash << 5) - hash);
  }
  
  let color = '#';
  
  for (i = 0; i < 3; i += 1) {
    const value = (hash >> (i * 8)) & 0xff;
    color += `00${value.toString(16)}`.substr(-2);
  }
  /* eslint-enable no-bitwise */
  
  return color;
  };

   stringAvatar(name) {
  {
    
    var avatarInitials
   if (name)
   {
    if (name.includes(' ')) 
    {
    avatarInitials=`${name.split(' ')[0][0]}${name.split(' ')[1][0]}`;
    }
    else
    {
  
      avatarInitials=name.charAt(0)
    }
  
   }
   
    else
    {
    name="unknown";
    avatarInitials="?";
    }
  
    return {
    sx: {
      bgcolor: this.stringToColor(name),
    },
    children: avatarInitials,
    };
  }
  };

componentDidMount() {
   
this.InitializeData();

}


handleToggleChange = (event, defendingPlayer) => {
  //alert(defendingPlayer);
  this.setState({
    defendingPlayer: defendingPlayer
  })
};

  //  Handle all Scoring Button Clicks
  handleRActions = (actionType) => 
  {
    const clickedButtonName= actionType;
    switch (clickedButtonName) {
      case "Serve":
        this.handleServe(clickedButtonName,1);
        break;
    case "Finish":
      this.handleFinish(clickedButtonName,1);
      break;
    case "Back":
      this.setState({
        isServing: false,
        actionType: "IDLE",
        serveNum: this.state.serveNum -1,
        scoring1stClick: true,
        pointAdvanced: false,
        sideoutAdcanced: false
      })
    break;
      case "Ace":
      this.handleAce(clickedButtonName,1);
      break;
      case "1stServeFault":
        this.handle1stServeFault(clickedButtonName);
        break;
      case "2ndServeFault":
        this.handle2ndServeFault(clickedButtonName);
        break;
      case "Point":
        this.handlePoint(clickedButtonName);
        break;
      case "PointAdvanced":
        this.handlePointAdvanced(clickedButtonName);
        break;
      case "Splat":
        this.handleSplat(clickedButtonName);
        break;
      case "Rollout":
        this.handleRollout(clickedButtonName);
        break;
      case "Pinch":
        this.handlePinch(clickedButtonName);
        break;
      case "Unreturnable":
        this.handleUnreturnable(clickedButtonName);
        break;
      case "Sideout":
        this.handleSideout(clickedButtonName);
        break;
      case "SideoutAdvanced":
        this.handleSideoutAdvanced(clickedButtonName)
        break;
      case "Hinder":
        this.handleHinder(clickedButtonName);
        break;
      case 13:
        this.handleAddPoint(clickedButtonName);
        break;
      case 14:
        this.handleSubtractPoint(clickedButtonName);
        break;
      case "showText":
        this.setState({
          showText: !this.state.showText
        })
        break;
      case "scoringAdvanced":
      this.setState({
        scoringAdvanced: !this.state.scoringAdvanced
      })
      break;
    }        
  };
  setURLOpen(myBoolean){
    this.setState({
    urlOpen: myBoolean});
  }



  handleShare()
  {
    const gameURL="https://" + this.state.currentHostName + "/viewgame/game/" + this.state.Game.gameUID
    navigator.clipboard.writeText("https://" + this.state.currentHostName + "/viewgame/game/"+this.state.Game.gameUID)
    this.setState({gameURL: gameURL,
     urlOpen: true});

  }
  
  handleFinish(clickedButtonName,i)
  {
    //alert(clickedButtonName)
    
      //console.log("Game Finished- Go back to selected match: " +this.state.Game.matchUID)
      this.setState({ redirect: "/mymatches/match/"+this.state.Game.matchUID });
    }

  handleServe(clickedButtonName,i)
  {
    //alert(clickedButtonName)
    
      
      let raction = {
        game_id: this.state.Game.gameID, t1p1_id: this.state.Game.players[0].playerID,
        t1p2_id: this.state.Game.players[1].playerID, t2p1_id: this.state.Game.players[3].playerID,
        t2p2_id: this.state.Game.players[3].playerID, serving_p_id: this.state.servingPlayer.playerID,
        defending_p_id:this.state.defendingPlayer.playerID,
        raction_type_id: 50 ,
        raction:  this.state.servingPlayer.playerName +" Served",

        

        t1score: this.state.Game.team1Score, t2score: this.state.Game.team2Score};
        
      this.insertRAction(raction) 
      const listItem={
        raction_id: this.state.rows.length + 1 , display_type: "action",raction: this.state.servingPlayer.playerName +" Served",


        display_text: "Manual Entry - " + this.state.servingPlayer.playerName +" - Now Serving",
        createdby_name: this.state.rballUser.name,
        serving_p_name: this.state.servingPlayer.playerName,
        serving_p_uid: this.state.servingPlayer.playerUID,
        defending_p_uid: this.state.defendingPlayer.playerUID,
        defending_p_name: this.state.defendingPlayer.playerName,
        

        t1score: this.state.Game.team1Score, t2score: this.state.Game.team2Score};
        const list=[listItem, ...this.state.rows];
      
      this.setState(
      {   
        isServing: true,
        actionType: "Serving",
          edit: true,
        rkey: this.state.rkey+1,
        serveNum: this.state.serveNum +1,
        rows:list
        });

        this.updateGame();
    }
      
  
    switchServeT1 =() =>{
      const reqBody={};
      reqBody.matchUID=this.state.Game.matchID;
      
      const requestOptions = {
        method: 'PUT',
        headers: { 'Content-Type': 'application/json' ,
        Authorization: `Bearer ${Userfront.tokens.accessToken}` },
        body: JSON.stringify(reqBody)
    };
    fetch('/api/setupMatch?rbp1=true', requestOptions)
        .then(data => this.InitializeData());
    }

switchServeT2 =() =>{
      const reqBody={};
      reqBody.matchUID=this.state.Game.matchID;
      
      const requestOptions = {
        method: 'PUT',
        headers: { 'Content-Type': 'application/json' ,
        Authorization: `Bearer ${Userfront.tokens.accessToken}` },
        body: JSON.stringify(reqBody)
    };
    fetch('/api/setupMatch?rbp1=false', requestOptions)
        .then(data => this.InitializeData());
    }

switchServe= (manUpdate) => 
  {
    const rplayer = {};
    if (this.state.Game.gameType=="Singles") 
      {
        switch (this.state.servingPlayer.index) {
          case 0:
            
            rplayer.playerID=this.state.Game.players[2].playerID;
            rplayer.playerUID=this.state.Game.players[2].playerUID;
            rplayer.index=2;
             serveNum:
            rplayer.playerName=this.state.Game.players[2].playerName;  
          this.setState({
              team1Serving: !this.state.team1Serving,
              serveNum:  0,
              servingPlayer: rplayer,
              serveNum: 0,
              defendingPlayer: this.state.Game.players[0]
            });
            this.updateDBSwitchServe(manUpdate);
            break;
            
          case 2:
           
            rplayer.playerID=this.state.Game.players[0].playerID;
            rplayer.playerUID=this.state.Game.players[0].playerUID;
            rplayer.index=0;
            rplayer.playerName=this.state.Game.players[0].playerName; 
            this.setState({
              team1Serving: !this.state.team1Serving,
              serveNum:  0,
              servingPlayer: rplayer,
              serveNum: 0,
              defendingPlayer: this.state.Game.players[2]
            });
            this.updateDBSwitchServe(manUpdate);
            break;
           
  
        }
      }
      else{
          switch (this.state.servingPlayer.index) {
          case 0:
            if (this.state.firstServe)
            {
              
              rplayer.playerID=this.state.Game.players[2].playerID;
              rplayer.playerUID=this.state.Game.players[2].playerUID;
              rplayer.index=2;
              rplayer.playerName=this.state.Game.players[2].playerName; 
              this.setState({
                team1Serving: !this.state.team1Serving,
                serveNum:  0,
                firstServe: false,
                servingPlayer: rplayer,
                serveNum: 0,
                defendingPlayer: this.state.Game.players[0]
              });
              this.updateDBSwitchServe(manUpdate);
              break;
              
            } 
            else
            {
              
              rplayer.playerID=this.state.Game.players[1].playerID;
              rplayer.playerUID=this.state.Game.players[1].playerUID;
              rplayer.index=1;
              rplayer.playerName=this.state.Game.players[1].playerName; 
            this.setState({
              team1Serving: this.state.team1Serving,
              serveNum:  0,
              servingPlayer: rplayer,
              serveNum: 0,
              defendingPlayer: this.state.Game.players[2]
            });
            this.updateDBSwitchServe(manUpdate);   
            break;
            
          }
          case 1:
            
            rplayer.playerID=this.state.Game.players[2].playerID;
            rplayer.playerUID=this.state.Game.players[2].playerUID;
            rplayer.index=2;
            rplayer.playerName=this.state.Game.players[2].playerName; 
            this.setState({
              team1Serving: !this.state.team1Serving,
              serveNum:  0,
              servingPlayer: rplayer,
              defendingPlayer: this.state.Game.players[0]
            });
            this.updateDBSwitchServe(manUpdate);
            break;
          
          case 2:
            if (this.state.firstServe)
            {
              
              rplayer.playerID=this.state.Game.players[0].playerID;
              rplayer.playerUID=this.state.Game.players[0].playerUID;
              rplayer.index=0;
              rplayer.playerName=this.state.Game.players[0].playerName; 
              this.setState({
                team1Serving: !this.state.team1Serving,
                serveNum:  0,
                firstServe: false,
                servingPlayer: rplayer,
                defendingPlayer: this.state.Game.players[2]
              });
              this.updateDBSwitchServe(manUpdate);
              break;
            } 
            else
            {
              
              rplayer.playerID=this.state.Game.players[3].playerID;
              rplayer.playerUID=this.state.Game.players[3].playerUID;
              rplayer.index=3;
              rplayer.playerName=this.state.Game.players[3].playerName; 
              this.setState({
              team1Serving: this.state.team1Serving,
              serveNum:  0,
              servingPlayer: rplayer,
              defendingPlayer: this.state.Game.players[0]
            });
            this.updateDBSwitchServe(manUpdate);
            break;
          }
          case 3:
            
            rplayer.playerID=this.state.Game.players[0].playerID;
            rplayer.playerUID=this.state.Game.players[0].playerUID;
            rplayer.index=0;
            rplayer.playerName=this.state.Game.players[0].playerName; 
            this.setState({
              team1Serving: !this.state.team1Serving,
              serveNum:  0,
              servingPlayer: rplayer,
              defendingPlayer: this.state.Game.players[2]
            });
            this.updateDBSwitchServe(manUpdate);
            break;
            
        }
      }
        

      this.updateGame();

          
  };////switch serve

  updateDBSwitchServe= (manUpdate) => 
  {
    const listItem = {};
    if (manUpdate)
    {
    let raction = {
      game_id: this.state.Game.gameID, t1p1_id: this.state.Game.players[0].playerID,
      t1p2_id: this.state.Game.players[1].playerID, t2p1_id: this.state.Game.players[3].playerID,
      t2p2_id: this.state.Game.players[3].playerID, serving_p_id: this.state.servingPlayer.playerID,
      defending_p_id:this.state.defendingPlayer.playerID,
      raction_type_id: 1 ,
      raction:  "Manual Entry - " + this.state.servingPlayer.playerName  +" - Now Serving",
      t1score: this.state.Game.team1Score, t2score: this.state.Game.team2Score};
    
    this.insertRAction(raction) 
    const listItem={
      raction_id: this.state.rows.length + 1 , raction: "Manual Entry - " + this.state.servingPlayer.playerName +" - Now Serving",
      display_text: "Manual Entry - " + this.state.servingPlayer.playerName +" - Now Serving",
        createdby_name: this.state.rballUser.name,
        serving_p_name: this.state.servingPlayer.playerName,
        serving_p_uid: this.state.servingPlayer.playerUID,
        defending_p_uid: this.state.defendingPlayer.playerUID,
        defending_p_name: this.state.defendingPlayer.playerName,
      t1score: this.state.Game.team1Score, t2score: this.state.Game.team2Score};
       
    }
    const list=[listItem, ...this.state.rows];
    const tempGame=(this.state.Game);
    tempGame.team1Serving=!this.state.team1Serving;
    this.setState(
    {   
      isServing: false,
      actionType: "IDLE",
        edit: true,
      rkey: this.state.rkey+1,
      serveNum: this.state.serveNum =0,
      Game: tempGame,
      rows:list
      });
      
      this.updateGame();
};

/** team1Points If team serving return 1 else 0 */
team1Points = () => 
  {
    var retTeam1Points;
    if (this.state.team1Serving)
    {retTeam1Points=1;
    }
    else 
    {retTeam1Points=0;
    }
return retTeam1Points;
  }

  /** team2Points If team serving false return 1 else 0 */
  team2Points = () => 
  {
    var retTeam2Points;
    if (!this.state.team1Serving)
    { retTeam2Points=1;
    }
    else 
    { retTeam2Points=0;
    }
return retTeam2Points;
  }

//*** Handle Ace - Point is scored */
  handleAce(clickedButtonName,i)
  {
   // alert(clickedButtonName)
    const team1Points=this.team1Points();
    const team2Points=this.team2Points();
    
      
      let raction = {
        game_id: this.state.Game.gameID, t1p1_id: this.state.Game.players[0].playerID,
        t1p2_id: this.state.Game.players[1].playerID, t2p1_id: this.state.Game.players[3].playerID,
        t2p2_id: this.state.Game.players[3].playerID, serving_p_id: this.state.servingPlayer.playerID,
        defending_p_id:this.state.defendingPlayer.playerID,
        raction_type_id: 120 + this.state.serveNum -1,
        raction:  this.state.servingPlayer.playerName +" Ace Serve (Serve #" + this.state.serveNum +")",

        display_text: this.state.servingPlayer.playerName +" Ace Serve (Serve #" + this.state.serveNum +")",
        createdby_name: this.state.rballUser.name,
        serving_p_name: this.state.servingPlayer.playerName,
        serving_p_uid: this.state.servingPlayer.playerUID,
        defending_p_uid: this.state.defendingPlayer.playerUID,
        defending_p_name: this.state.defendingPlayer.playerName,

        t1score: this.state.Game.team1Score +  team1Points, t2score: this.state.Game.team2Score + team2Points};
       

      this.insertRAction(raction) 
      const listItem={
        raction_id: this.state.rows.length + 1 , display_type: "action",raction: this.state.servingPlayer.playerName +" Ace Serve (Serve #" + this.state.serveNum +")",

        display_text: this.state.servingPlayer.playerName +" Ace Serve (Serve #" + this.state.serveNum +")",
        createdby_name: this.state.rballUser.name,
        serving_p_name: this.state.servingPlayer.playerName,
        serving_p_uid: this.state.servingPlayer.playerUID,
        defending_p_uid: this.state.defendingPlayer.playerUID,
        defending_p_name: this.state.defendingPlayer.playerName,
        
        t1score: this.state.Game.team1Score +  team1Points, t2score: this.state.Game.team2Score + team2Points};
        const list=[listItem, ...this.state.rows];
        const tempGame=(this.state.Game);
      tempGame.team1Score=tempGame.team1Score+team1Points;
      if (tempGame.team1Score >= this.state.Game.pointsToWin)
      {
        tempGame.gameStatus=this.state.Game.teams[0].teamName + " Won " + tempGame.team1Score + " to " + tempGame.team2Score
      }
      else
      {
        tempGame.gameStatus="In Progress"
      }
      tempGame.team2Score=tempGame.team2Score+team2Points;
      if (tempGame.team2Score >= this.state.Game.pointsToWin)
      {
        tempGame.gameStatus=this.state.Game.teams[1].teamName + " Won " + tempGame.team2Score + " to " + tempGame.team1Score
      }
      else
      {
        tempGame.gameStatus="In Progress"
      }
      this.setState(
      {   
        isServing: true,
        actionType: "IDLE",
          edit: true,
        rkey: this.state.rkey+1,
        serveNum: 0,
        Game:tempGame,
        rows:list
        });

      this.updateGame();
    
  }
  handle1stServeFault(clickedButtonName,i)
  {
    //alert(clickedButtonName)
    const team1Points=this.team1Points();
    const team2Points=this.team2Points();
    
    
      let raction = {
        game_id: this.state.Game.gameID, t1p1_id: this.state.Game.players[0].playerID,
        t1p2_id: this.state.Game.players[1].playerID, t2p1_id: this.state.Game.players[3].playerID,
        t2p2_id: this.state.Game.players[3].playerID, serving_p_id: this.state.servingPlayer.playerID,
        defending_p_id:this.state.defendingPlayer.playerID,
        raction_type_id: 51 ,
        raction:  this.state.servingPlayer.playerName + " - First Serve Fault",
       
        display_text: "Manual Entry - " + this.state.servingPlayer.playerName +" - Now Serving",
        createdby_name: this.state.rballUser.name,
        serving_p_name: this.state.servingPlayer.playerName,
        serving_p_uid: this.state.servingPlayer.playerUID,
        defending_p_uid: this.state.defendingPlayer.playerUID,
        defending_p_name: this.state.defendingPlayer.playerName,
        
        t1score: this.state.Game.team1Score , t2score: this.state.Game.team2Score };
       

      this.insertRAction(raction) 
      const listItem={
        raction_id: this.state.rows.length + 1 , display_type: "action",raction: this.state.servingPlayer.playerName +" - First Serve Fault",

        display_text: this.state.servingPlayer.playerName +" - First Serve Fault",
        createdby_name: this.state.rballUser.name,
        serving_p_name: this.state.servingPlayer.playerName,
        serving_p_uid: this.state.servingPlayer.playerUID,
        defending_p_uid: this.state.defendingPlayer.playerUID,
        defending_p_name: this.state.defendingPlayer.playerName,
        
        t1score: this.state.Game.team1Score , t2score: this.state.Game.team2Score };
        const list=[listItem, ...this.state.rows];
      
        const tempGame=(this.state.Game);
      tempGame.team1Score=tempGame.team1Score;
      tempGame.team2Score=tempGame.team2Score;

      if (tempGame.team1Score >= this.state.Game.pointsToWin)
      {
        tempGame.gameStatus=this.state.Game.teams[0].teamName + " Won " + tempGame.team1Score + " to " + tempGame.team2Score
      }
      else
      {
        tempGame.gameStatus="In Progress"
      }
      tempGame.team2Score=tempGame.team2Score;
      if (tempGame.team2Score >= this.state.Game.pointsToWin)
      {
        tempGame.gameStatus=this.state.Game.teams[1].teamName + " Won " + tempGame.team2Score + " to " + tempGame.team1Score
      }
      else
      {
        tempGame.gameStatus="In Progress"
      }


      this.setState(
      {   
        isServing: true,
        actionType: "Serving",
          edit: true,
        rkey: this.state.rkey+1,
        serveNum: this.state.serveNum +1,
        Game:tempGame,
        rows:list
        });

      this.updateGame();
   
  }
  handle2ndServeFault(clickedButtonName,i)
  {
    //alert(clickedButtonName)
    const team1Points=this.team1Points();
    const team2Points=this.team2Points();
    
    
      let raction = {
        game_id: this.state.Game.gameID, t1p1_id: this.state.Game.players[0].playerID,
        t1p2_id: this.state.Game.players[1].playerID, t2p1_id: this.state.Game.players[3].playerID,
        t2p2_id: this.state.Game.players[3].playerID, serving_p_id: this.state.servingPlayer.playerID,
        defending_p_id:this.state.defendingPlayer.playerID,
        raction_type_id: 52 ,
        raction:  this.state.servingPlayer.playerName + " - Second Serve Fault",

        t1score: this.state.Game.team1Score , t2score: this.state.Game.team2Score };
       

      this.insertRAction(raction) 
      const listItem={
        raction_id: this.state.rows.length + 1 , display_type: "action",raction: this.state.servingPlayer.playerName +" - Second Serve Fault",

        display_text: this.state.servingPlayer.playerName +" - Second Serve Fault",
        createdby_name: this.state.rballUser.name,
        serving_p_name: this.state.servingPlayer.playerName,
        serving_p_uid: this.state.servingPlayer.playerUID,
        defending_p_uid: this.state.defendingPlayer.playerUID,
        defending_p_name: this.state.defendingPlayer.playerName,
        
        t1score: this.state.Game.team1Score , t2score: this.state.Game.team2Score };
        const list=[listItem, ...this.state.rows];
      
        const tempGame=(this.state.Game);
      tempGame.team1Score=tempGame.team1Score;
      tempGame.team2Score=tempGame.team2Score;
      this.setState(
      {   
        isServing: false,
        actionType: "IDLE",
          edit: true,
        rkey: this.state.rkey+1,
        serveNum: 0,
        Game:tempGame,
        rows:list
        });
      this.switchServe();
      this.updateGame();
  }
  handlePoint(clickedButtonName,i)
  {
    //alert(clickedButtonName)
    const team1Points=this.team1Points();
    const team2Points=this.team2Points();
    
    
      let raction = {
        game_id: this.state.Game.gameID, t1p1_id: this.state.Game.players[0].playerID,
        t1p2_id: this.state.Game.players[1].playerID, t2p1_id: this.state.Game.players[3].playerID,
        t2p2_id: this.state.Game.players[3].playerID, serving_p_id: this.state.servingPlayer.playerID,
        defending_p_id:this.state.defendingPlayer.playerID,
        raction_type_id: 30 ,
        raction:  this.state.servingPlayer.playerName  +" Scored",

        t1score: this.state.Game.team1Score +  team1Points, t2score: this.state.Game.team2Score + team2Points};
       

      this.insertRAction(raction) 
      const listItem={
        raction_id: this.state.rows.length + 1 , display_type: "action",raction: this.state.servingPlayer.playerName +" Scored",

        display_text: this.state.servingPlayer.playerName +" Scored",
        createdby_name: this.state.rballUser.name,
        serving_p_name: this.state.servingPlayer.playerName,
        serving_p_uid: this.state.servingPlayer.playerUID,
        defending_p_uid: this.state.defendingPlayer.playerUID,
        defending_p_name: this.state.defendingPlayer.playerName,
        
        t1score: this.state.Game.team1Score +  team1Points, t2score: this.state.Game.team2Score + team2Points};
        const list=[listItem, ...this.state.rows];
        const tempGame=(this.state.Game);
      tempGame.team1Score=tempGame.team1Score+team1Points;
      tempGame.team2Score=tempGame.team2Score+team2Points;

      if (tempGame.team1Score >= this.state.Game.pointsToWin)
      {
        tempGame.gameStatus=this.state.Game.teams[0].teamName + " Won " + tempGame.team1Score + " to " + tempGame.team2Score
      }
      else if (tempGame.team2Score >= this.state.Game.pointsToWin)
      {
        tempGame.gameStatus=this.state.Game.teams[1].teamName + " Won " + tempGame.team2Score + " to " + tempGame.team1Score
      }
      else
      {
        tempGame.gameStatus="In Progress"
      }


      this.setState(
      {   
        isServing: true,
        actionType: "IDLE",
          edit: true,
        rkey: this.state.rkey+1,
        serveNum: 0,
        Game:tempGame,
        rows:list
        });

      this.updateGame();
  
  }
  handlePointAdvanced(clickedButtonName,i)
  {
   // alert(clickedButtonName)
    
    this.setState({
      scoring1stClick: false,
      pointAdvanced: true,
      sideoutAdcanced: false,
    });
  }
  handleSplat(clickedButtonName,i)
  {
   // alert(clickedButtonName)
    
   var team1Points=this.team1Points();
   var team2Points=this.team2Points();
    
    
      var list = []
      if (this.state.pointAdvanced)
      {  
        let raction = {
        game_id: this.state.Game.gameID, t1p1_id: this.state.Game.players[0].playerID,
        t1p2_id: this.state.Game.players[1].playerID, t2p1_id: this.state.Game.players[3].playerID,
        t2p2_id: this.state.Game.players[3].playerID, serving_p_id: this.state.servingPlayer.playerID,
        defending_p_id:this.state.defendingPlayer.playerID,
        raction_type_id: 32 ,
        raction:  this.state.servingPlayer.playerName  +" Scored (Splat)",

        t1score: this.state.Game.team1Score +  team1Points, t2score: this.state.Game.team2Score + team2Points};
       
 
      this.insertRAction(raction) 
      const listItem={
        raction_id: this.state.rows.length + 1 , display_type: "action",raction: this.state.servingPlayer.playerName +" Scored (Splat)",

        display_text: this.state.servingPlayer.playerName +" Scored (Splat)",
        createdby_name: this.state.rballUser.name,
        serving_p_name: this.state.servingPlayer.playerName,
        serving_p_uid: this.state.servingPlayer.playerUID,
        defending_p_uid: this.state.defendingPlayer.playerUID,
        defending_p_name: this.state.defendingPlayer.playerName,
        
        t1score: this.state.Game.team1Score +  team1Points, t2score: this.state.Game.team2Score + team2Points};
         list=[listItem, ...this.state.rows];
      }
      else
      {
        
        team1Points=0;
        team2Points=0;
        let raction = {
          game_id: this.state.Game.gameID, t1p1_id: this.state.Game.players[0].playerID,
          t1p2_id: this.state.Game.players[1].playerID, t2p1_id: this.state.Game.players[3].playerID,
          t2p2_id: this.state.Game.players[3].playerID, serving_p_id: this.state.servingPlayer.playerID,
          defending_p_id:this.state.defendingPlayer.playerID,
          raction_type_id: 10 ,
          raction:  this.state.defendingPlayer.playerName  +" Sideout (Splat)",
  
          t1score: this.state.Game.team1Score +  team1Points, t2score: this.state.Game.team2Score + team2Points};
         
  
        this.insertRAction(raction) 
        const listItem={
          raction_id: this.state.rows.length + 1 , raction: this.state.defendingPlayer.playerName +" Sideout (Splat)",
          t1score: this.state.Game.team1Score +  team1Points, t2score: this.state.Game.team2Score + team2Points};
         list=[listItem, ...this.state.rows];
        this.switchServe();
      }

      const tempGame=(this.state.Game);
      tempGame.team1Score=tempGame.team1Score+team1Points;
     

      if (tempGame.team1Score >= this.state.Game.pointsToWin)
      {
        tempGame.gameStatus=this.state.Game.teams[0].teamName + " Won " + tempGame.team1Score + " to " + tempGame.team2Score
      }
      else
      {
        tempGame.gameStatus="In Progress"
      }
      tempGame.team2Score=tempGame.team2Score+team2Points;
      if (tempGame.team2Score >= this.state.Game.pointsToWin)
      {
        tempGame.gameStatus=this.state.Game.teams[1].teamName + " Won " + tempGame.team2Score + " to " + tempGame.team1Score
      }
      else
      {
        tempGame.gameStatus="In Progress"
      }


      
      this.setState(
      {   
        isServing: true,
        actionType: "IDLE",
          edit: true,
        rkey: this.state.rkey+1,
        serveNum: 0,
        Game:tempGame,
        scoring1stClick: true,
        rows:list
        });

      this.updateGame();
  
   
  }
  handleRollout(clickedButtonName,i)
  {
    //alert(clickedButtonName)
    
    var team1Points=this.team1Points();
    var team2Points=this.team2Points();
    
    var list = [];
      
      if (this.state.pointAdvanced)
      {  
        let raction = {
        game_id: this.state.Game.gameID, t1p1_id: this.state.Game.players[0].playerID,
        t1p2_id: this.state.Game.players[1].playerID, t2p1_id: this.state.Game.players[3].playerID,
        t2p2_id: this.state.Game.players[3].playerID, serving_p_id: this.state.servingPlayer.playerID,
        defending_p_id:this.state.defendingPlayer.playerID,
        raction_type_id: 33 ,
        raction:  this.state.servingPlayer.playerName  +" Scored (Rollout)",

        t1score: this.state.Game.team1Score +  team1Points, t2score: this.state.Game.team2Score + team2Points};
       

      this.insertRAction(raction) 
      const listItem={
        raction_id: this.state.rows.length + 1 , display_type: "action",raction: this.state.servingPlayer.playerName +" Scored (Rollout)",

        display_text: this.state.servingPlayer.playerName +" Scored (Rollout)",
        createdby_name: this.state.rballUser.name,
        serving_p_name: this.state.servingPlayer.playerName,
        serving_p_uid: this.state.servingPlayer.playerUID,
        defending_p_uid: this.state.defendingPlayer.playerUID,
        defending_p_name: this.state.defendingPlayer.playerName,
        
        t1score: this.state.Game.team1Score +  team1Points, t2score: this.state.Game.team2Score + team2Points};
         list=[listItem, ...this.state.rows];
      }
      else
      {
        team1Points=0;
        team2Points=0;
        
        let raction = {
          game_id: this.state.Game.gameID, t1p1_id: this.state.Game.players[0].playerID,
          t1p2_id: this.state.Game.players[1].playerID, t2p1_id: this.state.Game.players[3].playerID,
          t2p2_id: this.state.Game.players[3].playerID, serving_p_id: this.state.servingPlayer.playerID,
          defending_p_id:this.state.defendingPlayer.playerID,
          raction_type_id: 11 ,
          raction:  this.state.defendingPlayer.playerName  +" Sideout (Rollout)",
  
          t1score: this.state.Game.team1Score +  team1Points, t2score: this.state.Game.team2Score + team2Points};
         
  
        this.insertRAction(raction) 
        const listItem={
          raction_id: this.state.rows.length + 1 , raction: this.state.defendingPlayer.playerName +" Sideout (Rollout)",
          t1score: this.state.Game.team1Score +  team1Points, t2score: this.state.Game.team2Score + team2Points};
           list=[listItem, ...this.state.rows];
        this.switchServe();
      }

      const tempGame=(this.state.Game);
      tempGame.team1Score=tempGame.team1Score+team1Points;
     

      if (tempGame.team1Score >= this.state.Game.pointsToWin)
      {
        tempGame.gameStatus=this.state.Game.teams[0].teamName + " Won " + tempGame.team1Score + " to " + tempGame.team2Score
      }
      else
      {
        tempGame.gameStatus="In Progress"
      }
      tempGame.team2Score=tempGame.team2Score+team2Points;
      if (tempGame.team2Score >= this.state.Game.pointsToWin)
      {
        tempGame.gameStatus=this.state.Game.teams[1].teamName + " Won " + tempGame.team2Score + " to " + tempGame.team1Score
      }
      else
      {
        tempGame.gameStatus="In Progress"
      }
      
      this.setState(
      {   
        isServing: true,
        actionType: "IDLE",
          edit: true,
        rkey: this.state.rkey+1,
        serveNum: 0,
        Game:tempGame,
        scoring1stClick: true,
        rows:list
        });

      this.updateGame();
  
   
  }
  handlePinch(clickedButtonName,i)
  {
   // alert(clickedButtonName)
   
   var team1Points=this.team1Points();
   var team2Points=this.team2Points();
    
    
      var list = [];
      if (this.state.pointAdvanced)
      {  
        let raction = {
        game_id: this.state.Game.gameID, t1p1_id: this.state.Game.players[0].playerID,
        t1p2_id: this.state.Game.players[1].playerID, t2p1_id: this.state.Game.players[3].playerID,
        t2p2_id: this.state.Game.players[3].playerID, serving_p_id: this.state.servingPlayer.playerID,
        defending_p_id:this.state.defendingPlayer.playerID,
        raction_type_id: 34 ,
        raction:  this.state.servingPlayer.playerName  +" Scored (Pinch)",

        t1score: this.state.Game.team1Score +  team1Points, t2score: this.state.Game.team2Score + team2Points};
       

      this.insertRAction(raction) 
      const listItem={
        raction_id: this.state.rows.length + 1 , display_type: "action",raction: this.state.servingPlayer.playerName +" Scored (Pinch)",

        display_text: this.state.servingPlayer.playerName +" Scored (Pinch)",
        createdby_name: this.state.rballUser.name,
        serving_p_name: this.state.servingPlayer.playerName,
        serving_p_uid: this.state.servingPlayer.playerUID,
        defending_p_uid: this.state.defendingPlayer.playerUID,
        defending_p_name: this.state.defendingPlayer.playerName,
        
        t1score: this.state.Game.team1Score +  team1Points, t2score: this.state.Game.team2Score + team2Points};
         list=[listItem, ...this.state.rows];
      }
      else
      {
        
        team1Points=0;
        team2Points=0;
        let raction = {
          game_id: this.state.Game.gameID, t1p1_id: this.state.Game.players[0].playerID,
          t1p2_id: this.state.Game.players[1].playerID, t2p1_id: this.state.Game.players[3].playerID,
          t2p2_id: this.state.Game.players[3].playerID, serving_p_id: this.state.servingPlayer.playerID,
          defending_p_id:this.state.defendingPlayer.playerID,
          raction_type_id: 14 ,
          raction:  this.state.defendingPlayer.playerName  +" Sideout (Pinch)",
  
          t1score: this.state.Game.team1Score +  team1Points, t2score: this.state.Game.team2Score + team2Points};
          
         
  
        this.insertRAction(raction) 
        const listItem={
          raction_id: this.state.rows.length + 1 , raction: this.state.defendingPlayer.playerName +" Sideout (Pinch)",
          t1score: this.state.Game.team1Score +  team1Points, t2score: this.state.Game.team2Score + team2Points};
           list=[listItem, ...this.state.rows];
        this.switchServe();
      }

      const tempGame=(this.state.Game);
      tempGame.team1Score=tempGame.team1Score+team1Points;
  
      
      if (tempGame.team1Score >= this.state.Game.pointsToWin)
      {
        tempGame.gameStatus=this.state.Game.teams[0].teamName + " Won " + tempGame.team1Score + " to " + tempGame.team2Score
      }
      else
      {
        tempGame.gameStatus="In Progress"
      }
      tempGame.team2Score=tempGame.team2Score+team2Points;
      if (tempGame.team2Score >= this.state.Game.pointsToWin)
      {
        tempGame.gameStatus=this.state.Game.teams[1].teamName + " Won " + tempGame.team2Score + " to " + tempGame.team1Score
      }
      else
      {
        tempGame.gameStatus="In Progress"
      }

      this.setState(
      {   
        isServing: true,
        actionType: "IDLE",
          edit: true,
        rkey: this.state.rkey+1,
        serveNum: 0,
        Game:tempGame,
        scoring1stClick: true,
        rows:list
        });

      this.updateGame();
  
   
  }
  handleUnreturnable(clickedButtonName,i)
  {
    //alert(clickedButtonName)
   
    var team1Points=this.team1Points();
    var team2Points=this.team2Points();
    
    var list = [];
      
      if (this.state.pointAdvanced)
      {  
        let raction = {
        game_id: this.state.Game.gameID, t1p1_id: this.state.Game.players[0].playerID,
        t1p2_id: this.state.Game.players[1].playerID, t2p1_id: this.state.Game.players[3].playerID,
        t2p2_id: this.state.Game.players[3].playerID, serving_p_id: this.state.servingPlayer.playerID,
        defending_p_id:this.state.defendingPlayer.playerID,
        raction_type_id: 35 ,
        raction:  this.state.servingPlayer.playerName  +" Scored (Unreturnable)",

        t1score: this.state.Game.team1Score +  team1Points, t2score: this.state.Game.team2Score + team2Points};
       

      this.insertRAction(raction) 
      const listItem={
        raction_id: this.state.rows.length + 1 , display_type: "action",raction: this.state.servingPlayer.playerName +" Scored (Unreturnable)",

        display_text: this.state.servingPlayer.playerName +" Scored (Unreturnable)",
        createdby_name: this.state.rballUser.name,
        serving_p_name: this.state.servingPlayer.playerName,
        serving_p_uid: this.state.servingPlayer.playerUID,
        defending_p_uid: this.state.defendingPlayer.playerUID,
        defending_p_name: this.state.defendingPlayer.playerName,
        
        t1score: this.state.Game.team1Score +  team1Points, t2score: this.state.Game.team2Score + team2Points};
         list=[listItem, ...this.state.rows];
      }
      else
      {
        
        team1Points=0;
        team2Points=0;
        let raction = {
          game_id: this.state.Game.gameID, t1p1_id: this.state.Game.players[0].playerID,
          t1p2_id: this.state.Game.players[1].playerID, t2p1_id: this.state.Game.players[3].playerID,
          t2p2_id: this.state.Game.players[3].playerID, serving_p_id: this.state.servingPlayer.playerID,
          defending_p_id:this.state.defendingPlayer.playerID,
          raction_type_id: 12 ,
          raction:  this.state.defendingPlayer.playerName  +" Sideout (Unreturnable)",
  
          t1score: this.state.Game.team1Score +  team1Points, t2score: this.state.Game.team2Score + team2Points};
      
         
  
        this.insertRAction(raction) 
        const listItem={
          raction_id: this.state.rows.length + 1 , raction: this.state.defendingPlayer.playerName +" Sideout (Unreturnable)",
          t1score: this.state.Game.team1Score +  team1Points, t2score: this.state.Game.team2Score + team2Points};
          list=[listItem, ...this.state.rows];
          this.switchServe();
      }

      const tempGame=(this.state.Game);
      tempGame.team1Score=tempGame.team1Score+team1Points;
     

      if (tempGame.team1Score >= this.state.Game.pointsToWin)
      {
        tempGame.gameStatus=this.state.Game.teams[0].teamName + " Won " + tempGame.team1Score + " to " + tempGame.team2Score
      }
      else
      {
        tempGame.gameStatus="In Progress"
      }
      tempGame.team2Score=tempGame.team2Score+team2Points;
      if (tempGame.team2Score >= this.state.Game.pointsToWin)
      {
        tempGame.gameStatus=this.state.Game.teams[1].teamName + " Won " + tempGame.team2Score + " to " + tempGame.team1Score
      }
      else
      {
        tempGame.gameStatus="In Progress"
      }


      
      this.setState(
      {   
        isServing: true,
        actionType: "IDLE",
          edit: true,
        rkey: this.state.rkey+1,
        serveNum: 0,
        Game:tempGame,
        scoring1stClick: true,
        rows:list
        });

      this.updateGame();
  
   
  }
  handleSideout(clickedButtonName,i)
  {
    //alert(clickedButtonName)
    const team1Points=this.team1Points();
    const team2Points=this.team2Points();
    
    
      let raction = {
        game_id: this.state.Game.gameID, t1p1_id: this.state.Game.players[0].playerID,
        t1p2_id: this.state.Game.players[1].playerID, t2p1_id: this.state.Game.players[3].playerID,
        t2p2_id: this.state.Game.players[3].playerID, serving_p_id: this.state.servingPlayer.playerID,
        defending_p_id:this.state.defendingPlayer.playerID,
        raction_type_id: 16 ,
        raction:  this.state.defendingPlayer.playerName  +" Sideout",

        t1score: this.state.Game.team1Score , t2score: this.state.Game.team2Score };
       

      this.insertRAction(raction) 
      const listItem={
        raction_id: this.state.rows.length + 1 , display_type: "action",raction: this.state.defendingPlayer.playerName +" Sideout",

        display_text: this.state.defendingPlayer.playerName +" Sideout",
        createdby_name: this.state.rballUser.name,
        serving_p_name: this.state.servingPlayer.playerName,
        serving_p_uid: this.state.servingPlayer.playerUID,
        defending_p_uid: this.state.defendingPlayer.playerUID,
        defending_p_name: this.state.defendingPlayer.playerName,
        
        t1score: this.state.Game.team1Score , t2score: this.state.Game.team2Score };
        const list=[listItem, ...this.state.rows];
      
        const tempGame=(this.state.Game);
      tempGame.team1Score=tempGame.team1Score;
      tempGame.team2Score=tempGame.team2Score;
      this.setState(
      {   
        isServing: true,
        actionType: "IDLE",
          edit: true,
        rkey: this.state.rkey+1,
        serveNum: 0,
        Game:tempGame,
        rows:list
        });
      this.switchServe();
      this.updateGame();
  
  }
  handleSideoutAdvanced(clickedButtonName,i)
  {
   // alert(clickedButtonName)
    this.setState({
      scoring1stClick: false,
      sideoutAdcanced: true,
      pointAdvanced: false
    });
  }
  handleHinder(clickedButtonName,i)
  {
   //alert(clickedButtonName)
   
   var team1Points=this.team1Points();
   var team2Points=this.team2Points();
    var list = [];
    
      
      if (this.state.pointAdvanced)
      {  
        let raction = {
        game_id: this.state.Game.gameID, t1p1_id: this.state.Game.players[0].playerID,
        t1p2_id: this.state.Game.players[1].playerID, t2p1_id: this.state.Game.players[3].playerID,
        t2p2_id: this.state.Game.players[3].playerID, serving_p_id: this.state.servingPlayer.playerID,
        defending_p_id:this.state.defendingPlayer.playerID,
        raction_type_id: 36 ,
        raction:  this.state.servingPlayer.playerName  +" Scored (Hinder)",

        t1score: this.state.Game.team1Score +  team1Points, t2score: this.state.Game.team2Score + team2Points};
       

      this.insertRAction(raction) 
      const listItem={
        raction_id: this.state.rows.length + 1 , display_type: "action",raction: this.state.servingPlayer.playerName +" Scored (Hinder)",

        display_text: this.state.servingPlayer.playerName +" Scored (Hinder)",
        createdby_name: this.state.rballUser.name,
        serving_p_name: this.state.servingPlayer.playerName,
        serving_p_uid: this.state.servingPlayer.playerUID,
        defending_p_uid: this.state.defendingPlayer.playerUID,
        defending_p_name: this.state.defendingPlayer.playerName,
        
        t1score: this.state.Game.team1Score +  team1Points, t2score: this.state.Game.team2Score + team2Points};
         list=[listItem, ...this.state.rows];
      }
      else
      {
        team1Points=0;
        team2Points=0;
        
        let raction = {
          game_id: this.state.Game.gameID, t1p1_id: this.state.Game.players[0].playerID,
          t1p2_id: this.state.Game.players[1].playerID, t2p1_id: this.state.Game.players[3].playerID,
          t2p2_id: this.state.Game.players[3].playerID, serving_p_id: this.state.servingPlayer.playerID,
          defending_p_id:this.state.defendingPlayer.playerID,
          raction_type_id: 13 ,
          raction:  this.state.defendingPlayer.playerName  +" Sideout (Hinder)",
  
          t1score: this.state.Game.team1Score +  team1Points, t2score: this.state.Game.team2Score + team2Points};
          
         
  
        this.insertRAction(raction) 
        const listItem={
          raction_id: this.state.rows.length + 1 , raction: this.state.defendingPlayer.playerName +" Sideout (Hinder  )",
          t1score: this.state.Game.team1Score +  team1Points, t2score: this.state.Game.team2Score + team2Points};
          list=[listItem, ...this.state.rows];
          this.switchServe();
      }

      const tempGame=(this.state.Game);
      tempGame.team1Score=tempGame.team1Score+team1Points;
     

      tempGame.team1Score=tempGame.team1Score+team1Points;
     

      if (tempGame.team1Score >= this.state.Game.pointsToWin)
      {
        tempGame.gameStatus=this.state.Game.teams[0].teamName + " Won " + tempGame.team1Score + " to " + tempGame.team2Score
      }
      else
      {
        tempGame.gameStatus="In Progress"
      }
      tempGame.team2Score=tempGame.team2Score+team2Points;
      if (tempGame.team2Score >= this.state.Game.pointsToWin)
      {
        tempGame.gameStatus=this.state.Game.teams[1].teamName + " Won " + tempGame.team2Score + " to " + tempGame.team1Score
      }
      else
      {
        tempGame.gameStatus="In Progress"
      }


      this.switchServe();
      this.setState(
      {   
        isServing: true,
        actionType: "IDLE",
          edit: true,
        rkey: this.state.rkey+1,
        serveNum: 0,
        Game:tempGame,
        scoring1stClick: true,
        rows:list
        });

      this.updateGame();
  
   
    
  }
  handleAddPoint(clickedButtonName,i)
  {
    alert(clickedButtonName)
  }
  
  handleSubtractPoint(clickedButtonName,i)
  {
    alert(clickedButtonName)
  }
  
  updateGame(){

    

  const requestOptions = {
    method: 'PUT',
    headers: { 'Content-Type': 'application/json', 
		Authorization: `Bearer ${Userfront.tokens.accessToken}` },
    body: JSON.stringify(this.state.Game)
    };

    fetch('/api/getScoreBoard', requestOptions)
  .then((res)  => {
    if (res.status != 404)
    {
      return res.json()
    }
    else
    {
    
      return res;
    }
    }
  )
       .then((res)=> {
        if (res.status != 404)
        {
  
          this.setState({ postId: res.id });
        }
        
            
        })
    .catch(error => {
      //console.log("throw me an error " +  error)
        }  )

 
  
  
    }

  insertRAction (raction){

    var current_datetime = new Date();
		var formatted_date = current_datetime.getFullYear() + "-" +
		  this.appendLeadingZeroes(current_datetime.getMonth() + 1) + "-" +
		  this.appendLeadingZeroes(current_datetime.getDate()) + "_" +
		  this.appendLeadingZeroes(current_datetime.getHours()) + ":" +
		  this.appendLeadingZeroes(current_datetime.getMinutes()) + ":" +
		  this.appendLeadingZeroes(current_datetime.getSeconds());
	
		  //console.log(formatted_date);
		  raction.date_created=formatted_date;

    const requestOptions = {
      method: 'POST',
      headers: { 'Content-Type': 'application/json', 
      Authorization: `Bearer ${Userfront.tokens.accessToken}` },
      body: JSON.stringify(raction)
  };
  fetch('/api/rballActions', requestOptions)
      .then(response => response.json())
      .then(data => this.setState({ postId: data.id }));
  }
  
  deleteRAction (raction_id,game_id){
    const reqBody={};
    reqBody.raction_id=raction_id;
    reqBody.game_id=game_id;
    const requestOptions = {
      method: 'DELETE',
      headers: { 'Content-Type': 'application/json' ,
      Authorization: `Bearer ${Userfront.tokens.accessToken}` },
      body: JSON.stringify(reqBody)
  };
  fetch('/api/rballActions', requestOptions)
      
      .then(data => {
        this.setState({ postId: data.id });
      //console.log(JSON.stringify(data))
      })
  }

//** Update RAction after delete - update all rows past raction to minus points
  updateRAction (raction_id,game_id,Team1Points,Team2Points){
    const reqBody={};
    reqBody.raction_id=raction_id;
    reqBody.game_id=game_id;
    reqBody.Team1Points=Team1Points;
    reqBody.Team2Points=Team2Points;
    const requestOptions = {
      method: 'PUT',
      headers: { 'Content-Type': 'application/json' ,
      Authorization: `Bearer ${Userfront.tokens.accessToken}` },
      body: JSON.stringify(reqBody)
  };
  fetch('/api/rballActions', requestOptions)
      .then(response => response.json())
      .then(data => this.setState({ postId: data.id }));
  }


// Cancel Serve - UI will revert to serving screen
  cancelServe= () => 
  {
    this.setState({
      isServing: !this.state.isServing
    });
          
  };
   

  



  manualIncrementTeam1Score = () => 
    {
      
      let raction = {
        game_id: this.state.Game.gameID, t1p1_id: this.state.Game.players[0].playerID,
        t1p2_id: this.state.Game.players[1].playerID, t2p1_id: this.state.Game.players[3].playerID,
        t2p2_id: this.state.Game.players[3].playerID, serving_p_id: this.state.servingPlayer.playerID,
        defending_p_id:this.state.defendingPlayer.playerID,
        raction_type_id: 100 ,
        raction:  "Manual Entry - " + this.state.Game.teams[0].teamName  +" Point Added",

        t1score: this.state.Game.team1Score+1, t2score: this.state.Game.team2Score};
        
      this.insertRAction(raction) 
      const listItem={
        raction_id: this.state.rows.length + 1 , display_type: "action", raction: "Manual Entry - " + this.state.Game.teams[0].teamName +" Point Added",

        display_text: "Manual Entry - " + this.state.Game.teams[0].teamName +" Point Added",
        createdby_name: this.state.rballUser.name,
        serving_p_name: this.state.servingPlayer.playerName,
        serving_p_uid: this.state.servingPlayer.playerUID,
        defending_p_uid: this.state.defendingPlayer.playerUID,
        defending_p_name: this.state.defendingPlayer.playerName,
        
        t1score: this.state.Game.team1Score +1, t2score: this.state.Game.team2Score};
        const list=[listItem, ...this.state.rows];
      const tempGame=(this.state.Game);
      tempGame.team1Score=tempGame.team1Score+1;

      if (tempGame.team1Score >= this.state.Game.pointsToWin)
      {
        tempGame.gameStatus=this.state.Game.teams[0].teamName + " Won " + tempGame.team1Score + " to " + tempGame.team2Score
      }
      else
      {
        tempGame.gameStatus="In Progress"
      }
      


      this.setState(
      {   
        isServing: false,
        actionType: "IDLE",
          edit: true,
        rkey: this.state.rkey+1,
        Game:tempGame,
        serveNum: this.state.serveNum =0,
        rows:list
        });
        
        this.updateGame();
  };

  manualIncrementTeam2Score = () => 
    {
      
      let raction = {
        game_id: this.state.Game.gameID, t1p1_id: this.state.Game.players[0].playerID,
        t1p2_id: this.state.Game.players[1].playerID, t2p1_id: this.state.Game.players[3].playerID,
        t2p2_id: this.state.Game.players[3].playerID, serving_p_id: this.state.servingPlayer.playerID,
        defending_p_id:this.state.defendingPlayer.playerID,
        raction_type_id: 100 ,
        raction:  "Manual Entry - " +this.state.Game.teams[1].teamName +" Point Added",
         
        t1score: this.state.Game.team1Score, t2score: this.state.Game.team2Score + 1};
        
      this.insertRAction(raction) 
      const listItem={
        raction_id: this.state.rows.length + 1 , display_type: "action",raction: "Manual Entry - " + this.state.Game.teams[1].teamName +" Point Added",

        display_text: "Manual Entry - " + this.state.Game.teams[1].teamName +" Point Added",
        createdby_name: this.state.rballUser.name,
        serving_p_name: this.state.servingPlayer.playerName,
        serving_p_uid: this.state.servingPlayer.playerUID,
        defending_p_uid: this.state.defendingPlayer.playerUID,
        defending_p_name: this.state.defendingPlayer.playerName,
        
        t1score: this.state.Game.team1Score, t2score: this.state.Game.team2Score+1};
        const list=[listItem, ...this.state.rows];
      const tempGame=(this.state.Game);
      tempGame.team2Score=tempGame.team2Score+1;

      if (tempGame.team2Score >= this.state.Game.pointsToWin)
      {
        tempGame.gameStatus=this.state.Game.teams[1].teamName + " Won " + tempGame.team2Score + " to " + tempGame.team1Score
      }
      else
      {
        tempGame.gameStatus="In Progress"
      }
      


      this.setState(
      {   
        isServing: false,
        actionType: "IDLE",
          edit: true,
        rkey: this.state.rkey+1,
        Game:tempGame,
        serveNum: this.state.serveNum =0,
        rows:list
        });
        
        this.updateGame();
  };

  manualDecrementTeam1Score = () => 
    {
      
      let raction = {
        game_id: this.state.Game.gameID, t1p1_id: this.state.Game.players[0].playerID,
        t1p2_id: this.state.Game.players[1].playerID, t2p1_id: this.state.Game.players[3].playerID,
        t2p2_id: this.state.Game.players[3].playerID, serving_p_id: this.state.servingPlayer.playerID,
        defending_p_id:this.state.defendingPlayer.playerID,
        raction_type_id: 101 ,
        raction:  "Manual Entry - " + this.state.Game.teams[0].teamName  +" Point Removed",

        t1score: this.state.Game.team1Score -1, t2score: this.state.Game.team2Score};
        
      this.insertRAction(raction) 
      const listItem={
        raction_id: this.state.rows.length + 1 , display_type: "action", raction: "Manual Entry - " + this.state.Game.teams[0].teamName +" Point Removed",
        
        display_text: "Manual Entry - " + this.state.Game.teams[0].teamName +" Point Removed",
        createdby_name: this.state.rballUser.name,
        serving_p_name: this.state.servingPlayer.playerName,
        serving_p_uid: this.state.servingPlayer.playerUID,
        defending_p_uid: this.state.defendingPlayer.playerUID,
        defending_p_name: this.state.defendingPlayer.playerName,
        t1score: this.state.Game.team1Score -1, t2score: this.state.Game.team2Score};
        const list=[listItem, ...this.state.rows];
      const tempGame=(this.state.Game);
      tempGame.team1Score=tempGame.team1Score-1;

      if (tempGame.team1Score >= this.state.Game.pointsToWin)
      {
        tempGame.gameStatus=this.state.Game.teams[0].teamName + " Won " + tempGame.team1Score + " to " + tempGame.team2Score
      }
      else
      {
        tempGame.gameStatus="In Progress"
      }
     


      this.setState(
      {   
        isServing: false,
        actionType: "IDLE",
          edit: true,
        rkey: this.state.rkey+1,
        Game:tempGame,
        serveNum: this.state.serveNum =0,
        rows:list
        });
        
        this.updateGame();
  };

  manualDecrementTeam2Score = () => 
    {
      
      let raction = {
        game_id: this.state.Game.gameID, t1p1_id: this.state.Game.players[0].playerID,
        t1p2_id: this.state.Game.players[1].playerID, t2p1_id: this.state.Game.players[3].playerID,
        t2p2_id: this.state.Game.players[3].playerID, serving_p_id: this.state.servingPlayer.playerID,
        defending_p_id:this.state.defendingPlayer.playerID,
        raction_type_id: 101 ,
        raction:  "Manual Entry - " +this.state.Game.teams[1].teamName +" Point Removed",
         
        t1score: this.state.Game.team1Score, t2score: this.state.Game.team2Score - 1};
        
      this.insertRAction(raction) 
      const listItem={
        raction_id: this.state.rows.length + 1 , display_type: "action", raction: "Manual Entry - " + this.state.Game.teams[1].teamName +" Point Removed",

        display_text: "Manual Entry - " + this.state.Game.teams[1].teamName +" Point Removed",
        createdby_name: this.state.rballUser.name,
        serving_p_name: this.state.servingPlayer.playerName,
        serving_p_uid: this.state.servingPlayer.playerUID,
        defending_p_uid: this.state.defendingPlayer.playerUID,
        defending_p_name: this.state.defendingPlayer.playerName,
        
        t1score: this.state.Game.team1Score, t2score: this.state.Game.team2Score-1};
        const list=[listItem, ...this.state.rows];
      const tempGame=(this.state.Game);
      tempGame.team2Score=tempGame.team2Score-1;

      if (tempGame.team2Score >= this.state.Game.pointsToWin)
      {
        tempGame.gameStatus=this.state.Game.teams[1].teamName + " Won " + tempGame.team2Score + " to " + tempGame.team1Score
      }
      else
      {
        tempGame.gameStatus="In Progress"
      }
      


      this.setState(
      {   
        isServing: false,
        actionType: "IDLE",
          edit: true,
        rkey: this.state.rkey+1,
        Game:tempGame,
        serveNum: this.state.serveNum =0,
        rows:list
        });
        
        this.updateGame();
  };

   
//  For adding is serving row

//  For adding is ball in play row
 isBallInPlay = () => {
  
  const listItem={
    raction_id: this.state.rows.length + 1 , sAction: "Ball In Play",
    Team1Score: this.state.team1Score, Team2Score: this.state.team2Score,
    Team1Points: 0, Team2Points: 0, rkey: this.state.rkey+1};
    const list=[listItem, ...this.state.rows];
  this.setState({   
  isServing: true,
  edit: true,
  rkey: this.state.rkey+1,
  rows:list
    });

};
     //  For adding new row object
      handleAdd = () => {
      
     
     
    // isBallInPlay()
      
    this.setState({   
      isServing: true,
      edit: true,
      actionType: "Serving"
        });
     };
   
     //  to handle edit
      handleEdit = (i) => {
         // If edit mode is true setEdit will 
         // set it to false and vice versa
         this.setState({   
          
          edit: true
            });
         
     };
   
     //  to handle save
      teamPoint = () => {
      this.setState({   
        isServing: false,
        edit: true,
        disable: true,
        open: true
          });
    if (this.state.team1Serving)  {
                        this.manualIncrementTeam1Score()
                        }
                        else
                        {
                          this.incrementTeam2Score()
                          }
      //setRows(this.state.rows);
      //console.log("saved : ", this.state.rows);
      
    };
       teamSideout= () => {
        
        
        if (this.state.team1Serving)
       {
        
        let raction = {
          game_id: 1,    raction: "Team 1 Side Out",
          t1score: this.state.team1Score, t2score: this.state.team2Score};
          
          this.insertRAction(raction) 
        
        const listItem={
          raction_id: this.state.rows.length + 1 , sAction: "Team 1 Side Out",
          Team1Score: this.state.team1Score, Team2Score: this.state.team2Score,
          Team1Points: 0, Team2Points: 0, rkey: this.state.rkey+1};
          const list=[listItem, ...this.state.rows];
        
          
       //   list.sort(function(a, b) {
       //     return b.rkey - a.rkey;})  
            //console.log(list);
        
          this.setState({   
          isServing: false,
          edit: true,
          disable: true,
          open: true,
          rkey: this.state.rkey+1,
          rows:list
            });

          }
          else
          {
            
            let raction = {
              game_id: 1,    raction: "Team 2 Side Out",
              t1score: this.state.team1Score, t2score: this.state.team2Score};
              
              this.insertRAction(raction) 
            
            const listItem={
              raction_id: this.state.rows.length + 1 , sAction: "Team 2 Side Out",
              Team1Score: this.state.team1Score, Team2Score: this.state.team2Score,
              Team1Points: 0, Team2Points: 0, rkey: this.state.rkey+1};
              const list=[listItem, ...this.state.rows];
           
             
           //   list.sort(function(a, b) {
            //    return b.rkey - a.rkey;})  
                //console.log(list);
           
              this.setState({   
            isServing: false,
            edit: true,
            disable: true,
            open: true,
            rkey: this.state.rkey+1,
            rows:list
              });
  
            }
            this.switchServe()
        //setRows(rows);
        //console.log("saved : ", this.state.rows);
        
        
    };
  
      handleSave = () => {
      
         
     };
   
     // The handleInputChange handler can be set up to handle
     // many different inputs in the form, listen for changes 
     // to input elements and record their values in state
      handleInputChange = (e, index) => {
       //  setDisable(false);
        // const { name, value } = e.target;
         //const list = [...rows];
         //list[index][name] = value;
         //setRows(list);
     };
   
     // Showing delete confirmation to users
      handleConfirm = () => {
      this.setState({   
        showConfirm: true,
        
          });
     };
   
     // Handle the case of delete confirmation where 
     // user click yes delete a specific row of id:i
      handleRemoveClick = (i) => {
       
      
      
        const list=this.state.rows;  
    if (i==this.state.rows.length-1) 
      {
        this.updateRAction(JSON.parse(this.state.rows[i].actions_comments_id),this.state.Game.gameID,
        this.state.rows[i].t1score,
        this.state.rows[i].t2score);
      }
      else 
      {  
        this.updateRAction(this.state.rows[i].actions_comments_id,this.state.Game.gameID,
        this.state.rows[i-1].t1score-this.state.rows[i].t1score,
        this.state.rows[i-1].t2score-this.state.rows[i].t2score);
      }
      this.deleteRAction(JSON.parse(this.state.rows[i].actions_comments_id),this.state.Game.gameID); 
      
      let newTeam1Score = 0
      let newTeam2Score =0
      if (i==this.state.rows.length-1) 
      {   
       newTeam1Score = this.state.rows[i].t1score
       newTeam2Score = this.state.rows[i].t2score
      }
      else
      {
        newTeam1Score = this.state.rows[i-1].t1score-this.state.rows[i].t1score
         newTeam2Score = this.state.rows[i-1].t2score-this.state.rows[i].t2score

      }


        
      for (let index = list.length-1; index >=0; index--) 
       {
        if (index <=i && index <= list.length-1)
          
        {list[index].t1score=list[index].t1score - newTeam1Score
          list[index].t2score=list[index].t2score- newTeam2Score
      
         
        }
        
      }  

      list.splice(i, 1);
         
      const tempGame=(this.state.Game);
      tempGame.team1Score=tempGame.team1Score-newTeam1Score;
      tempGame.team2Score=tempGame.team2Score-newTeam2Score;
      this.setState(
      {   
        isServing: false,
        actionType: "IDLE",
          edit: true,
        Game:tempGame,
        serveNum: this.state.serveNum =0,
        rows:list
        });
        
        this.updateGame();
        
     };
   
     // Handle the case of delete confirmation 
     // where user click no 
      handleNo = () => {
       //  setShowConfirm(false);
     };

    render()
     {
    return (
   <>   

{(this.state.redirect) && (
      <MyGamesRedirect gameURL={this.state.redirect}/>
  )
}


   
   
   <Table

      size="small"
      aria-label="a dense table"
      stickyHeader padding="none"
      className="app"
      >
        
        <TableHead stickyHeader>
          <TableRow >
            <TableCell colSpan="5"  align="right">

                
             <table  className="ScoreBoard">
                <tr>
                
                    </tr>
                    <tr>
                    <td align="center">
                    
                    <table >
                    <tr>
                               
                    <td align="center">               
                                                 
                      <table className="team">
                        <tr >
                          <td align="center">  
                          <table>
                            <td>
                          <Avatar {...this.stringAvatar(this.state.Game.teams[0].teamName)} src={"/static/public/" + this.state.Game.teams[0].teamUid+".JPEG"} variant="square" sx={{ width: 24, height: 24 }}/>   
                           </td>
                           <td>
                           {this.state.Game.teams[0].teamName}
                           </td></table>      
                          </td> 
                          </tr>
                          <tr> 
                          <td align="center">    
                      <table >
                        <tr>
                        <td className="tdScore">  

                          {this.state.Game.team1Score}
                          
                          </td> 
                          <td >  

                                <tr>
                                  <button id="team1Decrement" className="plusminus" onClick={this.manualDecrementTeam1Score}>
                                    -
                                    </button>
                                </tr> 
                                <tr>
                          
                                  <button id="team1Increment" className="plusminus" onClick={this.manualIncrementTeam1Score}>
                                    +
                                  </button>
                                  </tr>
                            </td>
                            </tr>
                          </table>
                            </td> 
                            </tr>
                              <tr>
                              <td className="players" align="center">  
                                
                                    
                                   
                                      <>    
                                            {this.state.servingPlayer.index==0 && (
                                              <SportsHandballIcon  />
                                            )
                                            
                                            }

                                        
                                        {this.state.Game.gameType=="Singles" ? (
                                          <>
                                          {this.state.Game.players[0].playerName }
                                        <Avatar {...this.stringAvatar(this.state.Game.players[0].playerName)} src={"/static/public/" + this.state.Game.players[0].playerUID + ".JPEG"}/> 
                                       
                                        </>
                                        )
                                        :
                                        (
                                          <>
                                         
                                          {this.state.Game.players[0].playerName  }
                                          
                                          <IconButton  onClick={(e) => this.switchServeT1()}>
                                           <AutorenewIcon />
                                          </IconButton>
                                          {this.state.servingPlayer.index==1 && (
                                            <SportsHandballIcon  />
                                          )
                                          
                                          }
                                          
                                          {this.state.Game.players[1].playerName }
                                          </>
                                          )
                                        }
                                     </>
                                      
                                      
                                      
                                  
                                  </td>
                                  </tr>           
                                
                            </table>    
                                       

                    </td>
                    
                    <td align="center"> 

                              <IconButton  onClick={(e) => this.switchServe("true")}>
                                <AutorenewIcon />
                              </IconButton>
                              
                    </td>
                    <td align="center">               
                                                 
                      <table  className="team">
                        <tr >
                          <td align="center"> 
                          <table>
                            <td>
                          <Avatar {...this.stringAvatar(this.state.Game.teams[1].teamName)} src={"/static/public/" + this.state.Game.teams[1].teamUid + ".JPEG"}  variant="square" sx={{ width: 24, height: 24 }}/>  
                          </td>
                          <td>
                            {this.state.Game.teams[1].teamName}
                            </td>
                            </table>         
                          </td> 
                          </tr>
                          <tr> 
                          <td align="center">    
                      <table >
                        <tr>
                        <td className="tdScore">  

                          {this.state.Game.team2Score}
                          
                          </td> 
                          <td>  

                                <tr>
                                  <button id="team2Decrement" className="plusminus" onClick={this.manualDecrementTeam2Score}>
                                    -
                                    </button>
                                </tr> 
                                <tr>
                          
                                  <button id="team2Increment" className="plusminus" onClick={this.manualIncrementTeam2Score}>
                                    +
                                  </button>
                                  </tr>
                            </td>
                            </tr>
                          </table>
                            </td> 
                            </tr>
                              <tr>
                              <td className="players"  align="center">  
                                
                                    
                                   
                                      <>    
                                            {this.state.servingPlayer.index==2 && (
                                              <SportsHandballIcon  />
                                            )
                                            
                                            }

                                        
                                        {this.state.Game.gameType=="Singles" ? (
                                          <>
                                       { this.state.Game.players[2].playerName }
                                       <Avatar {...this.stringAvatar(this.state.Game.players[2].playerName)} src={"/static/public/" + this.state.Game.players[2].playerUID + ".JPEG"}/> 
                                      
                                        </>
                                        )
                                        :
                                        (
                                          <>
                                         
                                          {this.state.Game.players[2].playerName  }
                                          <IconButton  onClick={(e) => this.switchServeT2()}>
                                           <AutorenewIcon />
                                          </IconButton>
                                          {this.state.servingPlayer.index==3 && (
                                            <SportsHandballIcon  />
                                          )
                                          
                                          }
                                          
                                          {this.state.Game.players[3].playerName }
                                          </>
                                          )
                                        }
                                     </>
                                      
                                      
                                      
                                  
                                  </td>
                                  </tr>           
                                
                            </table>    
                                       

                    </td>
                       </tr>
                      </table>
                  </td>
                  </tr>
                
                </table>
                <table className="serveTable">

                

                  
                            {(this.state.actionType == "Serving" ) && 
                                (
                                  <>
                                  <tr>
                                    <td  align="center">
                                      
                                             <>
                                             <tr>
                                               <td>
                                              <Button align="right" onClick={(e) => this.handleRActions("Ace")}>
                                                  {(this.state.showText) && ("ace")}
                                                  <LocalFireDepartmentIcon/>
                                                </Button>
                                                
                                                {(this.state.serveNum ==1) && (
                                                  <Button align="right" onClick={(e) => this.handleRActions("1stServeFault")}>
                                                  {(this.state.showText) && ("1st Serve Fault")}
                                                  <FmdBadIcon/>
                                                  </Button>
                                                  
                                                  )
                                                }

                                                {(this.state.serveNum ==2) && (
                                                  <Button align="right" onClick={(e) => this.handleRActions("2ndServeFault")}>
                                                  {(this.state.showText) && ("2nd Serve Fault")}
                                                  <FmdBadIcon/>
                                                  </Button>
                                                  
                                                )}
                                                </td>
                                             </tr>
                                              </>
                                          

                                            {(!this.state.scoringAdvanced) ? (
                                              <>
                                                <Button align="right" onClick={(e) => this.handleRActions("Point")}>
                                                  {(this.state.showText) && ("Point")}
                                                  <DoneIcon/>
                                                  </Button>
                                                  
                                                  <Button align="right" onClick={(e) => this.handleRActions("Sideout")}>
                                                  {(this.state.showText) && ("Sideout")}
                                                  <OutboundIcon/>
                                                  </Button>

                                                  <Button align="right" onClick={(e) => this.handleRActions("Back")}>
                                                  {(this.state.showText) && ("Back")}
                                                  <SettingsBackupRestoreIcon/>
                                                  </Button>
                                                  </>
                                              )
                                                :
              (
                <>
                          {(this.state.scoring1stClick) ? (
                              <>
                                  <Button align="right" onClick={(e) => this.handleRActions("PointAdvanced")}>
                                  {(this.state.showText) && ("Point")}
                                  <DoneAllIcon/>
                                  </Button>
                                  
                                  <Button align="right" onClick={(e) => this.handleRActions("SideoutAdvanced")}>
                                  {(this.state.showText) && ("Sideout")}
                                  <OutboundIcon/>
                                  </Button>
                                  <Button align="right" onClick={(e) => this.handleRActions("Back")}>
                                  {(this.state.showText) && ("Back")}
                                  <SettingsBackupRestoreIcon/>
                                  </Button>
                                  </>
                            )
                            :
                            (
                            <>
    
                              {(!this.state.team1Serving) ? (
                                                  <>
                                                  <tr>
                                                    <td>
                                                    {(this.state.pointAdvanced) ? (  
                                                        <h4>Point: {this.state.servingPlayer.playerName}</h4>
                                                      )
                                                      :
                                                      ( <ToggleButtonGroup
                                                          
                                                        value={this.state.defendingPlayer}
                                                        exclusive
                                                        onChange={this.handleToggleChange}
                                                      >
                                                      <ToggleButton value={this.state.Game.players[0]}>{this.state.Game.players[0].playerName}</ToggleButton>
                                                      <ToggleButton value={this.state.Game.players[1]}>{this.state.Game.players[1].playerName}</ToggleButton>
                                                      </ToggleButtonGroup>
                                                      )
                                                      
                                                    }
                                                    </td>
                                                  </tr>
                                                    </>
                              
                                )
                                :
                                (
                                  <>
                                  <tr>
                                    <td>
                                      
                                    {(this.state.pointAdvanced) ? (  
                                        <ToggleButtonGroup
                                          
                                          value={this.state.servingPlayer}
                                          exclusive
                                          onChange={this.handleToggleChange}
                                        >
                                        Point: <ToggleButton value={this.state.servingPlayer}>{this.state.servingPlayer.playerName}</ToggleButton>
                                        
                                        </ToggleButtonGroup>
                                      )
                                      :
                                      ( <ToggleButtonGroup
                                          
                                        value={this.state.defendingPlayer}
                                        exclusive
                                        onChange={this.handleToggleChange}
                                      >
                                      <ToggleButton value={this.state.Game.players[2]}>{this.state.Game.players[2].playerName}</ToggleButton>
                                      <ToggleButton value={this.state.Game.players[3]}>{this.state.Game.players[3].playerName}</ToggleButton>
                                      </ToggleButtonGroup>
                                      )
                                      
                                    }

                                  </td>
                                  </tr>
                                  </>
                                )
                              }
  


                            <tr>
                              <td>

                              
                              <Button align="right" onClick={(e) => this.handleRActions("Splat")}>
                                {(this.state.showText) && ("Splat")}
                                <PriorityHighIcon />
                              </Button>
                              <Button align="right" onClick={(e) => this.handleRActions("Rollout")}>
                                {(this.state.showText) && ("Rollout")}
                                <TrendingDownIcon />
                              </Button>
                              <Button align="right" onClick={(e) => this.handleRActions("Pinch")}>
                                {(this.state.showText) && ("Pinch")}
                                <PinchIcon />
                              </Button>
                              </td>
                            </tr>
                            <tr>
                              <td>
                              <Button align="right" onClick={(e) => this.handleRActions("Unreturnable")}>
                                {(this.state.showText) && ("Unreturnable")}
                                <InsightsIcon />
                              </Button>


                              <Button align="right" onClick={(e) => this.handleRActions("Hinder")}>
                                {(this.state.showText) && ("Hinder")}
                                <AutorenewIcon />
                              </Button>

                              <Button align="right" onClick={(e) => this.handleRActions("Back")}>
                                {(this.state.showText) && ("Back")}
                                <SettingsBackupRestoreIcon />
                              </Button>
                              </td>
                            </tr>
                            </>
                            )
                          }
              </>
              )
            }
                                        
                                        
                                
                                        </td>    
                             </tr>
                                        </>
                                  )
                                  
                                }
                           
                             </table>
                             <table className="serveTable">
                            
                              

                              {console.log(this.state.actionType)}
                              {(this.state.actionType == "IDLE") && (
                                 <tr>
                                 <td  align="center">
                                

                                  {(this.state.Game.team1Score>= this.state.Game.pointsToWin ||  this.state.Game.team2Score>= this.state.Game.pointsToWin) ? (
                                  <button className="Servebutton" onClick={(e) => this.handleRActions("Finish")}>
                                    <SportsHandballIcon />
                                    Finish
                                  
                                    <EmojiPeopleIcon />
                                
                                  </button>
                                )
                              :
                              (

                                <button className="Servebutton" onClick={(e) => this.handleRActions("Serve")}>
                                <SportsHandballIcon />
                                Serve
                              
                                <EmojiPeopleIcon />
                            
                              </button>
                              )
                              }


 </td> 
                            </tr>
                              )}
                            
                           
                            </table>
                            <table className="optionsTable">
                            <tr> 
                            <td    align="center">
                
                 
                                  <FormControlLabel 
                                        control={<Checkbox defaultChecked 
                                            
                                            onChange={(e) => this.handleRActions("showText")}
                                        />
                                        }
                                        label={
                                          <Box className="optionsLabel">
                                             Show Text
                                           </Box>
                                     }
                                     />
                                    <FormControlLabel
                                        control={<Checkbox 
                                            
                                            onChange={(e) => this.handleRActions("scoringAdvanced")}
                                        />
                                        }
                                        label={
                                          <Box className="optionsLabel">
                                             Advanced Scoring
                                           </Box>
                                     }/>
                                
                                </td>
                                </tr>
                                </table>
                                <table className="optionsTable">
                                <tr> 
                            <td  align="center">
                           
                               
                                <button className="Servebutton" onClick={(e) => this.handleShare()}>
                                Share this Game 
                                </button>
                                <Collapse in={this.state.urlOpen}>
        <Alert
          action={
            <IconButton
              aria-label="close"
              color="inherit"
              size="small"
              onClick={(e) => this.setURLOpen(false)}
            >
              <CloseIcon fontSize="inherit" />
            </IconButton>
          }
          sx={{ mb: 2 }}
        >
          Game Link Copied to Clipboard
        </Alert>
      </Collapse>    
                                </td>
                                </tr>
                                </table>


                           



                     
                    </TableCell>  
              </TableRow>
              <TableRow>
<td  colSpan={5} align="center">
    <table className="ScoreBoard">
              <TextField
          id="comment"
          value={this.state.gameComment}
          label="comment"
          type="search"
          size="small"
          variant="filled" onChange={(e) => this.handleInputCommentChange(e)}
          /> 
		<IconButton className="mr10" name="IMAGE_UPLOAD_BUTTON" aria-label="upload picture" component="label" onChange={(e) => this.handleImageChange(e)}>
					<input hidden name="IMAGE_UPLOAD" accept="image/*" type="file" />
					<PhotoCamera />
				</IconButton>
			
<Button align="right" onClick={(e) => this.handleGameComment()}>
                                 
                                  <SendIcon/>
 </Button>
 </table>
 </td>
 
              </TableRow>
              </TableHead>           
              <TableBody  >  
                         <TableRow >
                         
                                <TableCell  >
                                 
                                  </TableCell>
                               
                                <TableCell className="rActionScore" align="center">{this.state.Game.teams[0].teamName}
                                </TableCell>
                                <TableCell></TableCell>
                                <TableCell className="rActionScore" align="center">{this.state.Game.teams[1].teamName}
                                </TableCell>
                                
                                <TableCell>
                                  
                                </TableCell>
                                      
                            </TableRow>
                             
                            {this.state.rows.map((row, i) => {
                              return (

                                <TableRow>

                                  <TableCell className="rAction">
                                  {(row.display_type == 'action') ? (
                                       <>
                                       {((row.raction_type_id >= 2 && row.raction_type_id <=8) ||(row.raction_type_id ==101 )) ? (
                                        <>
                                        <Avatar {...this.stringAvatar(row.defending_p_name)} src={"/static/public/" +  row.defending_p_uid +".JPEG"} variant="square" sx={{ width: 24, height: 24 }}/>            
                                        </>
                                        
                                        )
                                      :
                                      (<Avatar {...this.stringAvatar(row.serving_p_name)} src={"/static/public/" +  row.serving_p_uid +".JPEG"} variant="square" sx={{ width: 24, height: 24 }}/>        
                                          )
                                          
                                      }
                                      {row.display_text}
                                      </>
                                      )
                                      :
                                      (
                                        <>
                                      
                                      
                                        <Avatar {...this.stringAvatar(row.createdby_name)} src={"/static/public/" +row.createdby_uid + ".JPEG"} variant="square" sx={{ width: 24, height: 24 }}/>  
                                        {row.createdby_name + " said: " + row.display_text} 
                                        {(row.is_image == 1) && (
                                         <>
                                         <img src={"/static/public/" +row.game_comment_uid + ".JPEG"} alt="Italian Trulli" width="500"/>
                                          
                                        </>
                                        )
                                        }
                                        </>
                                      )
                                  }
                                  </TableCell>
                                  <TableCell align="center" className="rActionScore">
                                    {row.t1score}
                                  </TableCell>
                                  <TableCell></TableCell>
                                  <TableCell align="center" className="rActionScore">
                                    {row.t2score}
                                  </TableCell>
                                  <TableCell className="tdDelete">

                                    <Button
                                      onClick={() => this.handleRemoveClick(i)}
                                      
                                      
                                    >
                                      <DeleteIcon />
                                    </Button>
                                  </TableCell>



                                  {this.state.showConfirm && (
                                  
                                      <Dialog
                                        open={this.state.showConfirm}
                                        onClose={this.handleNo}
                                        aria-labelledby="alert-dialog-title"
                                        aria-describedby="alert-dialog-description"
                                      >
                                        <DialogTitle id="alert-dialog-title">
                                          {"Confirm Delete"}
                                        </DialogTitle>
                                        <DialogContent>
                                          <DialogContentText id="alert-dialog-description">
                                            Are you sure to delete
                                          </DialogContentText>
                                        </DialogContent>
                                        <DialogActions>
                                          <Button
                                            onClick={() => this.handleRemoveClick(i)}
                                            color="primary"
                                            autoFocus
                                          >
                                            <DeleteIcon />
                                          </Button>
                                          <Button
                                            onClick={this.handleNo}
                                            color="primary"
                                            autoFocus
                                          >
                                            No
                                          </Button>
                                        </DialogActions>
                                      </Dialog>
                                  
                                  )}
                                </TableRow>

                              );
                            }
                  )}
              </TableBody>
              </Table>
              </>         
      
      )
    }
  
 
  }
    export default withParams(ScoreGame);