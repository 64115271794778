import React, { useState, useEffect } from "react";
import CreateIcon from "@material-ui/icons/Create";

import AppBar from "./appBar";
import SettingsAppBar from "./SettingsAppBar";

import Paper from '@mui/material/Paper';
import TableContainer from '@mui/material/TableContainer';
import TablePagination from '@mui/material/TablePagination';

import {
    Box, Button, Snackbar, Table,
    TableBody, TableCell, TableHead, TableRow
} from "@material-ui/core";
import DeleteOutlineIcon from "@material-ui/icons/DeleteOutline";
import AddBoxIcon from "@material-ui/icons/AddBox";
import DoneIcon from "@material-ui/icons/Done";
import ClearIcon from "@material-ui/icons/Clear";
import { makeStyles } from "@material-ui/core/styles";
import Alert from "@material-ui/lab/Alert";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import SportsHandballIcon from '@material-ui/icons/SportsHandball';
import AutorenewIcon from '@material-ui/icons/Autorenew';
import DeleteIcon from '@material-ui/icons/Delete';
import SportsTennisIcon from '@material-ui/icons/SportsTennis';
import EmojiPeopleIcon from '@material-ui/icons/EmojiPeople';
import EditIcon from '@material-ui/icons/Edit';
import AddIcon from '@material-ui/icons/Add';
import SaveIcon from '@material-ui/icons//Save';

import Userfront from "@userfront/react";

const requestOptions = {
    method: 'GET',
    headers: { 'Content-Type': 'application/json', 
    Authorization: `Bearer ${Userfront.tokens.accessToken}` }
  };
  
// Creating styles
const useStyles = makeStyles({
	root: {
		"& > *": {
			borderBottom: "unset",
		},
	},

	snackbar: {
		bottom: "104px",
	},
});

function MyPlayersTable({mSelectedClub, mSelectedTeam}) {
	// Creating style object
	const classes = useStyles();

	// Defining a state named rows
	// which we can update by calling on setRows function
	const [rows, setRows] = useState([]);
  const [sRows, setSRows] = useState([])
  

 
  //const [rows, setRows] = useState([
//		{ id: 1, firstname: "", lastname: "", city: "" },
//	]);

	// Initial states
	const [open, setOpen] = React.useState(false);
	const [isEdit, setEdit] = React.useState(false);
  const [editRow, setEditRow] = React.useState(-1);
  const [isAdd, setIsAdd] = React.useState(false);
	const [disable, setDisable] = React.useState(true);
	const [showConfirm, setShowConfirm] = React.useState(false);
	const [page, setPage] = React.useState(0);
	const [loggedInUser,setLoggedInUser] = React.useState(Userfront.user);
	const [isInitialized,setIsInitialized] = React.useState(false);

const [selectedMatch, setSelectedMatch] = useState(null);
const [stateChanger, setStateChanger] = useState(false);
const [selectedTeam, setSelectedTeam] = useState(mSelectedTeam);
const [selectedClub, setSelectedClub] = useState(mSelectedClub);
const [rowsPerPage, setRowsPerPage] = React.useState(5);

	//useEffect(() => {
	//	InitializeData()
	//	})
	
	// Showing delete confirmation to users
	const handleInitialized = () => {
		setIsInitialized(!isInitialized);
	};
	const InitializeData = () => {
   
		const requestOptions = {
			method: 'GET',
			headers: { 'Content-Type': 'application/json', 
			Authorization: `Bearer ${Userfront.tokens.accessToken}` }
		  };
		  
	if (mSelectedTeam != null)
	{
		fetch("/api/myplayers?rbt="+mSelectedTeam,requestOptions)
		.then((res)  => {
            if (res.status != 404)
            {
              return res.json()
            }
            else
            {
				setSRows([]);
              return res;
            }
            }
          )
               .then((res)=> {
                if (res.status != 404)
                {
					setSRows(res);
                }
                
                    
                })
          // .catch(error => {
            //  console.log("throw me an error on players data" +  error)
              //  }  )
		  } //end if
		}
	  
	  


	const handleChangePage = (event, newPage) => {
		setPage(newPage);
	  };


	  const handleChangeRowsPerPage = (event) => {
		setRowsPerPage(parseInt(event.target.value, 10));
		setPage(0);
	  };

	  


	// The handleInputChange handler can be set up to handle
	// many different inputs in the form, listen for changes
	// to input elements and record their values in state
	const handleInputChange = (e, index) => {
		setDisable(false);
		const { name, value } = e.target;
		const list = [...rows];
		list[index][name] = value;
		setRows(list);
	};

  	// The handleInputChange handler can be set up to handle
	// many different inputs in the form, listen for changes
	// to input elements and record their values in state
	const handleInputChangePlayers = (e, index) => {
		setDisable(false);
		const { name, value } = e.target;
		const list = [...sRows];
		list[index][name] = value;
		setSRows(list);
	};


	

	// Handle the case of delete confirmation
	// where user click no
	const handleNo = () => {
		setShowConfirm(false);
	};
	const emptyRows =
    page > 0 ? Math.max(0, (1 + page) * rowsPerPage - rows.length) : 0;

	if (!isInitialized) {
	InitializeData();
setIsInitialized(true)
	}
	
return (
  

  


   

    <Box sx={{ flexGrow: 12}}>
      <Paper>
        
        <TableContainer>
    <Table
		 className="myMatch"
		size="small"
		aria-label="a dense table"
		>
		<TableHead>
			<TableRow>
			<TableCell>PLAYER Name</TableCell>
			<TableCell>PLAYER Last Name</TableCell>
			<TableCell>PLAYER Nick Name</TableCell>
			<TableCell align="center"></TableCell>
			</TableRow>
		</TableHead>
		<TableBody>
      {(sRows) && (
        <> {}
			{sRows.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row, i) => {
			return (
				<>
				<TableRow>
					{isEdit   && i==editRow ? (
					<>
						<TableCell padding="none">
						<input
							value={row.PLAYER_FIRST_NAME}
							name="PLAYER_FIRST_NAME"
							 onChange={(e) => handleInputChangePlayers(e, i)}
						/>
						</TableCell>
						<TableCell padding="none">
						<input
							value={row.PLAYER_LAST_NAME}
							name="PLAYER_LAST_NAME"
							 onChange={(e) => handleInputChangePlayers(e, i)}
						/>
						</TableCell>
						<TableCell padding="none" colSpan={2}>
						<input
							value={row.PLAYER_SHORT_NAME}
							name="PLAYER_SHORT_NAME"
							onChange={(e) => handleInputChangePlayers(e, i)}
						/>
					
     
          </TableCell>
            </>
					) : (
					<>
						<TableCell component="th" scope="row">
						{row.PLAYER_FIRST_NAME}
						</TableCell>
						<TableCell component="th" scope="row">
						{row.PLAYER_LAST_NAME}
						</TableCell>
						<TableCell component="th" scope="row">
						{row.PLAYER_SHORT_NAME}
						</TableCell>
					
						<TableCell
						component="th"
						scope="row"
						align="center"
						>
		
          </TableCell>
          
          </>

					)}
				
					
				</TableRow>
				</>
			);
			})}
      </>
      )}
		</TableBody>
		</Table>
		</TableContainer>
        <TablePagination
          rowsPerPageOptions={[5, 10, 25]}
          component="div"
          count={sRows.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </Paper>
     
    </Box>
    


  
);
}
    export default MyPlayersTable;