import React, { useState } from "react";
import Button from "./components/Button";

import Userfront from "@userfront/react";


import "./assets/css/style.css";


import {
  
  Routes,
  Route,
 
  Navigate,
  useLocation,
} from "react-router-dom";

import Home from "./routes/home";
import ScoreGameRoute from "./routes/scoregameroute";
import ViewGameRoute from "./routes/viewgameroute";
import TablePlayersRoute from "./routes/tableplayersroute";
import MyMatches from "./routes/mymatches";
import SetupMatch from "./routes/setupmatch";
import SignUp from "./routes/signup";
import MyGames from "./routes/mygames";
import MyGamesDashboard from "./routes/mygamesdashboard";
import MyMatchesDashboard from "./routes/mymatchesdashboard";
import MyClubs from "./routes/myclubsroute";
import MyTeams from "./routes/myteamsroute";
import MyPlayers from "./routes/myplayersroute";
import MyClubAccess from "./routes/myclubaccessroute";
import MyUsers from "./routes/myusersroute";
import ViewMatches from "./routes/viewmatches";
import AppBar from "./appBar";
import MyFavoriteClubs from "./routes/myfavoriteclubsroute";

Userfront.init("8nwrp6db");


const SignupForm = Userfront.build({
  toolId: "adrnmb"
});

const LoginForm = Userfront.build({
  toolId: "omdnbo"
});


const PasswordResetForm = Userfront.build({
  toolId: "blrkon"
});


const LogoutButton = Userfront.build({
  toolId: "rlodkd"
});





export default function App() {
 
  
 
  return (
   
    <div>
        
        <div className="App">

<AppBar/>


</div>
    <Routes>
    <Route path="/" element={<Home />} />
    <Route path="/.well-known" element={<Home />} />
      <Route path="/home" element={<Home />} />
      <Route path="/mymatches/match/:match" element={<RequireAuth><MyMatches /></RequireAuth>} />
      <Route path="/viewmatches/match/:match" element={<RequireAuth><ViewMatches /></RequireAuth>} />
      <Route path="/mymatches/" element={<RequireAuth><MyMatches /></RequireAuth>} />
      <Route path="/mygames" element={<RequireAuth><MyGames /></RequireAuth>} />
      <Route path="/mygamesDashboard" element={<RequireAuth><MyGamesDashboard /></RequireAuth>} />
      
      <Route path="/MyMatchesDashboard" element={<MyMatchesDashboard />} />
      <Route path="/SetupMatch" element={<RequireAuth><SetupMatch /></RequireAuth>} />
      <Route path="/signup" element={<SignUp />} />
      <Route path="/login" element={<Login />} />
      <Route path="/reset" element={<PasswordReset />} />
      <Route path="/dashboard" element={<RequireAuth><MyMatchesDashboard /></RequireAuth>} />
      <Route path="scoregame/game/:game" element={<RequireAuth><ScoreGameRoute /></RequireAuth>} />
      <Route path="viewgame" element={<ViewGameRoute />} />
      <Route path="viewgame/game/:game" element={<ViewGameRoute />} />
      <Route path="tableplayers" element={<RequireAuth><TablePlayersRoute /></RequireAuth>} />
      <Route path="myclubs" element={<RequireAuth><MyClubs /></RequireAuth>} />
      <Route path="myfavoriteclubs" element={<RequireAuth><MyFavoriteClubs /></RequireAuth>} />
      <Route path="myteams" element={<RequireAuth><MyTeams /></RequireAuth>} />
      <Route path="myplayers" element={<RequireAuth><MyPlayers /></RequireAuth>} />
      <Route path="MyClubAccess" element={<RequireAuth><MyClubAccess /></RequireAuth>} />
      <Route path="myusers" element={<RequireAuth><MyUsers /></RequireAuth>} />
    </Routes>
 

  
   
 
   
 

</div>
  );
  function PasswordReset() {
    return (
      <div>
        <h2>Password Reset</h2>
        <PasswordResetForm />
      </div>
    );
  }
  
  function Dashboard() {
    const userData = JSON.stringify(Userfront.user, null, 2);
    return (
      <div>
        <h2>Dashboard</h2>
        <pre>{userData}</pre>
        <pre>{Userfront.tokens.accessToken}</pre>
        <button onClick={Userfront.logout}>Logout</button>
      </div>
    );
  }
  
  
  function RequireAuth({ children }) {
    let location = useLocation();
    if (!Userfront.tokens.accessToken) {
      // Redirect to the /login page
      return <Navigate to="/login" state={{ from: location }} replace />;
    }
  
    return children;
  }
  function Login() {
    return (
      <div className="match"  >
        
   
  
    
        
        <LoginForm />
      </div>
    );
  }
}

