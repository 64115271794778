import { useNavigate } from 'react-router-dom';
import React, { useState, useEffect } from 'react';
const MyGamesRedirect = (props) => {

   
    let navigate = useNavigate();
    useEffect(() => {
        if (1){
            handleClick(props.gameURL) 
        }
    },[])
    

function handleClick(gameURL) {
        navigate( gameURL);
      }
  
    

    return (
<>  </>      
)
      }
    
   
    
      export default MyGamesRedirect;