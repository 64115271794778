import { Aod } from "@mui/icons-material";
import React, { useState } from "react";

import dayjs, { Dayjs } from "dayjs";

import AppBar from "./appBar";
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import Select from '@mui/material/Select';

import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';

import FormHelperText from '@mui/material/FormHelperText';
import Input from '@mui/material/Input';

import OutlinedInput from '@mui/material/OutlinedInput';
import FilledInput from '@mui/material/FilledInput';

import SportsHandballIcon from '@material-ui/icons/SportsHandball';
import EmojiPeopleIcon from '@material-ui/icons/EmojiPeople';

import Userfront from "@userfront/react";

import MyGamesRedirect from "./MyGamesRedirect";

import  { DateTimePicker }  from '@mui/x-date-pickers/DateTimePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
// or for Day.js
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';

function  SetupMatch () {
  

    const [match,setMatch] = React.useState({ MATCH_NAME: dayjs().format('MMMM D, YYYY h:mm A'),
    MATCH_LOCATION_ID: "1",
    NUM_GAMES: "1",
    MATCH_DATE: dayjs().format('MMMM D, YYYY h:mm A'),
    TEAM1_ID: 1,
    TEAM1P1_ID: 1,
    TEAM1P2_ID: 2,
    TEAM2P1_ID: 3,
    TEAM2P2_ID: 4,
    TEAM2_ID: 2,
    MATCH_TYPE: "Singles",
    POINTSTOWIN: 15,
    MATCH_OWNER: null,
    MATCH_STATUS: "Created",
    START_TIME: null,
    END_TIME: null 
  } );
  const [redirect,setRedirect] = React.useState(null);   
  const [teams,setTeams] = React.useState([]);
  const [team1Players,setTeam1Players] = React.useState([]);
  const [team2Players,setTeam2Players] = React.useState([]);    
  const [myDateValue, setMyDateValue] = React.useState(    dayjs(new Date())  );
  const [isInitialized,setIsInitialized] = React.useState(false);



const handleDateChange = (newValue: Dayjs | null) => {
  setMyDateValue(newValue);
  var tempMatch=match
  tempMatch.MATCH_NAME=dayjs(newValue).format('MMMM D, YYYY h:mm A')
  setMatch(tempMatch);
};



const InitializeData = () => {
  const loggedInUser = Userfront.user;
		
	const requestOptions = {
    method: 'GET',
    headers: { 'Content-Type': 'application/json', 
    Authorization: `Bearer ${Userfront.tokens.accessToken}` }
  };
  //fetch teams data is in progress
  fetch("/api/myteams?rbo="+loggedInUser.userUuid, requestOptions)
  .then((res) => res.json())
  .then((teamsData)=> {
   
    
      setTeams(teamsData);
    
     
   })

 //fetch Team 1 players 
 fetch("/api/myPlayers?rbt="+match.TEAM1_ID, requestOptions)
 .then((res)  => {
  if (res.status != 404)
  {
    return res.json()
  }
  else
  {
    
      setTeam1Players([]);
    }
    return res;
  }
  
)
     .then((res)=> {
      if (res.status != 404)
      {
        
          setTeam1Players(res);
        
      }
      
          
      })
  .catch(error => {
    //console.log("throw me an error " +  error)
      }  )
  fetch("/api/myPlayers?rbt="+match.TEAM2_ID, requestOptions)
 
  
  .then((res)  => {
    if (res.status != 404)
    {
      return res.json()
    }
    else
    {
      
        setTeam2Players([]);
      
      return res;
    }
    }
  )
       .then((res)=> {
        if (res.status != 404)
        {
          
            setTeam2Players(res);
          
        }
        
            
        })
    .catch(error => {
      //console.log("throw me an error " +  error)
        }  )
}

  
const handleRActions = (actionType) => 
  {
    const clickedButtonName= actionType;
    switch (clickedButtonName) {
      case "Serve":
        //
        break;
    case "Back":
      
        //
      
    break;
 
     
    }        
  };

const handleChange = (e) => {
  
  const { name, value } = e.target;
  const myMatch = match;
  myMatch[name] = value;
  //console.log("name: " + name + " value: " + value)
  
    setMatch(myMatch);
   // if (name == "TEAM1_ID" || name == "TEAM1P1_ID" || name =="TEAM1P2_ID" || name =="TEAM2P1_ID" || name == "TEAM2P2_ID" || name == "TEAM2_ID") 
   // {   
       InitializeData();
   // }
  }
  
  
  const  appendLeadingZeroes = (n) => {
  if (n <= 9) {
    return "0" + n;
  }
  return n
}

const handleSetupMatch = (clickedButtonName) => {
  
    //alert(clickedButtonName)
    const loggedInUser = Userfront.user;
    let setupMatch = match
    setupMatch.MATCH_OWNER=loggedInUser.userUuid
    var current_datetime = new Date(setupMatch.MATCH_DATE);
    var formatted_date = current_datetime.getFullYear() + "-" +
      appendLeadingZeroes(current_datetime.getMonth() + 1) + "-" +
      appendLeadingZeroes(current_datetime.getDate()) + "_" +
      appendLeadingZeroes(current_datetime.getHours()) + ":" +
      appendLeadingZeroes(current_datetime.getMinutes()) + ":" +
      appendLeadingZeroes(current_datetime.getSeconds());

      //console.log(formatted_date)
    setupMatch.MATCH_DATE=formatted_date
    setupMatch.TEAM1_ID=
    setupMatch.TEAM2_ID=
    insertMatch(setupMatch)
    };
             
    

const insertMatch = (match) => {

  const requestOptions = {
    method: 'POST',
    headers: { 'Content-Type': 'application/json', 
    Authorization: `Bearer ${Userfront.tokens.accessToken}` },
    body: JSON.stringify(match)
};
fetch('api/setupMatch', requestOptions)
    .then(response => response.json())
    .then(data => {
      //console.log(data.MATCH_UID)
      setRedirect("/mymatches/match/"+data.MATCH_UID );
  
    })
  }

  if ((!isInitialized))
	{
	InitializeData();
	setIsInitialized(true);	
	
	}
    
    return (
      
      <div className="match">

{(redirect) && (
      <MyGamesRedirect gameURL={redirect}/>
  )
}
       
        <LocalizationProvider dateAdapter={AdapterDayjs}>
      
        <Box
      component="form"
      sx={{
        '& > :not(style)': { m: 1 },
      }}
      noValidate
      autoComplete="off"
    >
      <FormControl variant="standard">
        <InputLabel htmlFor="component-simple">Match Name</InputLabel>
        <Input className="matchInput" id="MATCH_NAME" name="MATCH_NAME" value={match.MATCH_NAME} onChange={handleChange} />
      
      </FormControl>
<div>

<DateTimePicker className="matchInput"
          label="Match Date"
          value={myDateValue}
          onChange={handleDateChange}
          renderInput={(params) => <TextField {...params} />}
        />
</div>
      <div>
      <FormControl>
     
      <RadioGroup
        row
        aria-labelledby="MATCH_TYPE"
        name="MATCH_TYPE"
        value={match.MATCH_TYPE}
        onChange={handleChange}
      >
        
        <FormControlLabel value="Singles" control={<Radio />} label="Singles" />
        <FormControlLabel value="Doubles" control={<Radio />} label="Doubles" />

      </RadioGroup>
      </FormControl>

    </div>

    <div>
      <FormControl sx={{ m: 1, minWidth: 175 }}>
      <InputLabel id="NUM_GAMES">Number of Games</InputLabel>
        <Select
          labelId="NUM_GAMES"
          id="demo-simple-select"
          value={match.NUM_GAMES}
          label="Number of Games"
          name="NUM_GAMES"
          onChange={handleChange}
        >
          <MenuItem value={1}>One</MenuItem>
          <MenuItem value={3}>Three</MenuItem>
          <MenuItem value={5}>Five</MenuItem>
        </Select>
        </FormControl>
        
        <FormControl sx={{ m: 1, maxWidth: 120 }}>
        <TextField
          id="outlined-number"
          label="Points to Win"
          name="POINTSTOWIN"
          type="number"
          value={match.POINTSTOWIN}
          onChange={handleChange}
          InputLabelWe={{
            shrink: true,
          }}
        />
        </FormControl>
    </div>
    </Box>
    <Box
      component="form"
      sx={{
        '& > :not(style)': { m: 1 },
      }}
      noValidate
      autoComplete="off"
    >


    <div>
      
      <FormControl sx={{ m: 1, minWidth: 125 }}>
      <InputLabel id="TEAM1_ID">Team 1</InputLabel>
        <Select
          labelId="TEAM1_ID"
          id="TEAM1_ID"
          value={match.TEAM1_ID}
          label="TEAM1_ID"
          name="TEAM1_ID"
          onChange={handleChange}
          
        >
           {teams.map((team, i) => {
                              return (
          <MenuItem value={team.TEAM_ID}>{team.TEAM_NAME}</MenuItem>
                                
                              );
           }
           )
          }
         
        </Select>
        </FormControl>

        </div>
        <div>
      <FormControl sx={{ m: 1, minWidth: 125 }}>
      <InputLabel id="TEAM1P1_ID">Player 1</InputLabel>
        <Select
          labelId="TEAM1P1_ID"
          id="TEAM1P1_ID"
          value={match.TEAM1P1_ID}
          label="TEAM1P1_ID"
          name="TEAM1P1_ID"
          onChange={handleChange}
          
        >
         
         {team1Players.map((player, i) => {
                              return (
          <MenuItem value={player.PLAYER_ID}>{player.PLAYER_SHORT_NAME}</MenuItem>
                                
                              );
           }
           )
          }
         
         
        </Select>
        </FormControl>
        {(match.MATCH_TYPE=="Doubles") && (
        <FormControl sx={{ m: 1, minWidth: 125 }}>
      
            <InputLabel id="TEAM1P2_ID">Player 2</InputLabel>
              <Select
                labelId="TEAM1P2_ID"
                id="TEAM1P2_ID"
                value={match.TEAM1P2_ID}
                label="TEAM1P2_ID"
                name="TEAM1P2_ID"
                onChange={handleChange}
                
              >
                {team1Players.map((player, i) => {
                              return (
          <MenuItem value={player.PLAYER_ID}>{player.PLAYER_SHORT_NAME}</MenuItem>
                                
                              );
           }
           )
          }
              
              </Select>
              </FormControl>
        )}
        </div>
        </Box>
        <Box
      component="form"
      sx={{
        '& > :not(style)': { m: 1 },
      }}
      noValidate
      autoComplete="off"
    >

        <div>
      <FormControl sx={{ m: 1, minWidth: 125 }}>
      <InputLabel id="TEAM2_ID">Team 2</InputLabel>
        <Select
          labelId="TEAM2_ID"
          id="TEAM2_ID"
          value={match.TEAM2_ID}
          label="TEAM2_ID"
          name="TEAM2_ID"
          onChange={handleChange}
          
        >
         {teams.map((team, i) => {
                              return (
          <MenuItem value={team.TEAM_ID}>{team.TEAM_NAME}</MenuItem>
                              );
           }
           )
          }
         
        </Select>
        </FormControl>

        </div>
        <div>
      <FormControl sx={{ m: 1, minWidth: 125 }}>
      <InputLabel id="TEAM2P1_ID">Player 
      {(match.MATCH_TYPE=="Doubles") ? (3 ): (2)}
      
      </InputLabel>
        <Select
          labelId="TEAM2P1_ID"
          id="TEAM2P1_ID"
          value={match.TEAM2P1_ID}
          label="TEAM2P1_ID"
          name="TEAM2P1_ID"
          onChange={handleChange}
          
        >
          {team2Players.map((player, i) => {
                              return (
          <MenuItem value={player.PLAYER_ID}>{player.PLAYER_SHORT_NAME}</MenuItem>
                                
                              );
           }
           )
          }
         
        </Select>
        </FormControl>
        {(match.MATCH_TYPE=="Doubles") && (
        <FormControl sx={{ m: 1, minWidth: 125 }}>
      <InputLabel id="TEAM2P2_ID">Player 4</InputLabel>
        <Select
          labelId="TEAM2P2_ID"
          id="TEAM2P2_ID"
          value={match.TEAM2P2_ID}
          label="TEAM2P2_ID"
          name="TEAM2P2_ID"
          onChange={handleChange}
          
        >
        {team2Players.map((player, i) => {
                              return (
          <MenuItem value={player.PLAYER_ID}>{player.PLAYER_SHORT_NAME}</MenuItem>
                                
                              );
           }
           )
          } 
        </Select>
        </FormControl>
        )
        }

        </div>
    </Box>
    
    </LocalizationProvider>
      <div align="center">
    <button className="Servebutton" onClick={(e) => handleSetupMatch("Start")}>
        <SportsHandballIcon />
        Start                                
    </button>

    </div>




        </div>
      )
    }
  
 
  
    export default SetupMatch;