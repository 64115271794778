import { Aod } from "@mui/icons-material";
import React, { useState } from "react";

import MyGamesRedirect from "./MyGamesRedirect";

import dayjs from "dayjs";

import AppBar from "./appBar";
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import Select from '@mui/material/Select';

import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import {
  Button, Table,
 TableBody, TableHead, TableRow,
} from "@material-ui/core";


import SportsHandballIcon from '@material-ui/icons/SportsHandball';
import EmojiPeopleIcon from '@material-ui/icons/EmojiPeople';
import SettingsBackupRestoreIcon from '@mui/icons-material/SettingsBackupRestore';

import Userfront from "@userfront/react";
import { TableCell } from "@mui/material";




class ViewMyGames extends React.Component {
  
  constructor(props) {
    super(props);
    this.state = {
      
      game:
      {
        matchID: null,  
        matchUID: null,  
        gameNum:null ,
        gameStatus: null,
        team1Serving:  null,
        t1score:  null,
        t2score:  null,
        start_time: null,
        end_time:  null,
       },
      servingPlayer: { playerID: 0, index: 0,playerName: "T1p1"},
      Redirect: null

}


}




  handleScoreGameClick = (i) => {
  
    const game = this.props.games[i];
    if (game.gameUID == null)
    {
      //console.log("Game Clicked: game_UID is NULL" )
      
      
    }      
    else
    {
      //console.log("Game In Progress Clicked: " + game.gameUID)
      // exsting game go to score game with game uid
     // const navigate = useNavigate();
      
   //const denis= () =>  {this.props.navigation.navigate("../scoregame/game/"+game.gameUID)};
  

     
   this.setState({ redirect: "../scoregame/game/"+game.gameUID });
    }
    

      
  }; 

  
  
    render()
     {
     
    return (
      
      <div className="match">
       {(this.state.redirect) && (
                <MyGamesRedirect gameURL={this.state.redirect}/>
                )
                }
       {(this.props.match) && (
      <>
      <h4> Match {this.props.match.MATCH_NAME} Game 1 of {this.props.match.NUM_GAMES} </h4>
      {(this.props.games.length>0) && (
        <>
          {this.props.games.map((game, i) => {
            return (
              <>
              <Table className="myGame">
              <TableRow>
                <TableCell className="matchCell" align="center" colSpan={2}>
              
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell  className="matchCell" align="center" colSpan={2}>
                  Game {game.gameNum} :  {game.gameStatus }
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell className="matchCell" align="center" colSpan={2}>
                  Score
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell className="matchCell" align="center" colSpan={1}>
                
                {game.teams[0].teamName }: {game.team1Score }
                </TableCell>
                <TableCell className="matchCell" align="center" colSpan={1}>
                {game.teams[1].teamName }: {game.team2Score }
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell className="matchCell" colSpan={2}>

                
                
                {(game.gameStatus=="In Progress" ) && (
                    <button className="Scorebutton" onClick={(e) => this.handleScoreGameClick(i)}>
                    <SportsHandballIcon />
                    View                              
                    </button>
                )
                }
                 <button className="Scorebutton" onClick={this.props.handleClickBack}>
                 <SettingsBackupRestoreIcon/>
                    Back                              
                    </button>
                </TableCell>
              </TableRow>
              </Table>
              </>
              );
              }
              
              )
              }
            </>
              )
            } 
      </>
       )
        
      }
        




        </div>
      )
    }
  
 
  }
    export default ViewMyGames;