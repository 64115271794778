import { Aod } from "@mui/icons-material";
import React, { useState } from "react";

import AppBar from "./appBar";

import {
    Table,
  TableBody, TableCell, TableHead, 
} from "@material-ui/core";


import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';

import InputLabel from '@mui/material/InputLabel';

import FormControl from '@mui/material/FormControl';

import Input from '@mui/material/Input';

import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';


import SportsHandballIcon from '@material-ui/icons/SportsHandball';



import Userfront, { addInitCallback } from "@userfront/core";
import { TableRow } from "@mui/material";




class SignUp extends React.Component {
  
  constructor(props) {
    super(props);
    this.state = {
      
      player:
      {   
        method: "password",
        email: "",
        name: "",
        nickname: "",
        username: "",
        data: {
        custom: "information", mystuff:"denis"
      },
      password: "",
      } 

}



}



handleRActions = (actionType) => 
  {
    const clickedButtonName= actionType;
    switch (clickedButtonName) {
      case "Serve":
        //
        break;
    case "Back":
      this.setState({
        //
      })
    break;
 
     
    }        
  };

handleChange = (e) => {
  
  const { name, value } = e.target;
  const setupPlayer = this.state.player;
  setupPlayer[name] = value;
  //console.log("name: " + name + " value: " + value)
  this.setState({
    player:setupPlayer
  });
    
}; 

handleSignup(clickedButtonName)
  {
    //alert(clickedButtonName)
    Userfront.init("8nwrp6db");
    let signupPlayer = this.state.player
    
    this.insertPlayer(signupPlayer)
    };
             
  handleResponse(response)
  {

    //console.log(response);
  }  

  handleErrorResponse(response)
  {
    alert(response.message);
    //console.log(response.message);
  }  

insertPlayer (signupPlayer){

  var userFrontError;
 
  const payload = {
    email: signupPlayer.email,
    password: signupPlayer.password,
    username: signupPlayer.username,
    name: signupPlayer.name,
    data: {
      custom: "data"
    },
    tenantId: "8nwrp6db",
    options: {}
  };
  

  
     Userfront.signup(signupPlayer)
     .then(response => this.handleResponse(response))
      .catch (error => this.handleErrorResponse(error));
  

  //const response =  fetch("https://api.userfront.com/v0/auth/create", {
  //  method: "POST",
  //  headers: {
  //    "Content-Type": "application/json"
  //  },
  //  body: JSON.stringify(payload)
 // })
 // .then(response => this.handleResponse(response));
  
    
}


    render()
     {
    return (
      
      <div className="match">
        
       
    
     <Table className="singUP">
       <TableRow>
      <TableCell>
      <FormControl variant="standard">
        <InputLabel htmlFor="component-simple">Player Email</InputLabel>
        <Input id="email" name="email" value={this.state.player.email} onChange={this.handleChange} />
      </FormControl>
      </TableCell>
      </TableRow>
      <TableRow>
      <TableCell>
        
      
      <FormControl variant="standard">
        <InputLabel htmlFor="component-simple">Player Name</InputLabel>
        <Input id="name" name="name" value={this.state.player.name} onChange={this.handleChange} />
      </FormControl>
      </TableCell>
      </TableRow>
     
      <TableRow>
      <TableCell>
      
      <FormControl variant="standard">
        <InputLabel htmlFor="component-simple">User Name</InputLabel>
        <Input id="username" name="username" value={this.state.player.username} onChange={this.handleChange} />
      </FormControl>
      </TableCell>
      </TableRow>
      <TableRow>
        <TableCell>
      <FormControl variant="standard">
        
        <TextField
          id="filled-password-input" name="password"
          label="Password"
          type="password"
          autoComplete="current-password"
          
          onChange={this.handleChange}
        />
      
      </FormControl>
      </TableCell>
      </TableRow>
      
      </Table>
    

      <div align="center">
    <button className="Servebutton" onClick={(e) => this.handleSignup("SignUp")}>
        <SportsHandballIcon />
        Sign Up                               
    </button>

    </div>




        </div>
      )
    }
  
 
  }
    export default SignUp;