import React, { useState } from "react";
import Button from "../components/Button";
import ScoreGame from "../ScoreGame";

import { Link } from "react-router-dom";

import "../assets/css/style.css";

export default function ScoreGameRoute() {
 

 
  return (
    <div className="App">
   

    
      
 
      
     
    
    <ScoreGame />
    
    </div>
   
  );
}

