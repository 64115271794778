import React, {useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import CreateIcon from "@material-ui/icons/Create";
import Userfront from "@userfront/react";
import { deepOrange, green } from '@mui/material/colors';

import MyGamesRedirect from "./MyGamesRedirect";


import {
    Box, Button, Snackbar, Table,
    TableBody, TableCell, TableHead, TableRow,TextField
} from "@material-ui/core";

import Avatar from '@mui/material/Avatar';

import DeleteOutlineIcon from "@material-ui/icons/DeleteOutline";
import AddBoxIcon from "@material-ui/icons/AddBox";
import SendIcon from '@mui/icons-material/Send';
import DoneIcon from "@material-ui/icons/Done";
import PhotoCamera from '@material-ui/icons/PhotoCamera';
import ClearIcon from "@material-ui/icons/Clear";
import { makeStyles } from "@material-ui/core/styles";
import Alert from "@material-ui/lab/Alert";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import SportsHandballIcon from '@material-ui/icons/SportsHandball';
import AutorenewIcon from '@material-ui/icons/Autorenew';
import DeleteIcon from '@material-ui/icons/Delete';
import SportsTennisIcon from '@material-ui/icons/SportsTennis';
import EmojiPeopleIcon from '@material-ui/icons/EmojiPeople';
import FmdBadIcon from '@mui/icons-material/FmdBad';
import DoneAllIcon from '@material-ui/icons/DoneAll';
import PriorityHighIcon from '@material-ui/icons/PriorityHigh';
import TrendingDownIcon from '@material-ui/icons/TrendingDown';
import PinchIcon from '@mui/icons-material/Pinch';
import InsightsIcon from '@mui/icons-material/Insights';
import Checkbox from '@mui/material/Checkbox';
import LocalFireDepartmentIcon from '@mui/icons-material/LocalFireDepartment';  
import FormControlLabel from '@mui/material/FormControlLabel';
import OutboundIcon from '@mui/icons-material/Outbound';
import SettingsBackupRestoreIcon from '@mui/icons-material/SettingsBackupRestore';
import { appBarClasses, fabClasses } from "@mui/material";
import ToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';
import IconButton from '@mui/material/IconButton';


import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';

import MenuIcon from '@mui/icons-material/Menu';

import AppBar from "./appBar";
// Creating styles
const useStyles = makeStyles({
	root: {
		"& > *": {
			borderBottom: "unset",
		},
	},

	snackbar: {
		bottom: "104px",
	},
});


function ViewGame ({gameID,embedMe}) {
  const classes = useStyles();
  const showActions = true;
  const [isInitialized,setIsInitialized] = React.useState(false);
    const [rows, setRows] = useState([]);
    const [raction_types, setRaction_types] = useState([]);
    const [team1Score, setTeam1Score] = useState(0);
    const [team2Score, setTeam2Score] = useState(0);
    //const [interval, setInterval] = useEffect(null);
    
    const [isEdit, setIsEdit] = useState(false);
    const [disable, setDisable] = useState(false);
    const [showConfirm, setShowConfirm] = useState(false);
    const [showText, setShowText] = useState(true);
    const [isServing, setIsServing] = useState(false);
    const [firstServe, setFirstServe] = useState(true);
    const [scoringAdvanced, setScoringAdvanced] = useState(false);
    const [pointAdvanced, setPointAdvanced] = useState(false);
    const [sideoutAdcanced, setSideoutAdcanced] = useState(false);
    const [scoring1stClick, setScoring1stClick] = useState(true);
    const [serveNum, setServeNum] = useState(0);
    const [actionType, setActionType] = useState("IDLE");
    const [servingPlayer, setServingPlayer] = useState({ playerID: 0, index: 0,playerName: "T1p1"});
    const [defendingPlayer, setDefendingPlayer] = useState({ playerID: 0, index: 0,playerName: "T2p1"});
    const [Game, setGame] = useState({
      gameID: 1,
      matchUID: null,
      numGames:3,
      gameNum:1,
      gameType: null,
      gameStatus: "InProgress",
      team1Serving: true,
      team1Score: null,
      team2Score: null,
      pointsToWin: null,
      teams: 
        [
       {
           teamId: null,
           teamName: null
       }
       ,  
       {
         teamId: null,
         teamName: "null"
       }
       ],
     players: 
       [
       {
           playerID: null,
           index:0,
           playerName: "null"               
       }
       ,  
       {
         playerID: null,
         index:1,
         playerName: "null"                  
       }
       ,  
       {
         playerID: null,
         index:2,
         playerName: "null"                
       }
       ,  
       {
         playerID: null,
         index:3,
         playerName: "null"               
       }
       ]
     
  });
  
  const [open, setOpen] = React.useState(false);
  const [notFoundopen, setNotFoundopen] = React.useState(false);
  const [team1Serving, setTeam1Serving] = useState(true);        
  const [game_id, setGame_id] = useState(0);   
  const [rkey, setRkey] = useState(0);  
  const [redirect, setRedirect] = useState(null);
  const [gameComment, setGameComment] = useState(null);    
  const [commentFile, setCommentFile] = useState("");
  const game = useParams().game;
  var Filter = require('bad-words'),
  filter = new Filter();


  const handleImageChange = (e) => {
		
    
   
   
    setCommentFile( e.target.files[0]);//files[0]
     
   };

     // Function For closing the alert snackbar
	const handleClose = (event, reason) => {
		if (reason === "clickaway") {
			return;
		}
		setOpen(false);
		setNotFoundopen(false);
	};

  const appendLeadingZeroes= (n)  => {
		if (n <= 9) {
		  return "0" + n;
		}
		return n
	  }
    const clearCommentFile = () => {
     
      setCommentFile(null);
      setGameComment(null)
    };
  const handleInputCommentChange = (e) => {
		const { name, value } = e.target;
   
		setGameComment(value);
	};
  const handleGameComment = () => {
		
    const gameComments={};
		var current_datetime = new Date();
		var formatted_date = current_datetime.getFullYear() + "-" +
		  appendLeadingZeroes(current_datetime.getMonth() + 1) + "-" +
		  appendLeadingZeroes(current_datetime.getDate()) + "_" +
		  appendLeadingZeroes(current_datetime.getHours()) + ":" +
		  appendLeadingZeroes(current_datetime.getMinutes()) + ":" +
		  appendLeadingZeroes(current_datetime.getSeconds());
	
		  //console.log(formatted_date)
		  gameComments.DATE_CREATED=formatted_date;
      gameComments.GAME_ID=Game.gameID;
      gameComments.GAME_COMMENT=filter.clean(gameComment);
      
		  const formData = new FormData();
		  formData.append("file", commentFile);
		  formData.append("insertPLAYER", JSON.stringify(gameComments));
		const requestOptions = {
		  method: 'POST',
		  headers: { 
		  Authorization: `Bearer ${Userfront.tokens.accessToken}` },
		  body: formData
	  };
	  fetch("/api/rballGameComments",requestOptions)
		  
		  .then(res => {
			  setOpen(true);
        InitializeData();
        setCommentFile(null)
        setGameComment("")
        
		  })
     
	  } 

    
  const stringToColor=(string) =>{
		let hash = 0;
		let i;
	  
		/* eslint-disable no-bitwise */
		for (i = 0; i < string.length; i += 1) {
		  hash = string.charCodeAt(i) + ((hash << 5) - hash);
		}
	  
		let color = '#';
	  
		for (i = 0; i < 3; i += 1) {
		  const value = (hash >> (i * 8)) & 0xff;
		  color += `00${value.toString(16)}`.substr(-2);
		}
		/* eslint-enable no-bitwise */
	  
		return color;
	  };

	  const stringAvatar=(name) =>{
		{
		  
		  var avatarInitials
		 if (name)
		 {
			if (name.includes(' ')) 
			{
			avatarInitials=`${name.split(' ')[0][0]}${name.split(' ')[1][0]}`;
			}
			else
			{
	  
			  avatarInitials=name.charAt(0)
			}
	  
		 }
		 
		  else
		  {
		  name="unknown";
		  avatarInitials="?";
		  }
	  
		  return {
			sx: {
			  bgcolor: stringToColor(name),
			},
			children: avatarInitials,
		  };
		}
	  };


  //  Initialize Data once
  const InitializeData = () => {
   


   
   if (gameID) 
   {
   game=gameID;
  }


  if (showActions!=false)
    {    
            //fetch game action is in progress gameView
              fetch("/api/gameView?rbvg="+game,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization:`Bearer ${Userfront.tokens.accessToken}`
              
          },
        })
          
        
          // .then((res) => res.json())
              .then((res)  => {
                if (res.status != 404)
                {
                  return res.json()
                }
                else
                {
                
                  return res;
                }
                }
              )
                  .then((res)=> {
                    
                    
              
              
                setRows(res.raction);
                setRkey( res.raction[res.raction.length-1].raction_id+1);
                    
                    
                        
                    })
                .catch(error => {
                  //console.log("throw me an error " +  error)
                    }  )

          }  
            
         
      
         //fetch if a game is in progress
         
         //console.log("geting: " + "/api/getScoreBoard?rbg="+game)
         fetch("/api/getScoreBoard?rbg="+game,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization:`Bearer ${Userfront.tokens.accessToken}`
            
        },
      }
      
      )
      .then((res) => {
        //console.log("here I am ");
        return res.json();
      })
      .then((GameData)=> {
     //console.log("fetch returned");
     //console.log(GameData[0].team1Serving)
     if (GameData[0].team1Serving=='true')
     {
     
     
        setServingPlayer({ playerID: GameData[0].players[0].playerID, index: 0, playerName: GameData[0].players[0].playerName});
        setDefendingPlayer({ playerID: GameData[0].players[2].playerID, index: 2, playerName: GameData[0].players[2].playerName});
        setGame(GameData[0]);
        setTeam1Serving(GameData[0].team1Serving);
        
          
        }
     else
     {
      
        setServingPlayer({ playerID: GameData[0].players[2].playerID, index: 2, playerName: GameData[0].players[2].playerName});
        setDefendingPlayer({ playerID: GameData[0].players[0].playerID, index: 0, playerName: GameData[0].players[0].playerName});
        setGame(GameData[0]);
        setTeam1Serving(GameData[0].team1Serving);
        
           

     }

        })
      

//MAKE SURE TO SET AFTER GAME IS FETCHED


}



   
if ((!isInitialized))
	{
	InitializeData();
	setIsInitialized(true);	
	
	}
  const [seconds, setSeconds] = useState(0);
  useEffect(() => {
    const interval = setInterval(() => {
      setIsInitialized(false);
    }, 10000);
    return () => clearInterval(interval);
  }, []);






   
    return (
   <>   

{(redirect) && (
      <MyGamesRedirect gameURL={redirect}/>
  )
}


<Snackbar
		open={open}
		autoHideDuration={2000}
		onClose={() => handleClose()}
		className={classes.snackbar}
	>
		<Alert onClose={() => handleClose()} severity="success">
		Comment Posted!
		</Alert>
	</Snackbar>      
   <Table

      size="small"
      aria-label="a dense table"
      stickyHeader padding="none"
      className="app"
      >
        
        <TableHead stickyHeader>
          <TableRow >
            <TableCell colSpan="5"  align="right">

           
             <table  className="ScoreBoard">
                <tr>
                  <td align="center" colSpan={5}>


                  
                     
                      
                    
                    </td>
                    </tr>
                    <tr>
                    <td align="center">
                    
                    <table >
                    <tr>
                               
                    <td align="center">               
                                                 
                      <table className="team">
                        <tr >
                          <td align="center"> 
                          <table> 
                           
                            <td>
                            <Avatar {...stringAvatar(Game.teams[0].teamName)} src={"/static/public/" +  Game.teams[0].teamUid +".JPEG" } variant="square" sx={{ width: 24, height: 24 }}/>  
                           
                            </td>
                            <td>
                            {Game.teams[0].teamName}
                            </td>
                            </table> 
                          </td> 
                          </tr>
                          <tr> 
                          <td align="center">    
                      <table >
                        <tr>
                        <td className="tdScore">  

                          {Game.team1Score}
                          
                          </td> 
                          <td >  

                                <tr>
                                  
                                </tr> 
                                <tr>
                          
                                 
                                  </tr>
                            </td>
                            </tr>
                          </table>
                            </td> 
                            </tr>
                              <tr>
                              <td className="players" align="center">  
                                
                                    
                                   
                                      <>    
                                           

                                        
                                        {Game.gameType=="Singles" ? (
                                        <>
                                        <table>
                                          <td>
                                          {servingPlayer.index==0 && (
                                              <SportsHandballIcon  />
                                            )
                                            
                                            }
                                            </td>
                                            <td>
                                        <Avatar {...stringAvatar(Game.players[0].playerName)} src={"/static/public/" + Game.players[0].playerUID +".JPEG"}/>   
                                        </td>
                                        <td>
                                        {Game.players[0].playerName} 
                                        </td>
                                        </table>
                                        </>
                                        )
                                        :
                                        (
                                          <>
                                         
                                          {Game.players[0].playerName + " : " }
                                          
                                          {servingPlayer.index==1 && (
                                            <SportsHandballIcon  />
                                          )
                                          
                                          }
                                          
                                          {Game.players[1].playerName }
                                          </>
                                          )
                                        }
                                     </>
                                      
                                      
                                      
                                  
                                  </td>
                                  </tr>           
                                
                            </table>    
                                       

                    </td>
                    
                    <td align="center"> 

                             
                    </td>
                    <td align="center">               
                                                 
                      <table  className="team">
                        <tr >
                          <td align="center">  
                            <table>
                            
                            <td align="left">
                            <Avatar {...stringAvatar(Game.teams[1].teamName)} src={"/static/public/" +  Game.teams[1].teamUid +".JPEG"} variant="square" sx={{ width: 24, height: 24 }}/>            
                          </td> 
                          <td>
                            {Game.teams[1].teamName}
                            </td>
                          </table>
                          </td>
                          </tr>
                          <tr> 
                          <td align="center">    
                      <table >
                        <tr>
                        <td className="tdScore">  

                          {Game.team2Score}
                          
                          </td> 
                          <td>  

                                <tr>
                                 
                                </tr> 
                                <tr>
                          
                                 
                                  </tr>
                            </td>
                            </tr>
                          </table>
                            </td> 
                            </tr>
                              <tr>
                              <td className="players"  align="center">  
                                
                                    
                                   
                                      <>    
                                           

                                        
                                        {Game.gameType=="Singles" ? (
                                        <>
                                        <table>
                                          <td>
                                          {servingPlayer.index==2 && (
                                              <SportsHandballIcon  />
                                            )
                                            
                                            }
                                          </td>
                                          <td>
                                        <Avatar {...stringAvatar(Game.players[2].playerName)} src={"/static/public/" +  Game.players[2].playerUID +".JPEG"}/>   
                                        </td>
                                        {Game.players[2].playerName }
                                        <td>
                                        </td>
                                        </table>
                                        </>
                                        )
                                        :
                                        (
                                          <>
                                         
                                          {Game.players[2].playerName + " : " }
                                          
                                          {servingPlayer.index==3 && (
                                            <SportsHandballIcon  />
                                          )
                                          
                                          }
                                          
                                          {Game.players[3].playerName }
                                          </>
                                          )
                                        }
                                     </>
                                      
                                      
                                      
                                  
                                  </td>
                                  </tr>           
                                
                            </table>    
                                       

                    </td>
                       </tr>
                      </table>
                  </td>
                  </tr>
                
                </table>
                
                           


                           



                     
                    </TableCell>  
              </TableRow>
              <TableRow>
<td  colSpan={5} align="center">
    <table className="ScoreBoard">
              <TextField
          id="comment"
          value={gameComment}
          label="comment"
          type="search"
          size="small"
          variant="filled" onChange={(e) => handleInputCommentChange(e)}
          /> 
		<IconButton className="mr10" name="IMAGE_UPLOAD_BUTTON" aria-label="upload picture" component="label" onChange={(e) => handleImageChange(e)}>
					<input hidden name="IMAGE_UPLOAD" accept="image/*" type="file" />
					<PhotoCamera />
				</IconButton>
			
<Button align="right" onClick={(e) => handleGameComment()}>
                                 
                                  <SendIcon/>
 </Button>
 </table>
 </td>
 
              </TableRow>
              </TableHead>           
              <TableBody  >  
                         <TableRow >
                         
                                <TableCell  >
                                 
                                  </TableCell>
                                 
                                  {(showActions) && (
                                <>
                                <TableCell className="rActionScore" align="center">{Game.teams[0].teamName}
                                </TableCell>
                                <TableCell></TableCell>
                                <TableCell className="rActionScore" align="center">{Game.teams[1].teamName}
                                </TableCell>
                                </>
                                  )}
                                <TableCell>
                                  
                                </TableCell>
                                      
                            </TableRow>
                             
                            {rows.map((row, i) => {
                              return (

                                <TableRow>

                                  <TableCell className="rAction">
                                  {(row.display_type == 'action') ? (
                                       <>
                                       {((row.raction_type_id >= 2 && row.raction_type_id <=8) ||(row.raction_type_id ==101 )) ? (
                                        <>
                                        <Avatar {...stringAvatar(row.defending_p_name)} src={"/static/public/" +  row.defending_p_uid +".JPEG"} variant="square" sx={{ width: 24, height: 24 }}/>            
                                        </>
                                        
                                        )
                                      :
                                      (<Avatar {...stringAvatar(row.serving_p_name)} src={"/static/public/" +  row.serving_p_uid +".JPEG"} variant="square" sx={{ width: 24, height: 24 }}/>        
                                          )
                                          
                                      }
                                      {row.display_text}
                                      </>
                                      )
                                      :
                                      (
                                        <>
                                      
                                      
                                        <Avatar {...stringAvatar(row.createdby_name)} src={"/static/public/" +row.createdby_uid + ".JPEG"} variant="square" sx={{ width: 24, height: 24 }}/>  
                                        {row.createdby_name + " said: " + row.display_text} 
                                        {(row.is_image == 1) && (
                                         <>
                                         <img src={"/static/public/" +row.game_comment_uid + ".JPEG"} alt="Italian Trulli" width="500"/>
                                          
                                        </>
                                        )
                                        }
                                        </>
                                      )
                                  }
                              
                                  </TableCell>
                                  <TableCell align="center" className="rActionScore">
                                    {row.t1score}
                                  </TableCell>
                                  <TableCell></TableCell>
                                  <TableCell align="center" className="rActionScore">
                                    {row.t2score}
                                  </TableCell>
                                  <TableCell className="tdDelete">

                                 
                                  </TableCell>



                                 
                                </TableRow>

                              );
                              
                            }
                  )}
              </TableBody>
              </Table>
              </>         
      
      )
    
  
 
  }
    export default ViewGame;