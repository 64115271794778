
import React, { useState, useEffect } from "react";
import { useNavigate } from 'react-router-dom';

import AdbIcon from '@mui/icons-material/Adb';
import AppBar from '@mui/material/AppBar';
import {
  Box, Button, Snackbar, Table,
  TableBody, TableCell, TableHead, TableRow
} from "@material-ui/core";
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import Menu from '@mui/material/Menu';
import MenuIcon from '@mui/icons-material/Menu';
import Container from '@mui/material/Container';
import Avatar from '@mui/material/Avatar';

import Tooltip from '@mui/material/Tooltip';
import MenuItem from '@mui/material/MenuItem';
import FormLabel from '@mui/material/FormLabel';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import InputLabel from '@mui/material/InputLabel';

import Userfront from "@userfront/react";

import TextField from '@mui/material/TextField';
import Grid from '@mui/material/Unstable_Grid2';
import dayjs, { Dayjs } from "dayjs";
import  { DatePicker }  from '@mui/x-date-pickers/DatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
// or for Day.js
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';

const notLoggedInPages = [ {pageName: "Login", pageKey: "login"},
                {pageName: "Sign Up", pageKey: "signup"}
                
              ];
const pages = [ 
                {pageName: "Quick Match", pageKey: "setupmatch"},
                {pageName: "My Matches", pageKey: "mymatches"},
                {pageName: "My Teams", pageKey: "myteams"},
                {pageName: "My Players", pageKey: "myplayers"}
              ];
const settings = ['Profile', 'Account', 'Dashboard', 'Logout'];



function ScoreMatchesSettingsAppBar({mStateChanger,mSelectedClub,mSelectedTeam,msetSettingAppBarLoaded,mSelectedDate}) {
  const [anchorElNav, setAnchorElNav] = React.useState(null);
  const [anchorElUser, setAnchorElUser] = React.useState(null);
  const [rows, setRows] = useState([]);
  const [sRows, setSRows] = useState([]);
  const [clubRows, setClubRows]= useState([]);
  const [teamRows, setTeamRows]= useState([]);
  const navigate = useNavigate();
  const [isInitialized,setIsInitialized] = React.useState(false);
  const [defaultClub, setDefaultClub] = useState("");
  const [selectedClub, setSelectedClub] = useState("");
  const [selectedTeam, setSelectedTeam] = useState(null);

  const [page, setPage] = React.useState(0);
  const [mUser, setMUser] = React.useState(0);
  const [isClubDataInitialized,setIsClubDataInitialized] = React.useState(false);
  const [isTeamDataInitialized,setIsTeamDataInitialized] = React.useState(false);
  const [myDateValue, setMyDateValue] = React.useState(    dayjs(new Date())  );
 
  const handleDateChange = (newValue: Dayjs | null) => {
    setMyDateValue(newValue);
    
    mSelectedDate(dayjs(newValue).format('YYYY-MM-DD'));
    mStateChanger(true);
    
  };

  const handleChangePage = (event, newPage) => {
		setPage(newPage);
	  };


	  const handleChange = (e) => {
		
		const { name, value } = e.target;
		
		setDefaultClub(value);
     
		//console.log("Default Club: " + value)	
		//Reset TEAM Drop Down
    InitializeData();
		  
	  }; 

	  const handleChangeClub = (e) => {
		
		const { name, value } = e.target;
		setSelectedClub(value);
    setIsTeamDataInitialized(false);
    mSelectedClub(value);
    
		mStateChanger(true);
    var current_datetime = new Date();
	  	var formatted_date = current_datetime.getFullYear() + "-" +
		  appendLeadingZeroes(current_datetime.getMonth() + 1) + "-" +
		  appendLeadingZeroes(current_datetime.getDate()) + "_" +
		  appendLeadingZeroes(current_datetime.getHours()) + ":" +
		  appendLeadingZeroes(current_datetime.getMinutes()) + ":" +
		  appendLeadingZeroes(current_datetime.getSeconds());
      setSelectedTeam(null);
      setMUser( 
        {
          DATE_UPDATED: formatted_date,DEFAULT_CLUB_ID:value,DEFAULT_TEAM_ID:null
        }
      );
      updateTeamUSER({
        DATE_UPDATED: formatted_date,DEFAULT_CLUB_ID:value,DEFAULT_TEAM_ID:null
      })
      //console.log(selectedTeam)

    InitializeTeamsData(value);
   
	  }; 
 
    const appendLeadingZeroes= (n)  => {
      if (n <= 9) {
        return "0" + n;
      }
      return n
      }

    const handleChangeTeam = (e) => {
		
      const { name, value } = e.target;
      setSelectedTeam(value);
      mSelectedTeam(value);
		  mStateChanger(true);
      var current_datetime = new Date();
	  	var formatted_date = current_datetime.getFullYear() + "-" +
		  appendLeadingZeroes(current_datetime.getMonth() + 1) + "-" +
		  appendLeadingZeroes(current_datetime.getDate()) + "_" +
		  appendLeadingZeroes(current_datetime.getHours()) + ":" +
		  appendLeadingZeroes(current_datetime.getMinutes()) + ":" +
		  appendLeadingZeroes(current_datetime.getSeconds());
      setMUser( 
        {
          DATE_UPDATED: formatted_date,DEFAULT_CLUB_ID:selectedClub,DEFAULT_TEAM_ID:value
        }
      );
      updateTeamUSER({
        DATE_UPDATED: formatted_date,DEFAULT_CLUB_ID:selectedClub,DEFAULT_TEAM_ID:value
      })
      //console.log(selectedTeam)
      }; 
  
      const updateTeamUSER = (USER) => {


        const updateTeamUSER=USER;
        
    
        const requestOptions = {
          method: 'PUT',
          headers: { 'Content-Type': 'application/json', 
          Authorization: `Bearer ${Userfront.tokens.accessToken}` },
          body: JSON.stringify(updateTeamUSER)
        };
        fetch('api/myTeamUsers', requestOptions)
          .then(response => response.json())
          .then(data => {
            InitializeData();
          })
        }  
  
   const InitializeTeamsData = (clubID) => {
   
		
          const requestOptions = {
            method: 'GET',
            headers: { 'Content-Type': 'application/json', 
            Authorization: `Bearer ${Userfront.tokens.accessToken}` }
            };
           
           //fetch all teams
           fetch("/api/myTeams?rbc="+clubID,requestOptions)
           .then((res) => {
            if (res.status != 404)
            {
              return res.json()
            }
            else
            {
              setTeamRows([]);
              return res;
            }
            }
          )
               .then((res)=> {
                if (res.status != 404)
                {
                  setTeamRows(res);
                
                  setIsTeamDataInitialized(true);
                 
                }
                
                    
                })
            .catch(error => {
              //console.log("throw me an error " +  error)
                }  )
          
            } ///end initialize teams data

const InitializeData = () => {
   
		
  //setIsTeamDataInitialized(false);
  //setIsClubDataInitialized(false);  
  const requestOptions = {
			method: 'GET',
			headers: { 'Content-Type': 'application/json', 
			Authorization: `Bearer ${Userfront.tokens.accessToken}` }
		  };
	  
		//fetch default club id
    fetch("/api/myUSERs?rbo="+loggedInUser.userUuid,requestOptions)
		.then((res) => res.json())
		.then((USERsData)=> {
		 
		 setSRows(USERsData);
		
     setSelectedClub(USERsData[0].DEFAULT_CLUB_ID);
     setSelectedTeam(USERsData[0].DEFAULT_TEAM_ID);
     mSelectedClub(USERsData[0].DEFAULT_CLUB_ID);
     mSelectedTeam(USERsData[0].DEFAULT_TEAM_ID);
		 mStateChanger(true);

      //fetch all clubs
      fetch("/api/myClubs",requestOptions)
      .then((res) => res.json())
      .then((clubData)=> {
     
     setClubRows(clubData);
     setIsClubDataInitialized(true);
       
        //fetch all teams
        fetch("/api/myTeams?rbc="+USERsData[0].DEFAULT_CLUB_ID,requestOptions)
        
        //.then((res)=> res.json())
        .then((res) => {
          if (res.status != 404)
          {
            return res.json()
          }
          else
          {
            return res;
          }
          }
        )
        .then((res)=> {
          if (res.status != 404)
          {
            setTeamRows(res);
            if (res.length > 0)
            {
            setIsTeamDataInitialized(true);
            }
            else
            {
              setIsTeamDataInitialized(true);
              }
          }
          
              
          })
      .catch(error => {
       //console.log("throw me an error on team rows")
         }  )

     })
       .catch(error => {
         //console.log("throw me an error")
           }  )
    })
	   .catch(error => {
		   //console.log("throw me an error")
	   		}  
		   );
	    
    
    
    
      } ///end initialize data
	 
 
		 
  
  const handleOpenNavMenu = (event) => {
    setAnchorElNav(event.currentTarget);
    const name = event.target.innerText;
    //console.log("name: " + name )
    switch (name) {
      case "Logout":
        Userfront.logout();
        break;
    }
  };
  
  const handleOpenUserMenu = (event) => {
    setAnchorElUser(event.currentTarget);
    const name = event.target.innerText;
    //console.log("name: " + name )
    switch (name) {
      case "Logout":
        Userfront.logout();
        break;
    }
  };
    
  

  const handleCloseNavMenu = (event) => {
    setAnchorElNav(null);
    const name = event.target.innerText;
    //console.log("name: " + name )
    switch (name) {
      case "Logout":
        Userfront.logout();
        break;
    }
  };
  

  const handleCloseUserMenu = (event) => {
    setAnchorElUser(null);
    const name = event.target.innerText;
    //console.log("name: " + name )
    switch (name) {
      case "Logout":
        Userfront.logout();
        break;
      default:
        navigate("../"+name);

    }
  };
  const loggedInUser = Userfront.user;
 // alert (loggedInUser.name)
  
  function stringToColor(string) {
    let hash = 0;
    let i;
  
    /* eslint-disable no-bitwise */
    for (i = 0; i < string.length; i += 1) {
      hash = string.charCodeAt(i) + ((hash << 5) - hash);
    }
  
    let color = '#';
  
    for (i = 0; i < 3; i += 1) {
      const value = (hash >> (i * 8)) & 0xff;
      color += `00${value.toString(16)}`.substr(-2);
    }
    /* eslint-enable no-bitwise */
  
    return color;
  }
  
  
  if (isClubDataInitialized==true && isTeamDataInitialized==true && selectedClub!=null && selectedTeam !=null)
  {
    msetSettingAppBarLoaded(true);

  }
  else
  {
    msetSettingAppBarLoaded(false);
  }
  if (!isInitialized)
	{
	InitializeData();
	setIsInitialized(true);
	}
  return (    <AppBar  class="myAppBar" position="static">
       <LocalizationProvider dateAdapter={AdapterDayjs}>
      <Container maxWidth="xl" disableGutters="true">
        <Toolbar>
          
        <Box sx={{ flexGrow: 1 }}>
     <Grid container spacing={1}>
                
             
                
                  <FormControl sx={{ m:1,minWidth: 85 }}>
                <DatePicker 
                className="datePicker"
          label="Match Date"
          inputFormat="MM/DD/YYYY"
          value={myDateValue}
          onChange={handleDateChange}
          renderInput={(params) => <TextField {...params} />}
        />


                </FormControl>       
              <FormControl sx={{ m:1,minWidth: 125 }}>
              <InputLabel size="small" id="DEFAULT_CLUB_UID">Club</InputLabel>
                <Select
                  size="small"
                  labelId="DEFAULT_CLUB_UID"
                  id="DEFAULT_CLUB_UID"
                  value={selectedClub}
                  label="DEFAULT_CLUB_UID"
                  name="DEFAULT_CLUB_UID"
                  onChange={(e) => handleChangeClub(e)}
                  
                >
                  {clubRows.map((club, i) => {
                                      return (
                  <MenuItem value={club.CLUB_ID}>{club.CLUB_NAME}</MenuItem>
                                        
                                      );
                  }
                  )
                  }
                
                </Select>
                </FormControl>	
               
                
                  <FormControl sx={{ m:1,minWidth: 125 }}>
              <InputLabel size="small" id="DEFAULT_TEAM_UID">Team</InputLabel>
                <Select
                  size="small"
                  labelId="DEFAULT_TEAM_UID"
                  id="DEFAULT_TEAM_UID"
                  value={((selectedTeam == null)? "" : selectedTeam)}
                  label="DEFAULT_TEAM_UID"
                  name="DEFAULT_TEAM_UID"
                    onChange={(e) => handleChangeTeam(e)}
                  
                >
                 
                    {teamRows.map((team, i) => {
                                      return (
                  <MenuItem value={team.TEAM_ID}>{team.TEAM_NAME}</MenuItem>
                                        
                                      );
                  }
                
                    )
                  }
                  
                
                </Select>
                </FormControl>
            
		<TableBody/>
   
    </Grid >
      </Box>
        </Toolbar>
      </Container>
      </LocalizationProvider>
    </AppBar>
  );
};
export default ScoreMatchesSettingsAppBar;