import Userfront from "@userfront/react";
import React, { useState } from "react";
import ViewGames from "./ViewGame";

import SettingsAppBar from "./SettingsAppBar";
import AppBar from "./appBar";

function MyGameDashboard (){
  
  const [selectedClub, setSelectedClub] = useState(null);
  const [selectedTeam, setSelectedTeam] = useState(null);
  const [stateChanger, setStateChanger] = useState(false);
     
    return (
    <> 
      <div className="match">
        
       </div>
   <SettingsAppBar mStateChanger={setStateChanger} mSelectedClub={setSelectedClub} mSelectedTeam={setSelectedTeam}/>
    <div>
      <ViewGames gameID={"abec2b8d-124f-4988-b59e-92c3681f9171"} showActions={false} embedMe={true}></ViewGames>
      
      <ViewGames gameID={"abec2b8d-124f-4988-b59e-92c3681f9171"} showActions={false} embedMe={true}></ViewGames>
      </div>
      </>
      )
}
    export default MyGameDashboard;