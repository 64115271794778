import React, { useState } from "react";

import Home from "../Home";
import "../assets/css/style.css";

export default function HomeRoute() {
 
  

 
  return (
    <div className="App">
    

  <Home></Home>
     
    </div>
      
     
    
  
  
   
  );
}

