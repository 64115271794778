import React, { useState } from "react";
import CreateIcon from "@material-ui/icons/Create";
import {
    Box, Button, Snackbar, Table,
    TableBody, TableCell, TableHead, TableRow
} from "@material-ui/core";
import DeleteOutlineIcon from "@material-ui/icons/DeleteOutline";
import AddBoxIcon from "@material-ui/icons/AddBox";
import DoneIcon from "@material-ui/icons/Done";
import ClearIcon from "@material-ui/icons/Clear";
import { makeStyles } from "@material-ui/core/styles";
import Alert from "@material-ui/lab/Alert";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import SportsHandballIcon from '@material-ui/icons/SportsHandball';
import AutorenewIcon from '@material-ui/icons/Autorenew';
import DeleteIcon from '@material-ui/icons/Delete';
import SportsTennisIcon from '@material-ui/icons/SportsTennis';
import EmojiPeopleIcon from '@material-ui/icons/EmojiPeople';
import EditIcon from '@material-ui/icons/Edit';
import AddIcon from '@material-ui/icons/Add';
import SaveIcon from '@material-ui/icons//Save';

class tablePlayers extends React.Component {
  constructor(props) {
      super(props);
      this.state = {
          rows: [],
		  sRows: [],
          team1Score: 0,
          team2Score: 0,
          rePhxItems: [],
          bomItems: [],
          date: new Date(),
          timeElasped: new Date(),
          open: false,
          isEdit: false,
          disable: false,
          showConfirm: false,
          isServing: false,
          team1Serving: true,
          rkey: 0
                  };


  }
  
  
  insertRAction (raction){

    const requestOptions = {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(raction)
  };
  fetch('api/rballActions', requestOptions)
      .then(response => response.json())
      .then(data => this.setState({ postId: data.id }));
  }
  
  deleteRAction (raction_id,game_id){
    const reqBody={};
    reqBody.raction_id=raction_id;
    reqBody.game_id=game_id;
    const requestOptions = {
      method: 'DELETE',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(reqBody)
  };
  fetch('api/rballActions', requestOptions)
      .then(response => response.json())
      .then(data => this.setState({ postId: data.id }));
  }


  
     //  For adding new row object
      handleAdd = () => {
      if (!this.state.open)
	  {
		const list=this.state.rows;
		list.push({
			player_id: this.state.rows.length + 1, PLAYER_SHORT_NAME: "",
		   PLAYER_FIRST_NAME: "", PLAYER_LAST_NAME: ""
		});
		  //  list.sort(function(a, b) {
		  //    return b.rkey - a.rkey;})  
		  //    console.log(list);
		 
		  
		   
		
		  this.setState({
			isEdit: true,
			open: true,
		  rows:list
			});

		}
		else
		{
			const list=[];
		list.push({
			player_id: this.state.sRows.length + 1, PLAYER_SHORT_NAME: "",
		   PLAYER_FIRST_NAME: "", PLAYER_LAST_NAME: ""
		});
			this.setState({
				isEdit: true,
				rows:list
				});	
		}
			};
     
   

	
     //  to handle edit
      handleEdit = (i) => {
         // If edit mode is true setEdit will 
         // set it to false and vice versa
         this.setState({   
          
          isEdit: false
            });
         
     };
	 handleSave= () => 
	 {
		const list=this.state.sRows;

		list.push(this.state.rows[0]
			);
		this.setState({   
			isEdit: false,
			sRows: list
			  });
		   
		   };
 
   
     // The handleInputChange handler can be set up to handle
     // many different inputs in the form, listen for changes 
     // to input elements and record their values in state
      handleInputChange = (e) => {
       //  setDisable(false);
         const { name, value } = e.target;
         const list = [...this.state.rows];
         list[0][name] = value;
		 this.setState({
			rows:list
			  });
     };
   
     // Showing delete confirmation to users
      handleConfirm = () => {
      this.setState({   
        showConfirm: true,
        
          });
     };
   
     // Handle the case of delete confirmation where 
     // user click yes delete a specific row of id:i
      handleRemoveClick = (i) => {
         const list = this.state.rows;
         const newTeam1Score=this.state.team1Score - this.state.rows[i].Team1Points
         const newTeam2Score=this.state.team2Score - this.state.rows[i].Team2Points
         for (const [index, value] of list.entries()) {
          if (index >i) {
            list[index].Team1Score=list[index].Team1Score-this.state.rows[i].Team1Points
            list[index].Team2Score=list[index].Team2Score-this.state.rows[i].Team2Points
          }
          
        }
      //add updates
      
      this.deleteRAction(JSON.parse(this.state.rows[i].rkey),JSON.parse(1)); 
        
        list.splice(i, 1);
         
       
         //setRows(list);
         this.setState({ 
          team1Score: newTeam1Score, 
          team2Score: newTeam2Score,  
          rows:list
            });
        
         //setShowConfirm(false);
     };
   
     // Handle the case of delete confirmation 
     // where user click no 
      handleNo = () => {
       //  setShowConfirm(false);
     };

    render()
     {
    return (
        <table>
        <div className="is-sticky1 " align="center">
        <tr>  
		<TableCell component="th" scope="row" align="center">  
          <div className="app">         
              <h3>Players </h3>
              <Button onClick={this.handleAdd} >
                      <AddIcon/>
                    </Button>
                    
         </div>
		 </TableCell>
         </tr>
         
       
            </div>
            <tr>
            
      
    
      <TableBody>
      <Box margin={1}>
      
            <Table
              
              size="small"
              aria-label="a dense table"
            >
             
              
			  {this.state.isEdit ? (
                    <div>

			<TableHead>
                <TableRow>
                  <TableCell>Nick Name</TableCell>
                  <TableCell>Fist Name</TableCell>
                  <TableCell>Last Name</TableCell>
                  <TableCell align="center"> </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
				  <TableRow>
                    <TableCell padding="none">
						<input
							value={this.state.rows[0].PLAYER_SHORT_NAME}
							name="PLAYER_SHORT_NAME"
							onChange={(e) => this.handleInputChange(e)}
						/>
						</TableCell>
						<TableCell padding="none">
						<input
							value={this.state.rows[0].PLAYER_FIRST_NAME}
							name="PLAYER_FIRST_NAME"
							onChange={(e) => this.handleInputChange(e)}
						/>
						</TableCell>
						<TableCell padding="none">
						<select
							style={{ width: "100px" }}
							name="PLAYER_LAST_NAME"
							value={this.state.rows[0].PLAYER_LAST_NAME}
							onChange={(e) => this.handleInputChange(e)}
						>
							<option value=""></option>
							<option value="Karanja">Karanja</option>
							<option value="Hingoli">Hingoli</option>
							<option value="Bhandara">Bhandara</option>
							<option value="Amaravati">Amaravati</option>
							<option value="Pulgaon">Pulgaon</option>
						</select>
						</TableCell>
						<TableCell padding="none">	<Button
                                  onClick={() => this.handleEdit()}
                                  color="primary"
                                  autoFocus
                                >
                                 <ClearIcon/>
                                </Button> 
								<Button
                                  onClick={() => this.handleSave()}
                                  color="primary"
                                  autoFocus
                                >
                                 <SaveIcon/>
                                </Button> 
								
						</TableCell>
						</TableRow>
					</TableBody>
                    </div>
					
             ) :
             (
<div>
<TableHead>
                <TableRow>
                  <TableCell>Nick Name</TableCell>
                  <TableCell>Fist Name</TableCell>
                  <TableCell>Last Name</TableCell>
                  <TableCell align="center"> </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {this.state.sRows.map((row, i) => {
                  return (
                    
                      <TableRow>   
                            <TableCell component="th" scope="row">
                              {row.PLAYER_SHORT_NAME}
                            </TableCell>
                            <TableCell component="th" scope="row">
                              {row.PLAYER_FIRST_NAME}
                            </TableCell>
                            <TableCell component="th" scope="row" align="center">
                              {row.PLAYER_LAST_NAME}
                            </TableCell>
                            <TableCell
                              component="th"
                              scope="row"
                              align="center"
                            >
                                <Button
                                  onClick={() => this.handleEditClick(i)}
                                  color="primary"
                                  autoFocus
                                >
                                 <EditIcon/>
                                </Button> 
                                <Button
                                  onClick={() => this.handleRemoveClick(i)}
                                  color="primary"
                                  autoFocus
                                >
                                 <DeleteIcon/>
                                </Button>
                            </TableCell>
                         
                        
                     
                        
                      </TableRow>
                    
                  );
                })}
</TableBody>
</div>
			 )
            }

	
              
            </Table>
          </Box>
        </TableBody>
        </tr>
    </table>
      )
    }
  
 
  }
    export default tablePlayers;