import React, { useState, useEffect } from "react";
import CreateIcon from "@material-ui/icons/Create";

import AppBar from "./appBar";

import Paper from '@mui/material/Paper';
import TableContainer from '@mui/material/TableContainer';
import TablePagination from '@mui/material/TablePagination';

import {
    Box, Button, Snackbar, Table,
    TableBody, TableCell, TableHead, TableRow
} from "@material-ui/core";
import DeleteOutlineIcon from "@material-ui/icons/DeleteOutline";
import AddBoxIcon from "@material-ui/icons/AddBox";
import DoneIcon from "@material-ui/icons/Done";
import ClearIcon from "@material-ui/icons/Clear";
import { makeStyles } from "@material-ui/core/styles";
import Alert from "@material-ui/lab/Alert";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import SportsHandballIcon from '@material-ui/icons/SportsHandball';
import AutorenewIcon from '@material-ui/icons/Autorenew';
import DeleteIcon from '@material-ui/icons/Delete';
import SportsTennisIcon from '@material-ui/icons/SportsTennis';
import EmojiPeopleIcon from '@material-ui/icons/EmojiPeople';
import EditIcon from '@material-ui/icons/Edit';
import AddIcon from '@material-ui/icons/Add';
import SaveIcon from '@material-ui/icons//Save';
import Select from '@mui/material/Select';
import PhotoCamera from '@material-ui/icons/PhotoCamera';
import IconButton from '@material-ui/core/IconButton';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Userfront from "@userfront/react";
import def from "ajv/dist/vocabularies/discriminator";
import { VpnLock } from "@mui/icons-material";

// Creating styles
const useStyles = makeStyles({
	root: {
		"& > *": {
			borderBottom: "unset",
		},
	},

	snackbar: {
		bottom: "104px",
	},
});

function MyUsers() {
	// Creating style object
	const classes = useStyles();

	// Defining a state named rows
	// which we can update by calling on setRows function
	const [rows, setRows] = useState([]);
  const [sRows, setSRows] = useState([]);
  const [defaultClub, setDefaultClub] = useState("");
  const [clubRows, setClubRows]= useState([]);

 
  //const [rows, setRows] = useState([
//		{ id: 1, firstname: "", lastname: "", city: "" },
//	]);

	// Initial states
	const [open, setOpen] = React.useState(false);
	const [isEdit, setEdit] = React.useState(false);
  const [editRow, setEditRow] = React.useState(-1);
  const [isAdd, setIsAdd] = React.useState(false);
	const [disable, setDisable] = React.useState(true);
	const [showConfirm, setShowConfirm] = React.useState(false);
	const [page, setPage] = React.useState(0);
	const [loggedInUser,setLoggedInUser] = React.useState(Userfront.user);
	const [isInitialized,setIsInitialized] = React.useState(false);
	

	//useEffect(() => {
	//	InitializeData()
	//	})
	
	// Showing delete confirmation to users
	const handleInitialized = () => {
		setIsInitialized(!isInitialized);
	};
	const InitializeData = () => {
   
		const requestOptions = {
			method: 'GET',
			headers: { 'Content-Type': 'application/json', 
			Authorization: `Bearer ${Userfront.tokens.accessToken}` }
		  };	
	  
		//fetch game action is in progress
		fetch("/api/myUSERs?rbo="+loggedInUser.userUuid,requestOptions)
		.then((res) => res.json())
		.then((USERsData)=> {
		 
		 setSRows(USERsData);
		 setDefaultClub(USERsData[0].DEFAULT_CLUB_ID);
		   
	   })

	   fetch("/api/myClubs",requestOptions)
	   .then((res) => res.json())
	   .then((clubData)=> {
		
		setClubRows(clubData);
		  
	  })

	  }
	  
	  const appendLeadingZeroes= (n)  => {
		if (n <= 9) {
		  return "0" + n;
		}
		return n
	  }

	  const handleImageChange = (e, index) => {
		
		const name=  e.target.name;//files[0]
		const value=  e.target.files[0];//files[0]
			const list = [...sRows];
		list[index][name] = value;
		setSRows(list);
	};

	  const insertUSER = (USER) => {


		const insertUSER=USER;
		var current_datetime = new Date();
		var formatted_date = current_datetime.getFullYear() + "-" +
		  appendLeadingZeroes(current_datetime.getMonth() + 1) + "-" +
		  appendLeadingZeroes(current_datetime.getDate()) + "_" +
		  appendLeadingZeroes(current_datetime.getHours()) + ":" +
		  appendLeadingZeroes(current_datetime.getMinutes()) + ":" +
		  appendLeadingZeroes(current_datetime.getSeconds());
	
		  //console.log(formatted_date)
		  insertUSER.DATE_CREATED=formatted_date

		const requestOptions = {
		  method: 'POST',
		  headers: { 'Content-Type': 'application/json', 
		  Authorization: `Bearer ${Userfront.tokens.accessToken}` },
		  body: JSON.stringify(insertUSER)
	  };
	  fetch('api/myUSERs', requestOptions)
	 
		  .then(response => response.json())
		  .then(data => {
			  //console.log(data.USER_ID)
			  InitializeData();
		  })
	  }  

const deleteUSER = (USER) => {


		const deleteUSER=USER;

		const requestOptions = {
		  method: 'DELETE',
		  headers: { 'Content-Type': 'application/json', 
		  Authorization: `Bearer ${Userfront.tokens.accessToken}` },
		  body: JSON.stringify(deleteUSER)
	  };
	  fetch('api/myUSERs', requestOptions)
		  .then(response => response.json())
		  .then(data => {
			  InitializeData();
		  })
	  }  
const updateUSER = (USER) => {


		const updateUSER=USER;
		var current_datetime = new Date();
		var formatted_date = current_datetime.getFullYear() + "-" +
		  appendLeadingZeroes(current_datetime.getMonth() + 1) + "-" +
		  appendLeadingZeroes(current_datetime.getDate()) + "_" +
		  appendLeadingZeroes(current_datetime.getHours()) + ":" +
		  appendLeadingZeroes(current_datetime.getMinutes()) + ":" +
		  appendLeadingZeroes(current_datetime.getSeconds());
	
		  //console.log(formatted_date)
		  updateUSER.DATE_UPDATED=formatted_date
		  const formData = new FormData();
		  formData.append("file", updateUSER.IMAGE_UPLOAD);
		  formData.append("updateUSER", JSON.stringify(updateUSER));
		const requestOptions = {
		  method: 'PUT',
		  headers: { 
			Authorization: `Bearer ${Userfront.tokens.accessToken}` },
			body: formData
	  };
	  fetch('api/myUSERs', requestOptions)
		  .then(response => response.json())
		  .then(data => {
			  InitializeData();
		  })
	  }  


	const handleChangePage = (event, newPage) => {
		setPage(newPage);
	  };


	 const handleChange = (e) => {
		
		const { name, value } = e.target;
		const tempClubRows=sRows;
		setDefaultClub(value);
		tempClubRows[0].DEFAULT_CLUB_ID=value;
		setSRows(tempClubRows);
		//console.log("Default Club: " + value)	
		
		  
	  }; 

	  const handleChangeRowsPerPage = (event) => {
		setRowsPerPage(parseInt(event.target.value, 10));
		setPage(0);
	  };

	  const [rowsPerPage, setRowsPerPage] = React.useState(5);
  // Function For closing the alert snackbar
	const handleClose = (event, reason) => {
		if (reason === "clickaway") {
			return;
		}
		setOpen(false);
	};

	// Function For adding new row object
	const handleAdd = () => {
		setIsAdd(true);
    if (rows)
    {
    setRows([
			...rows,
			{
				id: rows.length + 1,USER_NAME:"",USER_FIRST_NAME: "",USER_LAST_NAME: null,USER_EMAIL:null, USER_UID: null,DATE_CREATED: "",CREATEDBY_UID: null,DEFAULT_CLUB_ID:loggedInUser.userUuid, IMAGE_UPLOAD: null
			},
		]);
   }
    else
    {
      setRows([
        rows,
        {
			id: rows.length + 1,USER_NAME:"",USER_FIRST_NAME: "",USER_LAST_NAME:null, USER_EMAIL: null, USER_UID: null,DATE_CREATED: "",CREATEDBY_UID: null,DEFAULT_CLUB_ID:loggedInUser.userUuid, IMAGE_UPLOAD: null
        },
      ]);
    }
		setEdit(true);
    
	};
	// Function to handle cancel of edit
	const handleCancel = () => {
		// If edit mode is true setEdit will
		// set it to false and vice versa
		setEdit(!isEdit);
    setEditRow(-1)

	};

	// Function to handle edit
	const handleEdit = (i) => {
		// If edit mode is true setEdit will
		// set it to false and vice versa
		setEdit(!isEdit);
    setEditRow(i)

	};
  

	// Function to handle save
	const handleSave = () => {
		setEdit(false);
    setEditRow(-1);
    setIsAdd(false);
	 
    
    
    //console.log("Cleared : ", rows);
	insertUSER(rows[0]);
	setRows([]);
	};

  // Function to handle saveUSERs
	const handleSaveUSERs = (i) => {
		setEdit(false);
    setEditRow(-1);
    setIsAdd(false); 

	//console.log("saved : ", sRows[i]);
	setDisable(true);
	setOpen(true);
    
    setRows([]);
    //console.log("Cleared : ", rows);
	updateUSER(sRows[i]);
	InitializeData();
	};

	// The handleInputChange handler can be set up to handle
	// many different inputs in the form, listen for changes
	// to input elements and record their values in state
	const handleInputChange = (e, index) => {
		setDisable(false);
		const { name, value } = e.target;
		const list = [...rows];
		list[index][name] = value;
		setRows(list);
	};

  	// The handleInputChange handler can be set up to handle
	// many different inputs in the form, listen for changes
	// to input elements and record their values in state
	const handleInputChangeUSERs = (e, index) => {
		setDisable(false);
		const { name, value } = e.target;
		const list = [...sRows];
		list[index][name] = value;
		setSRows(list);
	};


	// Showing delete confirmation to users
	const handleConfirm = () => {
		setShowConfirm(true);
	};

	// Handle the case of delete confirmation where
	// user click yes delete a specific row of id:i
	const handleRemoveClick = (i) => {
		const list = [...rows];
		list.splice(i, 1);
		setRows(list);
		setShowConfirm(false);
    setIsAdd(false);
	};


  const handleRemoveUSERsClick = (i) => {
		const list = [...sRows];
		deleteUSER(sRows[i])
		list.splice(i, 1);
		setSRows(list);
		setShowConfirm(false);
    setIsAdd(false);

	InitializeData();
	};

	// Handle the case of delete confirmation
	// where user click no
	const handleNo = () => {
		setShowConfirm(false);
	};
	const emptyRows =
    page > 0 ? Math.max(0, (1 + page) * rowsPerPage - rows.length) : 0;

	if (!isInitialized)
	{
	InitializeData();
	setIsInitialized(true);
	}
return (
  
 <div >
  
  <div>
     

        </div>
  <Table className="myMatch">
	<TableBody >
	<Snackbar
		open={open}
		autoHideDuration={2000}
		onClose={() => handleClose()}
		className={classes.snackbar}
	>
		<Alert onClose={() => handleClose()} severity="success">
		Record saved successfully!
		</Alert>
	</Snackbar>
  <TableRow align="center">
    <TableCell  className="USERCell">
    My Profile
    </TableCell>
    <TableCell className="addButton" align="left">
	
      
      
      
			
		
		</TableCell>
    </TableRow>
    
    
		<TableRow align="center"></TableRow>
   
  <TableRow>
    <TableCell className="matchCell" colSpan={2}>
   

    <Box sx={{ width: '100%' }}>
      <Paper sx={{ width: '100%', mb: 2 }}>
        
        <TableContainer>
    <Table
		 className="myMatch"
		size="small"
		aria-label="a dense table"
		>
		<TableHead>
			<TableRow>
			<TableCell>User Name</TableCell>
			<TableCell>User First Name</TableCell>
			<TableCell>User Last Name</TableCell>
			<TableCell>User Email</TableCell>
			<TableCell>Default Club</TableCell>
			<TableCell align="center"></TableCell>
			</TableRow>
		</TableHead>
		<TableBody>
      {(sRows) && (
        <> {}
			{sRows.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row, i) => {
			return (
				<>
				<TableRow>
					{isEdit   && i==editRow ? (
					<>
						<TableCell padding="none">
						{row.USER_NAME}
							
						
						</TableCell>
						<TableCell padding="none">
						<input
							value={row.USER_FIRST_NAME}
							name="USER_FIRST_NAME"
							 onChange={(e) => handleInputChangeUSERs(e, i)}
						/>
						</TableCell>
						<TableCell padding="none">
						<input
							value={row.USER_LAST_NAME}
							name="USER_LAST_NAME"
							 onChange={(e) => handleInputChangeUSERs(e, i)}
						/>
						</TableCell>
						<TableCell padding="none">
						<input
							value={row.USER_EMAIL}
							name="USER_EMAIL"
							 onChange={(e) => handleInputChangeUSERs(e, i)}
						/>
						</TableCell>
						<TableCell padding="none" colSpan={2}>
						<FormControl sx={{ m: 1, minWidth: 125 }}>
      <InputLabel id="DEFAULT_CLUB_ID">Default Club</InputLabel>
        <Select
          labelId="DEFAULT_CLUB_ID"
          id="DEFAULT_CLUB_ID"
          value={defaultClub}
          label="DEFAULT_CLUB_ID"
          name="DEFAULT_CLUB_ID"
          onChange={(e) => handleChange(e)}
          
        >
           {clubRows.map((club, i) => {
                              return (
          <MenuItem value={club.CLUB_ID}>{club.CLUB_NAME}</MenuItem>
                                
                              );
           }
           )
          }
         
        </Select>
        </FormControl>	
		<IconButton className="mr10" name="file_BUTTON"aria-label="upload picture" component="label" onChange={(e) => handleImageChange(e, i)}>
							<input hidden name="IMAGE_UPLOAD"  type="file" />
							<PhotoCamera />
						</IconButton>
						
						<Button align="right" onClick={() => handleSaveUSERs(i)}>
						<DoneIcon />
					
					</Button>
          <Button className="mr10" onClick={() => handleCancel()}>
						<ClearIcon />
					</Button>
          </TableCell>
            </>
					) : (
					<>
						<TableCell component="th" scope="row">
						{row.USER_NAME}
						</TableCell>
						<TableCell component="th" scope="row">
						{row.USER_FIRST_NAME}
						</TableCell>
						<TableCell component="th" scope="row">
						{row.USER_LAST_NAME}
						</TableCell>
						<TableCell component="th" scope="row">
						{row.USER_EMAIL}
						</TableCell>
						<TableCell component="th" scope="row">
						{row.CLUB_NAME}
						</TableCell>
						<TableCell
						component="th"
						scope="row"
						align="center"
						>
		<Button align="right" onClick={(e) => handleEdit( i)}>
				<CreateIcon />
				EDIT
				</Button>
          
       
          </TableCell>
          
          </>

					)}
				
					{showConfirm && (
					<>
						<Dialog
						open={showConfirm}
						onClose={handleNo}
						aria-labelledby="alert-dialog-title"
						aria-describedby="alert-dialog-description"
						>
						<DialogTitle id="alert-dialog-title">
							{"Confirm Delete"}
						</DialogTitle>
						<DialogContent>
							<DialogContentText id="alert-dialog-description">
							Are you sure to delete
							</DialogContentText>
						</DialogContent>
						<DialogActions>
							<Button
							onClick={() => handleRemoveUSERsClick(i)}
							color="primary"
							autoFocus
							>
							Yes
							</Button>
							<Button
							onClick={handleNo}
							color="primary"
							autoFocus
							>
							No
							</Button>
						</DialogActions>
						</Dialog>
					</>
					)}
				</TableRow>
				</>
			);
			})}
      </>
      )}
		</TableBody>
		</Table>
		</TableContainer>
        <TablePagination
          rowsPerPageOptions={[5, 10, 25]}
          component="div"
          count={sRows.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </Paper>
     
    </Box>
    </TableCell>
  </TableRow>
	</TableBody>
  </Table>

  </div>
);
}
    export default MyUsers;