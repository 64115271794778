import React, { useState, useEffect } from "react";
import CreateIcon from "@material-ui/icons/Create";

import AppBar from "./appBar";

import Paper from '@mui/material/Paper';
import TableContainer from '@mui/material/TableContainer';
import TablePagination from '@mui/material/TablePagination';

import {
    Box, Button, Snackbar, Table,
    TableBody, TableCell, TableHead, TableRow
} from "@material-ui/core";
import DeleteOutlineIcon from "@material-ui/icons/DeleteOutline";
import AddBoxIcon from "@material-ui/icons/AddBox";
import DoneIcon from "@material-ui/icons/Done";
import ClearIcon from "@material-ui/icons/Clear";
import { makeStyles } from "@material-ui/core/styles";
import Alert from "@material-ui/lab/Alert";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import SportsHandballIcon from '@material-ui/icons/SportsHandball';
import AutorenewIcon from '@material-ui/icons/Autorenew';
import DeleteIcon from '@material-ui/icons/Delete';
import SportsTennisIcon from '@material-ui/icons/SportsTennis';
import EmojiPeopleIcon from '@material-ui/icons/EmojiPeople';
import EditIcon from '@material-ui/icons/Edit';
import AddIcon from '@material-ui/icons/Add';
import SaveIcon from '@material-ui/icons//Save';

import Userfront from "@userfront/react";

// Creating styles
const useStyles = makeStyles({
	root: {
		"& > *": {
			borderBottom: "unset",
		},
	},

	snackbar: {
		bottom: "104px",
	},
});

function MyClubs() {
	// Creating style object
	const classes = useStyles();

	// Defining a state named rows
	// which we can update by calling on setRows function
	const [rows, setRows] = useState([]);
  const [sRows, setSRows] = useState([])
  

 
  //const [rows, setRows] = useState([
//		{ id: 1, firstname: "", lastname: "", city: "" },
//	]);

	// Initial states
	const [open, setOpen] = React.useState(false);
	const [isEdit, setEdit] = React.useState(false);
  const [editRow, setEditRow] = React.useState(-1);
  const [isAdd, setIsAdd] = React.useState(false);
	const [disable, setDisable] = React.useState(true);
	const [showConfirm, setShowConfirm] = React.useState(false);
	const [page, setPage] = React.useState(0);
	const [loggedInUser,setLoggedInUser] = React.useState(Userfront.user);
	const [isInitialized,setIsInitialized] = React.useState(false);
	


	
	// Showing delete confirmation to users
	const handleInitialized = () => {
		setIsInitialized(!isInitialized);
	};
	const InitializeData = () => {
   
		
	  
		//fetch game action is in progress
		fetch("/api/myClubs",
		{
		  method: "GET",
		  headers: {
			"Content-Type": "application/json",
			Authorization:`Bearer ${Userfront.tokens.accessToken}`
			  
		  },
		})
		.then((res) => {
			return res.json();
		})
		.then((ClubsData)=> {
		
		 setSRows(ClubsData);
		   
	   })
	   .catch(error => {
		   //console.log("throw me an error")
	   		}  
		   );
	  }
	  
	  const appendLeadingZeroes= (n)  => {
		if (n <= 9) {
		  return "0" + n;
		}
		return n
	  }
const insertClub = (Club) => {


		const insertClub=Club;
		var current_datetime = new Date();
		var formatted_date = current_datetime.getFullYear() + "-" +
		  appendLeadingZeroes(current_datetime.getMonth() + 1) + "-" +
		  appendLeadingZeroes(current_datetime.getDate()) + "_" +
		  appendLeadingZeroes(current_datetime.getHours()) + ":" +
		  appendLeadingZeroes(current_datetime.getMinutes()) + ":" +
		  appendLeadingZeroes(current_datetime.getSeconds());
	
		  //console.log(formatted_date)
		  insertClub.DATE_CREATED=formatted_date

		const requestOptions = {
		  method: 'POST',
		  headers: { 'Content-Type': 'application/json', 
		  Authorization: `Bearer ${Userfront.tokens.accessToken}` },
		  body: JSON.stringify(insertClub)
	  };
	  fetch('api/myClubs', requestOptions)
	 
		  .then(response => response.json())
		  .then(data => {
			  //console.log(data.Club_ID)
			  InitializeData();
		  })
	  }  

const deleteClub = (Club) => {


		const deleteClub=Club;

		const requestOptions = {
		  method: 'DELETE',
		  headers: { 'Content-Type': 'application/json', 
		  Authorization: `Bearer ${Userfront.tokens.accessToken}` },
		  body: JSON.stringify(deleteClub)
	  };
	  fetch('api/myClubs', requestOptions)
		  .then(response => response.json())
		  .then(data => {
			  InitializeData();
		  })
	  }  
const updateClub = (Club) => {


		const updateClub=Club;
		var current_datetime = new Date();
		var formatted_date = current_datetime.getFullYear() + "-" +
		  appendLeadingZeroes(current_datetime.getMonth() + 1) + "-" +
		  appendLeadingZeroes(current_datetime.getDate()) + "_" +
		  appendLeadingZeroes(current_datetime.getHours()) + ":" +
		  appendLeadingZeroes(current_datetime.getMinutes()) + ":" +
		  appendLeadingZeroes(current_datetime.getSeconds());
	
		  //console.log(formatted_date)
		  updateClub.DATE_UPDATED=formatted_date

		const requestOptions = {
		  method: 'PUT',
		  headers: { 'Content-Type': 'application/json', 
		  Authorization: `Bearer ${Userfront.tokens.accessToken}` },
		  body: JSON.stringify(updateClub)
	  };
	  fetch('api/myClubs', requestOptions)
		  .then(response => response.json())
		  .then(data => {
			  InitializeData();
		  })
	  }  


	const handleChangePage = (event, newPage) => {
		setPage(newPage);
	  };


	  const handleChangeRowsPerPage = (event) => {
		setRowsPerPage(parseInt(event.target.value, 10));
		setPage(0);
	  };

	  const [rowsPerPage, setRowsPerPage] = React.useState(5);
  // Function For closing the alert snackbar
	const handleClose = (event, reason) => {
		if (reason === "clickaway") {
			return;
		}
		setOpen(false);
	};

	// Function For adding new row object
	const handleAdd = () => {
		setIsAdd(true);
    if (rows)
    {
    setRows([
			...rows,
			{
				id: rows.length + 1,CLUB_NAME:"", Club_UID: null,DATE_CREATED: "",CREATEDBY_UID: null
			},
		]);
   }
    else
    {
      setRows([
        rows,
        {
			id: rows.length + 1,CLUB_NAME:"", Club_UID: null,DATE_CREATED: "",CREATEDBY_UID: null
        },
      ]);
    }
		setEdit(true);
    
	};
	// Function to handle cancel of edit
	const handleCancel = () => {
		// If edit mode is true setEdit will
		// set it to false and vice versa
		setEdit(!isEdit);
    setEditRow(-1)

	};

	// Function to handle edit
	const handleEdit = (i) => {
		// If edit mode is true setEdit will
		// set it to false and vice versa
		setEdit(!isEdit);
    setEditRow(i)

	};
  

	// Function to handle save
	const handleSave = () => {
		setEdit(false);
    setEditRow(-1);
    setIsAdd(false);
	 
    
    
    //console.log("Cleared : ", rows);
	insertClub(rows[0]);
	setRows([]);
	};

  // Function to handle saveClubs
	const handleSaveClubs = (i) => {
		setEdit(false);
    setEditRow(-1);
    setIsAdd(false); 

	//console.log("saved : ", sRows[i]);
	setDisable(true);
	setOpen(true);
    
    setRows([]);
    //console.log("Cleared : ", rows);
	updateClub(sRows[i]);
	InitializeData();
	};

	// The handleInputChange handler can be set up to handle
	// many different inputs in the form, listen for changes
	// to input elements and record their values in state
	const handleInputChange = (e, index) => {
		setDisable(false);
		const { name, value } = e.target;
		const list = [...rows];
		list[index][name] = value;
		setRows(list);
	};

  	// The handleInputChange handler can be set up to handle
	// many different inputs in the form, listen for changes
	// to input elements and record their values in state
	const handleInputChangeClubs = (e, index) => {
		setDisable(false);
		const { name, value } = e.target;
		const list = [...sRows];
		list[index][name] = value;
		setSRows(list);
	};


	// Showing delete confirmation to users
	const handleConfirm = () => {
		setShowConfirm(true);
	};

	// Handle the case of delete confirmation where
	// user click yes delete a specific row of id:i
	const handleRemoveClick = (i) => {
		const list = [...rows];
		list.splice(i, 1);
		setRows(list);
		setShowConfirm(false);
    setIsAdd(false);
	};


  const handleRemoveClubsClick = (i) => {
		const list = [...sRows];
		deleteClub(sRows[i])
		list.splice(i, 1);
		setSRows(list);
		setShowConfirm(false);
    setIsAdd(false);

	InitializeData();
	};

	// Handle the case of delete confirmation
	// where user click no
	const handleNo = () => {
		setShowConfirm(false);
	};
	const emptyRows =
    page > 0 ? Math.max(0, (1 + page) * rowsPerPage - rows.length) : 0;

	if (!isInitialized)
	{
	InitializeData();
	setIsInitialized(true);
	}
return (
  
 <div >
  
  <Table className="myMatch">
	<TableBody >
	<Snackbar
		open={open}
		autoHideDuration={2000}
		onClose={() => handleClose()}
		className={classes.snackbar}
	>
		<Alert onClose={() => handleClose()} severity="success">
		Record saved successfully!
		</Alert>
	</Snackbar>
  <TableRow align="center">
    <TableCell  className="ClubCell">
    Clubs
    </TableCell>
    <TableCell className="addButton" align="left">
	
      
      
      
			{isEdit ? (
			<>
        {isAdd ? (
					<Button disabled align="right" onClick={() => handleAdd()}>
					<AddBoxIcon onClick={() => handleAdd()}/>
				ADD Club
				</Button>
					) : (
            <Button onClick={() => handleAdd()}>
            <AddBoxIcon onClick={() => handleAdd()}/>
            ADD Club
            </Button>
					)}
			
			</>	
			
			) : (
			<>
				<Button  onClick={() => handleAdd()}>
				<AddBoxIcon onClick={() => handleAdd()}/>
				ADD Club
				</Button>
		
			</>
			)}
		
		</TableCell>
    </TableRow>
    
    
		<TableRow align="center"></TableRow>
    <TableRow><TableCell colSpan={2}>
    {isAdd && (
      <>    
		<Table
		className="myMatch"
		size="small"
		aria-label="a dense table"
		>
     
		<TableHead>
			<TableRow>
			<TableCell>Club Name</TableCell>
			<TableCell align="center"></TableCell>
			</TableRow>
		</TableHead>
		<TableBody>
      {(rows) && (
        <>
			{rows.map((row, ii) => {
			return (
				<>
				<TableRow>
					{isEdit ? (
					<>
						<TableCell padding="none">
						<input
							value={row.CLUB_NAME}
							name="CLUB_NAME"
							 onChange={(e) => handleInputChange(e, ii)}
						/>
						</TableCell>
						<TableCell padding="none">
						
            {rows.length !== 0 && (
				<>
					{disable ? (
					<Button disabled align="right" onClick={() => handleSave()}>
						<DoneIcon />
						SAVE
					</Button>
					) : (
					<Button align="right" onClick={() => handleSave()}>
						<DoneIcon />
						SAVE
					</Button>
					)}
				</>
				)}

          {isEdit  ? (
					<Button className="mr10" onClick={() => handleRemoveClick(ii)}>
						<ClearIcon />
					</Button>
					) : (
					<Button className="mr10" onClick={() => handleRemoveClick(ii)}>
						<DeleteOutlineIcon />
					</Button>
					)}

						</TableCell>
					
            </>
					) : (
					<>
						<TableCell component="th" scope="row">
						{row.CLUB_NAME}
						</TableCell>
						
					
						<TableCell
						component="th"
						scope="row"
						align="center"
						>

            </TableCell>
					</>
					)}
				
					
				</TableRow>
				</>
			);
			})}
      </>
      )}
		</TableBody>
		</Table>
    </>
    )
  }
  </TableCell>
  </TableRow>
  <TableRow>
    <TableCell className="matchCell" colSpan={2}>
   

    <Box sx={{ width: '100%' }}>
      <Paper sx={{ width: '100%', mb: 2 }}>
        
        <TableContainer>
    <Table
		 className="myMatch"
		size="small"
		aria-label="a dense table"
		>
		<TableHead>
			<TableRow>
			<TableCell>Club Name</TableCell>
			<TableCell align="center"></TableCell>
			</TableRow>
		</TableHead>
		<TableBody>
      {(sRows) && (
        <> {}
			{sRows.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row, i) => {
			return (
				<>
				<TableRow>
					{isEdit   && i==editRow ? (
					<>
						<TableCell padding="none">
						<input
							value={row.CLUB_NAME}
							name="CLUB_NAME"
							 onChange={(e) => handleInputChangeClubs(e, i)}
						/>
						</TableCell>
						<TableCell padding="none" colSpan={2}>
					
						<Button align="right" onClick={() => handleSaveClubs(i)}>
						<DoneIcon />
					
					</Button>
          <Button className="mr10" onClick={() => handleCancel()}>
						<ClearIcon />
					</Button>
          </TableCell>
            </>
					) : (
					<>
						<TableCell component="th" scope="row">
						{row.CLUB_NAME}
						</TableCell>
						
					
						<TableCell
						component="th"
						scope="row"
						align="center"
						>
		<Button align="right" onClick={(e) => handleEdit( i)}>
				<CreateIcon />
				EDIT
				</Button>
          
        <Button className="mr10" onClick={() => handleConfirm()}>
						<DeleteOutlineIcon />
					</Button>
          </TableCell>
          
          </>

					)}
				
					{showConfirm && (
					<>
						<Dialog
						open={showConfirm}
						onClose={handleNo}
						aria-labelledby="alert-dialog-title"
						aria-describedby="alert-dialog-description"
						>
						<DialogTitle id="alert-dialog-title">
							{"Confirm Delete"}
						</DialogTitle>
						<DialogContent>
							<DialogContentText id="alert-dialog-description">
							Are you sure to delete
							</DialogContentText>
						</DialogContent>
						<DialogActions>
							<Button
							onClick={() => handleRemoveClubsClick(i)}
							color="primary"
							autoFocus
							>
							Yes
							</Button>
							<Button
							onClick={handleNo}
							color="primary"
							autoFocus
							>
							No
							</Button>
						</DialogActions>
						</Dialog>
					</>
					)}
				</TableRow>
				</>
			);
			})}
      </>
      )}
		</TableBody>
		</Table>
		</TableContainer>
        <TablePagination
          rowsPerPageOptions={[5, 10, 25]}
          component="div"
          count={(sRows) ? (sRows.length) : (0)}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </Paper>
     
    </Box>
    </TableCell>
  </TableRow>
	</TableBody>
  </Table>

  </div>
);
}
    export default MyClubs;