import Userfront from "@userfront/react";

import React, { useState, useEffect } from "react";
import ViewMatches from "./ViewMatch";

import SettingsAppBarAll from "./SettingsAppBarAll";
import AppBar from "./appBar";

function MyMatchesDashboard (){
  
  const [selectedClub, setSelectedClub] = useState(null);
  const [selectedTeam, setSelectedTeam] = useState(null);
  const [selectedDate, setSelectedDate] = useState(null);
  const [prevSelectedDate, setPrevSelectedDate] = useState(null);
  const [stateChanger, setStateChanger] = useState(true);
  const snowContainer = document.getElementById("snow-container");
const snowContent = ['&#10052', '&#10053', '&#10054']

const random = (num) => {
  return Math.floor(Math.random() * num);
}

const getRandomStyles = () => {
  const top = random(100);
  const left = random(100);
  const dur = random(10) + 10;
  const size = random(25) + 25;
  return `
    top: -${top}%;
    left: ${left}%;
    font-size: ${size}px;
    animation-duration: ${dur}s;
  `;
}

const createSnow = (num) => {
  for (var i = num; i > 0; i--) {
    var snow = document.createElement("div");
    snow.className = "snow";
    snow.style.cssText = getRandomStyles();
    snow.innerHTML = snowContent[random(3)]
   if (snowContainer )
   snowContainer.append(snow);
  }
}

  if (selectedDate != null && selectedDate!= prevSelectedDate)
  {
    setPrevSelectedDate(selectedDate);
    setStateChanger(true);
  }
// uncommebnt tio add snow
 // createSnow(30);
 //   const [seconds, setSeconds] = useState(0);
  //  useEffect(() => {
  //    const interval = setInterval(() => {
  //      createSnow(30);
  //    }, 60000);
  //    return () => clearInterval(interval);
  //  }, []);


    return (
    <> 
      <div className="match">
        
       </div>
   <SettingsAppBarAll mStateChanger={setStateChanger} mSelectedClub={setSelectedClub} mSelectedTeam={setSelectedTeam} mSelectedDate={setSelectedDate}/>
    <div id="snow-container" >
     
                                   
                                  
      <ViewMatches  stateChanger={stateChanger} setStateChanger={setStateChanger} selectedClub={selectedClub} selectedTeam={selectedTeam} selectedDate={selectedDate}showActions={false} embedMe={true}> </ViewMatches>
      
      
      
      </div>
      </>
      )
}
    export default MyMatchesDashboard;