import React, { useState } from "react";
import Button from "../components/Button";
import TablePlayers from "../tablePlayers";

import { Link } from "react-router-dom";

import "../assets/css/style.css";

export default function TablePlayersRoute() {
 
  
  return (
    <div className="App">
   

    <div>
     
      <nav
        style={{
          borderBottom: "solid 1px",
          paddingBottom: "1rem"
        }}
      >
        <Link to="/">Home</Link> |{" "}
        <Link to="/scoregame">Score Game</Link>
      </nav>
    </div>
      
     
    
  
    <TablePlayers />
    </div>
   
  );
}

