import React, { useState, useEffect } from "react";

import MyPlayersTable from "./MyPlayersTable";

import CreateIcon from "@material-ui/icons/Create";

import AppBar from "./appBar";
import ClubAppBar from "./clubAppBar";
import Avatar from '@mui/material/Avatar';
import IconButton from '@material-ui/core/IconButton';
import PhotoCamera from '@material-ui/icons/PhotoCamera';
import Paper from '@mui/material/Paper';
import TableContainer from '@mui/material/TableContainer';
import TablePagination from '@mui/material/TablePagination';

import {
    Box, Button, Snackbar, Table,
    TableBody, TableCell, TableHead, TableRow
} from "@material-ui/core";
import DeleteOutlineIcon from "@material-ui/icons/DeleteOutline";
import AddBoxIcon from "@material-ui/icons/AddBox";
import DoneIcon from "@material-ui/icons/Done";
import ClearIcon from "@material-ui/icons/Clear";
import { makeStyles } from "@material-ui/core/styles";
import Alert from "@material-ui/lab/Alert";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import SportsHandballIcon from '@material-ui/icons/SportsHandball';
import AutorenewIcon from '@material-ui/icons/Autorenew';
import DeleteIcon from '@material-ui/icons/Delete';
import SportsTennisIcon from '@material-ui/icons/SportsTennis';
import EmojiPeopleIcon from '@material-ui/icons/EmojiPeople';
import EditIcon from '@material-ui/icons/Edit';
import AddIcon from '@material-ui/icons/Add';
import SaveIcon from '@material-ui/icons//Save';

import Userfront from "@userfront/react";

import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import PersonAddIcon from '@mui/icons-material/PersonAdd';
import def from "ajv/dist/vocabularies/discriminator";



// Creating styles
const useStyles = makeStyles({
	root: {
		"& > *": {
			borderBottom: "unset",
		},
	},

	snackbar: {
		bottom: "104px",
	},
});

function MyTeams() {
	// Creating style object
	const classes = useStyles();

	// Defining a state named rows
	// which we can update by calling on setRows function
	const [rows, setRows] = useState([]);
  const [sRows, setSRows] = useState([]);
  const [team, setTeam] = useState(0);
  const [defaultClub, setDefaultClub] = useState("");
  const [selectedClub, setSelectedClub] = useState("");
  const [clubRows, setClubRows]= useState([]);
  const [playerRows, setPlayerRows]= useState([]);
  

 
  //const [rows, setRows] = useState([
//		{ id: 1, firstname: "", lastname: "", city: "" },
//	]);

	// Initial states
	const [open, setOpen] = React.useState(false);
	const [isEdit, setEdit] = React.useState(false);
	const [showPlayers, setShowPlayers] = useState([]);
  const [editRow, setEditRow] = React.useState(-1);
  const [playerAddRow, setPlayerAddRow] = React.useState(-1);
  
  const [isAdd, setIsAdd] = React.useState(false);
  const [isPlayerAdd, setIsPlayerAdd] = React.useState(false);
	const [disable, setDisable] = React.useState(true);
	const [showConfirm, setShowConfirm] = React.useState(false);
	const [page, setPage] = React.useState(0);
	const [loggedInUser,setLoggedInUser] = React.useState(Userfront.user);
	const [isInitialized,setIsInitialized] = React.useState(false);
	
const [selectedMatch, setSelectedMatch] = useState(null);
const [stateChanger, setStateChanger] = useState(false);
const [selectedTeam, setSelectedTeam] = useState(null);
const [selectedPlayer, setSelectedPlayer] = useState(null);
const [settingAppBarLoaded, setSettingAppBarLoaded] = useState(false);

	
	// Showing delete confirmation to users
	const handleInitialized = () => {
		setIsInitialized(!isInitialized);
	};

	// Function to handle imageChange
const handleImageChange = (e, index) => {
		
	const name=  e.target.name;//files[0]
	const value=  e.target.files[0];//files[0]
		const list = [...rows];
	list[index][name] = value;
	setSRows(list);
};
 // Function to handle imageEdit
 const handleImageEdit = (e, index) => {
	
	const name=  e.target.name;//files[0]
	const value=  e.target.files[0];//files[0]
		const list = [...sRows];
	list[index][name] = value;
	setSRows(list);
};

	const stringToColor=(string) =>{
		let hash = 0;
		let i;
	  
		/* eslint-disable no-bitwise */
		for (i = 0; i < string.length; i += 1) {
		  hash = string.charCodeAt(i) + ((hash << 5) - hash);
		}
	  
		let color = '#';
	  
		for (i = 0; i < 3; i += 1) {
		  const value = (hash >> (i * 8)) & 0xff;
		  color += `00${value.toString(16)}`.substr(-2);
		}
		/* eslint-enable no-bitwise */
	  
		return color;
	  };

	  const stringAvatar=(name) =>{
		{
		  
		  var avatarInitials
		 if (name)
		 {
			if (name.includes(' ')) 
			{
			avatarInitials=`${name.split(' ')[0][0]}${name.split(' ')[1][0]}`;
			}
			else
			{
	  
			  avatarInitials=name.charAt(0)
			}
	  
		 }
		 
		  else
		  {
		  name="unknown";
		  avatarInitials="?";
		  }
	  
		  return {
			sx: {
			  bgcolor: stringToColor(name),
			},
			children: avatarInitials,
		  };
		}
	  };

	const InitializeData = () => {
   
		const requestOptions = {
			method: 'GET',
			headers: { 'Content-Type': 'application/json', 
			Authorization: `Bearer ${Userfront.tokens.accessToken}` }
		  };
	  
		//fetch game action is in progress

		//fetch game action is in progress
	
//console.log(stateChanger);
		 if (selectedClub)
		 {  
		 fetch("/api/myteams?rbc="+selectedClub,requestOptions)
		.then((res)  => {
			if (res.status != 404)
			{
			  return res.json()
			}
			else
			{
				setSRows([]);
			  return res;
			}
			}
		  )
			   .then((res)=> {
				if (res.status != 404)
				{
					setSRows(res);
				}
				
					
				})
			.catch(error => {
			  //console.log("Set Matches throw me an error " +  error)
				}  )
	   
			}
		




	  }
	  

	  const refreshTeam = (value) => {
   
		const requestOptions = {
			method: 'GET',
			headers: { 'Content-Type': 'application/json', 
			Authorization: `Bearer ${Userfront.tokens.accessToken}` }
		  };
	  
		//fetch game action is in progress

		//fetch game action is in progress
	
		   
			 fetch("/api/myteams?rbc="+value,requestOptions)
		 .then((res)  => {
            if (res.status != 404)
            {
              return res.json()
            }
            else
            {
				setSRows([]);
              return res;
            }
            }
          )
               .then((res)=> {
                if (res.status != 404)
                {
					setSRows(res);
                }
                
                    
                })
            .catch(error => {
              //console.log("throw me an error " +  error)
                }  )
		 
	   fetch("/api/myClubs",requestOptions)
	   .then((res) => res.json())
	   .then((clubData)=> {
		
		setClubRows(clubData);
		  
	  })

	  }

	  const appendLeadingZeroes= (n)  => {
		if (n <= 9) {
		  return "0" + n;
		}
		return n
	  }
const insertTeam = (team) => {


		const insertTeam=team;
		var current_datetime = new Date();
		var formatted_date = current_datetime.getFullYear() + "-" +
		  appendLeadingZeroes(current_datetime.getMonth() + 1) + "-" +
		  appendLeadingZeroes(current_datetime.getDate()) + "_" +
		  appendLeadingZeroes(current_datetime.getHours()) + ":" +
		  appendLeadingZeroes(current_datetime.getMinutes()) + ":" +
		  appendLeadingZeroes(current_datetime.getSeconds());
	
		  //console.log(formatted_date)
		  insertTeam.DATE_CREATED=formatted_date
		  
		  const formData = new FormData();
		  formData.append("file", insertTeam.IMAGE_UPLOAD);
		  formData.append("insertTEAM", JSON.stringify(insertTeam));

		const requestOptions = {
		  method: 'POST',
		  headers: { 
		  Authorization: `Bearer ${Userfront.tokens.accessToken}` },
		  body: formData
	  };
	  fetch('api/myteams', requestOptions)
	 
		 // .then(response => response.json())
		  .then(data => {
			  //console.log(data.TEAM_ID)
			  InitializeData();
		  })
	  }  

const deleteTeam = (team) => {


		const deleteTeam=team;

		const requestOptions = {
		  method: 'DELETE',
		  headers: { 'Content-Type': 'application/json', 
		  Authorization: `Bearer ${Userfront.tokens.accessToken}` },
		  body: JSON.stringify(deleteTeam)
	  };
	  fetch('api/myteams', requestOptions)
		  
		  .then(data => {
			  InitializeData();
		  })
	  }  
const updateTeam = (team) => {


		const updateTeam=team;
		var current_datetime = new Date();
		var formatted_date = current_datetime.getFullYear() + "-" +
		  appendLeadingZeroes(current_datetime.getMonth() + 1) + "-" +
		  appendLeadingZeroes(current_datetime.getDate()) + "_" +
		  appendLeadingZeroes(current_datetime.getHours()) + ":" +
		  appendLeadingZeroes(current_datetime.getMinutes()) + ":" +
		  appendLeadingZeroes(current_datetime.getSeconds());
	
		  //console.log(formatted_date)
		  updateTeam.DATE_UPDATED=formatted_date

		  const formData = new FormData();
		  formData.append("file", updateTeam.IMAGE_UPLOAD);
		  formData.append("insertTEAM", JSON.stringify(updateTeam));

		const requestOptions = {
		  method: 'PUT',
		  headers: { 
		  Authorization: `Bearer ${Userfront.tokens.accessToken}` },
		  body: formData
	  };
	  fetch('api/myteams', requestOptions)
		  .then(response => response.json())
		  .then(data => {
			  InitializeData();
		  })
	  }  


	const handleChangePage = (event, newPage) => {
		setPage(newPage);
	  };


	  const handleChange = (e) => {
		
		const { name, value } = e.target;
		
		setDefaultClub(value);
		
		//console.log("Default Club: " + value)	
		refreshTeam(value);
		  
	  }; 

	  const handleChangeClub = (e) => {
		
		const { name, value } = e.target;
		setSelectedClub(value);
		
		
		  
	  }; 

	  const handleChangeRowsPerPage = (event) => {
		setRowsPerPage(parseInt(event.target.value, 10));
		setPage(0);
	  };

	  const [rowsPerPage, setRowsPerPage] = React.useState(5);
  // Function For closing the alert snackbar
	const handleClose = (event, reason) => {
		if (reason === "clickaway") {
			return;
		}
		setOpen(false);
	};

	// Function For adding new row object
	const handleAdd = () => {
		setIsAdd(true);
    if (rows)
    {
    setRows([
			...rows,
			{
				id: rows.length + 1,CLUB_ID: selectedClub ,TEAM_NAME:"",TEAM_SHORT_NAME: "",TEAM_LOGO:null, TEAM_UID: null,DATE_CREATED: "",CREATEDBY_UID: null,TEAM_OWNER_UID:loggedInUser.userUuid, IMAGE_UPLOAD: null
			},
		]);
   }
    else
    {
      setRows([
        rows,
        {
			id: rows.length + 1,CLUB_ID: selectedClub,TEAM_NAME:"",TEAM_SHORT_NAME: "",TEAM_LOGO:null, TEAM_UID: null,DATE_CREATED: "",CREATEDBY_UID: null,TEAM_OWNER_UID:loggedInUser.userUuid, IMAGE_UPLOAD: null
        },
      ]);
    }
		setEdit(true);
    
	};
	// Function to handle cancel of edit
	const handleCancel = () => {
		// If edit mode is true setEdit will
		// set it to false and vice versa
		setEdit(!isEdit);
    setEditRow(-1)

	};

	// Function to handle edit
	const handleEdit = (i) => {
		// If edit mode is true setEdit will
		// set it to false and vice versa
		setEdit(!isEdit);
    setEditRow(i)

	};

	const handleShowPlayers = (i) => {
		// If edit mode is true setEdit will
		// set it to false and vice versa
		//setShowPlayers[i](!showPlayers);
		const list = [...showPlayers];
		list[i] = !list[i];
		setShowPlayers(list);
    

	};

	const handleCancelPlayer = (i) => {
		// If edit mode is true setEdit will
		// set it to false and vice versa
		//setShowPlayers[i](!showPlayers);
		const list = [...showPlayers];
		list[i] = !list[i];
		setShowPlayers(list);
		setIsPlayerAdd(false);

	};

		// Function to handle edit
	const handlePlayerAdd = (i) => {
			
			//always show players
			const list = [...showPlayers];
			list[i] = true;
			setShowPlayers(list);

			if (!isPlayerAdd)
			{
			const requestOptions = {
				method: 'GET',
				headers: { 'Content-Type': 'application/json', 
				Authorization: `Bearer ${Userfront.tokens.accessToken}` }
			  };
		  
			fetch("/api/myplayers?rbct=true&rbc="+selectedClub + "&rbt="+sRows[i].TEAM_ID,requestOptions)
			.then((res) => res.json())
			.then((PlayersData)=> {
				const playerLabels = []
				for (let i = 0; i < PlayersData.length; i++) {
					playerLabels.push({ label: PlayersData[i].PLAYER_FIRST_NAME + " " + PlayersData[i].PLAYER_LAST_NAME, id: PlayersData[i].PLAYER_ID })
					
					}
			setPlayerRows(playerLabels);
			   
		   })
		}
		else
		{
			const mPlayer=	{
				id: 1,PLAYER_FIRST_NAME:"",PLAYER_LAST_NAME:"",PLAYER_SHORT_NAME: "", PLAYER_UID: null,DATE_CREATED: "",
				CREATEDBY_UID: null,PLAYER_OWNER_UID:loggedInUser.userUuid,TEAM_ID:sRows[i].TEAM_ID,PLAYER_ID: selectedPlayer
			};
			const requestOptions = {
				method: 'POST',
				headers: { 'Content-Type': 'application/json', 
				Authorization: `Bearer ${Userfront.tokens.accessToken}` },
				body: JSON.stringify(mPlayer)
			};
			fetch("api/myPlayers?rbpt=true&rbt="+sRows[i].TEAM_ID,requestOptions)
				.then(response => response.json())
				.then(data => {
					InitializeData();
				})
			}  
		

		   // If edit mode is true setEdit will
			// set it to false and vice versa
			setIsPlayerAdd(!isPlayerAdd);
			setPlayerAddRow(i)
			
	
		};
	

	// Function to handle save
	const handleSave = () => {
		setEdit(false);
    setEditRow(-1);
    setIsAdd(false);
	 
    
    
    //console.log("Cleared : ", rows);
	insertTeam(rows[0]);
	setRows([]);
	};

  // Function to handle saveteams
	const handleSaveTeams = (i) => {
		setEdit(false);
    setEditRow(-1);
    setIsAdd(false); 

	//console.log("saved : ", sRows[i]);
	setDisable(true);
	setOpen(true);
    
    setRows([]);
    //console.log("Cleared : ", rows);
	updateTeam(sRows[i]);
	InitializeData();
	};

	// The handleInputChange handler can be set up to handle
	// many different inputs in the form, listen for changes
	// to input elements and record their values in state
	const handleInputChange = (e, index) => {
		setDisable(false);
		const { name, value } = e.target;
		const list = [...rows];
		list[index][name] = value;
		setRows(list);
	};

  	// The handleInputChange handler can be set up to handle
	// many different inputs in the form, listen for changes
	// to input elements and record their values in state
	const handleInputChangeTeams = (e, index) => {
		setDisable(false);
		const { name, value } = e.target;
		const list = [...sRows];
		list[index][name] = value;
		setSRows(list);
	};

	const handleInputChangePlayer = (e, index) => {
		setDisable(false);
		const { id, label } = index;
		//console.log ("player selected " + label + "value " + id)
		setSelectedPlayer(id);
	};

	// Showing delete confirmation to users
	const handleConfirm = () => {
		setShowConfirm(true);
	};

	// Handle the case of delete confirmation where
	// user click yes delete a specific row of id:i
	const handleRemoveClick = (i) => {
		const list = [...rows];
		list.splice(i, 1);
		setRows(list);
		setShowConfirm(false);
    setIsAdd(false);
	};


  const handleRemoveTeamsClick = (i) => {
		const list = [...sRows];
		deleteTeam(sRows[i])
		list.splice(i, 1);
		setSRows(list);
		setShowConfirm(false);
    setIsAdd(false);

	InitializeData();
	};

	// Handle the case of delete confirmation
	// where user click no
	const handleNo = () => {
		setShowConfirm(false);
	};
	const emptyRows =
    page > 0 ? Math.max(0, (1 + page) * rowsPerPage - rows.length) : 0;

	if ((!isInitialized) || (stateChanger))
	{
		setIsInitialized(true);	
		setStateChanger(false);
		InitializeData();
	
	}
return (
  
 <div >
	

  <ClubAppBar mStateChanger={setStateChanger} mSelectedClub={setSelectedClub} msetSettingAppBarLoaded={setSettingAppBarLoaded}/>
       
          
  <div>
  
        </div>
  <Table className="myMatch">
	<TableBody >
	<Snackbar
		open={open}
		autoHideDuration={2000}
		onClose={() => handleClose()}
		className={classes.snackbar}
	>
		<Alert onClose={() => handleClose()} severity="success">
		Record saved successfully!
		</Alert>
	</Snackbar>
  <TableRow align="center">
    <TableCell  className="teamCell">
	 Teams
    </TableCell>
    <TableCell className="addButton" align="left">
	
      
      
      
			{isEdit ? (
			<>
        {isAdd ? (
					<Button disabled align="right" onClick={() => handleAdd()}>
					<AddBoxIcon onClick={() => handleAdd()}/>
				ADD Team
				</Button>
					) : (
            <Button onClick={() => handleAdd()}>
            <AddBoxIcon onClick={() => handleAdd()}/>
            ADD Team
            </Button>
					)}
			
			</>	
			
			) : (
			<>
				{(settingAppBarLoaded== false )? (
					<Button disabled align="right" onClick={() => handleAdd()}>
					<AddBoxIcon onClick={() => handleAdd()}/>
				ADD Team
				</Button>
					) : (
            <Button onClick={() => handleAdd()}>
            <AddBoxIcon onClick={() => handleAdd()}/>
            ADD Team
            </Button>
					)}
		
			</>
			)}
		
		</TableCell>
    </TableRow>
    
    
		<TableRow align="center"></TableRow>
    <TableRow><TableCell colSpan={2}>
    {isAdd && (
      <>    
		<Table
		className="myMatch"
		size="small"
		aria-label="a dense table"
		>
     
		<TableHead>
			<TableRow>
			<TableCell>Team Name</TableCell>
			<TableCell>Team Nick Name</TableCell>
			<TableCell align="center"></TableCell>
			</TableRow>
		</TableHead>
		<TableBody>
      {(rows) && (
        <>
			{rows.map((row, ii) => {
			return (
				<>
				<TableRow  key={ii}>
					{isEdit ? (
					<>
						<TableCell padding="none">
						
						<input
							value={row.TEAM_NAME}
							name="TEAM_NAME"
							 onChange={(e) => handleInputChange(e, ii)}
						/>
						</TableCell>
						<TableCell padding="none">
						<input
							value={row.TEAM_SHORT_NAME}
							name="TEAM_SHORT_NAME"
							onChange={(e) => handleInputChange(e, ii)}
						/>
						<FormControl sx={{ m: 1, minWidth: 125 }}>
      <InputLabel id="CLUB_ID">Club</InputLabel>
        <Select
          labelId="CLUB_ID"
          id="CLUB_ID"
          value={row.CLUB_ID}
          label="CLUB_ID"
          name="CLUB_ID"
          onChange={(e) => handleInputChange(e, ii)}
          
        >
           {clubRows.map((club, i) => {
                              return (
          <MenuItem value={club.CLUB_ID}>{club.CLUB_NAME}</MenuItem>
                                
                              );
           }
           )
          }
         
        </Select>
        
            {rows.length !== 0 && (
				<>
					{disable ? (
						<>
						<IconButton className="mr10" name="IMAGE_UPLOAD_BUTTON" aria-label="upload picture" component="label" onChange={(e) => handleImageChange(e, ii)}>
					<input hidden name="IMAGE_UPLOAD" accept="image/*" type="file" />
					<PhotoCamera />
				</IconButton>
					<Button disabled align="right" onClick={() => handleSave()}>
						<DoneIcon />
						SAVE
					</Button>
					</>
					) : (
						<>
					
					<IconButton className="mr10" name="IMAGE_UPLOAD_BUTTON" aria-label="upload picture" component="label" onChange={(e) => handleImageChange(e, ii)}>
					<input hidden name="IMAGE_UPLOAD" accept="image/*" type="file" />
					<PhotoCamera />
					</IconButton>
					<Button align="right" onClick={() => handleSave()}>
						<DoneIcon />
						SAVE
					</Button>
					</>
					)}
				</>
				)}

          {isEdit  ? (
					<Button className="mr10" onClick={() => handleRemoveClick(ii)}>
						<ClearIcon />
					</Button>
					) : (
					<Button className="mr10" onClick={() => handleRemoveClick(ii)}>
						<DeleteOutlineIcon />
					</Button>
					)}
				</FormControl>	
						</TableCell>
					
            </>
					) : (
					<>
						<TableCell component="th" scope="row">
						<Avatar {...stringAvatar(row.TEAM_NAME)} src={"static/public/" + row.TEAM_UID + ".JPEG"}/>	
						{row.TEAM_NAME}
						</TableCell>
						<TableCell component="th" scope="row">
						{row.TEAM_SHORT_NAME}
						</TableCell>
					
						<TableCell
						component="th"
						scope="row"
						align="center"
						>

            </TableCell>
					</>
					)}
				
					
				</TableRow>
				</>
			);
			})}
      </>
      )}
		</TableBody>
		</Table>
    </>
    )
  }
  </TableCell>
  </TableRow>
  <TableRow>
    <TableCell className="matchCell" colSpan={2}>
   

    <Box sx={{ width: '100%' }}>
      <Paper sx={{ width: '100%', mb: 2 }}>
        
        <TableContainer>
    <Table
		 className="myMatch"
		size="small"
		aria-label="a dense table"
		>
		<TableHead>
			<TableRow>
			<TableCell className="myMatch">Team Name</TableCell>
			<TableCell>Team Nick Name</TableCell>
			<TableCell align="center"></TableCell>
			</TableRow>
		</TableHead>
		<TableBody>
      {(sRows) && (
        <> {}
			{sRows.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row, i) => {
			return (
	<>
				<TableRow  key={i} className="myMatch">
	{isEdit   && i==editRow ? (
					<>
						<TableCell padding="none">
						
						<input
							value={row.TEAM_NAME}
							name="TEAM_NAME"
							 onChange={(e) => handleInputChangeTeams(e, i + (page * rowsPerPage ))}
						/>
						</TableCell>
						<TableCell padding="none" colSpan={2}>
						<input
							value={row.TEAM_SHORT_NAME}
							name="TEAM_SHORT_NAME"
							onChange={(e) => handleInputChangeTeams(e, i + (page * rowsPerPage ))}
						/>
						
          
				<FormControl sx={{ m: 1, minWidth: 125 }}>
				<InputLabel id="CLUB_ID">Club</InputLabel>
					<Select
					labelId="CLUB_ID"
					id="CLUB_ID"
					value={row.CLUB_ID}
					label="CLUB_ID"
					name="CLUB_ID"
					onChange={(e) => handleInputChangeTeams(e, i + (page * rowsPerPage ))}
					
					>
					{clubRows.map((club, i) => {
										return (
					<MenuItem value={club.CLUB_ID}>{club.CLUB_NAME}</MenuItem>
											
										);
					}
					)
					}	
				
				</Select>
				</FormControl>	
				<IconButton className="mr10" name="IMAGE_UPLOAD_BUTTON" aria-label="upload picture" component="label" onChange={(e) => handleImageEdit(e, i + (page * rowsPerPage ))}>
					<input hidden name="IMAGE_UPLOAD" accept="image/*" type="file" />
					<PhotoCamera />
				</IconButton>
				<Button align="right" onClick={() => handleSaveTeams(i + (page * rowsPerPage ))}>
								<DoneIcon />
							
							</Button>
				<Button className="mr10" onClick={() => handleCancel()}>
								<ClearIcon />
							</Button>
				</TableCell>
					</>
		)//end if
				//set	
		:
		(
	<>
						<TableCell >
						<Avatar {...stringAvatar(row.TEAM_NAME)} src={"static/public/" + row.TEAM_UID + ".JPEG"}/>	
						{row.TEAM_NAME}
						</TableCell>
						<TableCell >
						{row.TEAM_SHORT_NAME}
						</TableCell>
					
						<TableCell
						className="myMatch"
						>
		
				
						{showPlayers[i]  && (

							<table><MyPlayersTable mSelectedClub={selectedClub} mSelectedTeam={row.TEAM_ID}/>
							</table>
							
						)
						}
		
					<table className="myMatch">
		{isPlayerAdd   && i==playerAddRow ? (
		<>
								
							<Autocomplete
				disablePortal
				id="combo-box-demo"
				onChange={(e,newValue) => handleInputChangePlayer(e, newValue)}
				options={playerRows}
				sx={{ width: 100 }}
				renderInput={(params) => <TextField {...params} label="Players" />}
				/>
				<Button  onClick={() => handlePlayerAdd(i)}>
							<DoneIcon />
						
						</Button>
			<Button  onClick={() => handleCancelPlayer(i)}>
							<ClearIcon />
						</Button>	
		
		</>
		)
		:
		(
		<>
				<Button align="right" onClick={(e) => handleEdit( i + (page * rowsPerPage ))}>
						<CreateIcon />
						EDIT
						</Button>
					<Button align="right" onClick={(e) => handleShowPlayers(i + (page * rowsPerPage ))}>
					<CreateIcon />
					Show Players
					</Button>
				<Button id={row.TEAM_ID} align="right" onClick={(e) => handlePlayerAdd(i + (page * rowsPerPage ))}>
						<PersonAddIcon />
						Add Player
				</Button>
				<Button className="mr10" onClick={() => handleConfirm()}>
								<DeleteOutlineIcon />
							</Button>
			</>
		)
		}
		</table>
          </TableCell>
          
    </>//this is it
	)
	}

					
				
					{showConfirm && (
					<>
						<Dialog
						open={showConfirm}
						onClose={handleNo}
						aria-labelledby="alert-dialog-title"
						aria-describedby="alert-dialog-description"
						>
						<DialogTitle id="alert-dialog-title">
							{"Confirm Delete"}
						</DialogTitle>
						<DialogContent>
							<DialogContentText id="alert-dialog-description">
							Are you sure to delete
							</DialogContentText>
						</DialogContent>
						<DialogActions>
							<Button
							onClick={() => handleRemoveTeamsClick(i + (page * rowsPerPage ))}
							color="primary"
							autoFocus
							>
							Yes
							</Button>
							<Button
							onClick={handleNo}
							color="primary"
							autoFocus
							>
							No
							</Button>
						</DialogActions>
						</Dialog>
					</>
					)}
				</TableRow>
			</>
			);
			})}
      </>
      )}
		</TableBody>
		</Table>
		</TableContainer>
        <TablePagination
          rowsPerPageOptions={[5, 10, 25]}
          component="div"
          count={sRows.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </Paper>
     
    </Box>
    </TableCell>
  </TableRow>
	</TableBody>
  </Table>

  </div>
);
}
    export default MyTeams;