import React, { useState } from "react";
import Button from "../components/Button";
import ViewMatches from "../ViewMatches";

import { Link } from "react-router-dom";

import "../assets/css/style.css";


export default function ViewMatchesRoute() {
 
  

 
  return (
    <div className="App">

    <ViewMatches />
    </div>
   
  );
}

