import * as React from 'react';

import { useNavigate } from 'react-router-dom';


import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import Menu from '@mui/material/Menu';
import MenuIcon from '@mui/icons-material/Menu';
import Container from '@mui/material/Container';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import Tooltip from '@mui/material/Tooltip';
import MenuItem from '@mui/material/MenuItem';
import FormLabel from '@mui/material/FormLabel';
import HomeIcon from '@mui/icons-material/Home';


import Userfront from "@userfront/react";


const notLoggedInPages = [ 
                {pageName: "Matches", pageKey: "mymatchesDashboard"}, 
                {pageName: "Login", pageKey: "login"},
                {pageName: "Sign Up", pageKey: "signup"}
                
              ];
const pages = [ 
                {pageName: "Matches", pageKey: "mymatchesDashboard"},              
                {pageName: "Quick Match", pageKey: "setupmatch"},
                {pageName: "Score Matches", pageKey: "mymatches"}
               
              ];
const settings = ['Profile','Club Access','My Favorites','Clubs', 'Teams', 'Players', 'Logout'];



const ResponsiveAppBar = () => {
  const [anchorElNav, setAnchorElNav] = React.useState(null);
  const [anchorElUser, setAnchorElUser] = React.useState(null);

  const navigate = useNavigate();

  const handleOpenNavMenu = (event) => {
    setAnchorElNav(event.currentTarget);
    const name = event.target.innerText;
    //console.log("name: " + name )
    switch (name) {
      case "Logout":
        Userfront.logout();
        break;
    }
  };
  
  const handleOpenUserMenu = (event) => {
    setAnchorElUser(event.currentTarget);
    const name = event.target.innerText;
    //console.log("name: " + name )
    switch (name) {
      case "Logout":
        Userfront.logout();
        break;
    }
  };
    
  

  const handleCloseNavMenu = (event) => {
    setAnchorElNav(null);
    const name = event.target.innerText;
    //console.log("name: " + name )
    switch (name) {
      case "Logout":
        Userfront.logout();
        break;
    }
  };
  
  const goHome = (event) => {
    
        navigate("../home");

    }
 
  const handleCloseUserMenu = (event) => {
    setAnchorElUser(null);
    const name = event.target.innerText;
    //console.log("name: " + name )
    switch (name) {
      case "Logout":
        Userfront.logout();
        break;
      case "Profile"  :
        navigate("../myUsers");
        break;
        case "Club Access"  :
        navigate("../myclubaccess");
        break;
        
      case "My Favorites"  :
          navigate("../myFavoriteClubs");
          break;  
      case "Clubs"  :
          navigate("../myClubs");
          break;
      case "Teams"  :
            navigate("../myTeams");
            break;  
      case "Players"  :
              navigate("../myPlayers");
              break;   
      default:
        navigate("../"+name);

    }
  };
  const loggedInUser = Userfront.user;
  const loggedInUserUID = Userfront.user.userUuid;

 // alert (loggedInUser.name)
  
  function stringToColor(string) {
    let hash = 0;
    let i;
  
    /* eslint-disable no-bitwise */
    for (i = 0; i < string.length; i += 1) {
      hash = string.charCodeAt(i) + ((hash << 5) - hash);
    }
  
    let color = '#';
  
    for (i = 0; i < 3; i += 1) {
      const value = (hash >> (i * 8)) & 0xff;
      color += `00${value.toString(16)}`.substr(-2);
    }
    /* eslint-enable no-bitwise */
  
    return color;
  }
  
  function stringAvatar(name) {
    {
      
      var avatarInitials
     if (name)
     {
        if (name.includes(' ')) 
        {
        avatarInitials=`${name.split(' ')[0][0]}${name.split(' ')[1][0]}`;
        }
        else
        {

          avatarInitials=name.charAt(0)
        }

     }
     
      else
      {
      name="unknown";
      avatarInitials="?";
      }

      return {
        sx: {
          bgcolor: stringToColor(name),
        },
        children: avatarInitials,
      };
    }
  }
  return (
    <AppBar  class="myAppBar" position="static">
      <Container maxWidth="xl">
        <Toolbar disableGutters>
        
          <Typography
            variant="h6"
            noWrap
            component="div"
            sx={{ mr: 2, display: { xs: 'flex', md: 'flex' } }}
          >
                        <img src={"/static/public/logo/rball_logo.JPEG"} alt="Racquetball.fun" width="50"/>
          </Typography>

          <Box sx={{ flexGrow: 1, display: { xs: 'flex', md: 'flex' } }}>
          <Button align="left"  style={{maxWidth: '30px', minWidth: '30px'}} onClick={() => goHome("goHome")}>
          <HomeIcon size="small"  className="HomeIcon"/>
        </Button>
            {(!Userfront.tokens.accessToken) ? (
            
              <>
              {notLoggedInPages.map((page) => (
                <>
                <Button color="inherit" name={page.pageKey} href={"/"+page.pageKey}>{page.pageName}</Button>
                
                </>
              ))
              }
              </>
              )
            :
              (
              <>
                {pages.map((page) => (
                  <>
                  <Button color="inherit" name={page.pageKey} href={"/"+page.pageKey}>{page.pageName}</Button>
                  
                  </>
                ))
              }
              </>
              )
            }
          </Box>
         
          

          <Box sx={{ flexGrow: 0 }}>
          {(Userfront.tokens.accessToken) && (
            <Tooltip title="Open settings">
              <IconButton onClick={handleOpenUserMenu} sx={{ p: 0 }}>
              <Avatar {...stringAvatar(loggedInUser.name)} src={"/static/public/" +  loggedInUserUID +".JPEG" } onClick={handleOpenUserMenu}/>
              </IconButton>
            </Tooltip>
          )  
          }
            <Menu
              sx={{ mt: '45px' }}
              id="menu-appbar"
              anchorEl={anchorElUser}
              anchorOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
              keepMounted
              transformOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
              open={Boolean(anchorElUser)}
              onClose={handleCloseUserMenu}
            >
              <FormLabel sx={{ ml: 1}}>
                {loggedInUser.name}
              </FormLabel>
              {settings.map((setting) => (
                <MenuItem name={setting} key={setting} onClick={handleCloseUserMenu}>
                  <Typography textAlign="center">{setting}</Typography>
                </MenuItem>
              ))}
            </Menu>
          </Box>
        </Toolbar>
      </Container>
    </AppBar>
  );
};
export default ResponsiveAppBar;