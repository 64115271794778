import { Aod } from "@mui/icons-material";
import React, {useEffect, useState } from "react";
import { useParams } from "react-router-dom";

import dayjs from "dayjs";
import Avatar from '@mui/material/Avatar';
import SettingsAppBar from "./SettingsAppBar";
import AppBar from "./appBar";
import Box from '@mui/material/Box';
import Grid from '@mui/material/Unstable_Grid2';
import CloseIcon from '@mui/icons-material/Close';

import InputLabel from '@mui/material/InputLabel';

import FormControl from '@mui/material/FormControl';

import SportsHandballIcon from '@material-ui/icons/SportsHandball';

import Paper from '@mui/material/Paper';

import IosShareIcon from '@mui/icons-material/IosShare';

import Collapse from '@mui/material/Collapse';
import Alert from '@mui/material/Alert';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import Userfront from "@userfront/react";

import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import { styled } from '@mui/material/styles';
import {
   Button,
} from "@material-ui/core";
import Container from '@mui/material/Container';
import TableContainer from '@mui/material/TableContainer';

import MyGames from './MyGames';




function ViewMatch ({stateChanger,setStateChanger,selectedClub, selectedTeam,selectedDate,matchID,showActions,embedMe}) {
  const [defaultClub, setDefaultClub] = useState("");
  const currentHostName=window.location.host;
  //const [selectedClub, setSelectedClub] = useState(null);
 // const [selectedTeam, setSelectedTeam] = useState(null);
  const [matches, setMatches] = useState([]);
  const [match, setMatch] = useState([]);
  const [games, setGames] = useState([]);
  const [selectedMatch, setSelectedMatch] = useState(null);
  //const [stateChanger, setStateChanger] = useState(true);
  const [players, setPlayers] = useState([]);
  const [urlOpen, setUrlOpen] = useState(false);
  const [urlRow, setUrlRow] = useState("");
  const [gameURL, setGameURL] = useState("");
 
  const [isInitialized,setIsInitialized] = React.useState(false);  
  const [showBoxScore,setShowBoxScore] = React.useState([]);  
  
  
    const handleBoxScoreClick=(i) =>{
      const tempBoxScore=showBoxScore;
      tempBoxScore[i]=!showBoxScore[i];
      setShowBoxScore(tempBoxScore);
      setStateChanger(true);
    };

 
  const stringAvatar=(name) =>{
    {
      
      var avatarInitials
     if (name)
     {
        if (name.includes(' ')) 
        {
        avatarInitials=`${name.split(' ')[0][0]}${name.split(' ')[1][0]}`;
        }
        else
        {
  
          avatarInitials=name.charAt(0)
        }
  
     }
     
      else
      {
      name="unknown";
      avatarInitials="?";
      }
  
      return {
        sx: {
          bgcolor: stringToColor(name),
        },
        children: avatarInitials,
      };
    }
  };

  const handleClick = (i) => {
  
    const match = matches[i];
    //console.log("Match Clicked: " + match.MATCH_UID + " name: " + match.MATCH_NAME)
    
      setSelectedMatch(match);
     
    //console.log("we built the game");
  }; 

  const handleShare = (i) => {

  const gameURL="https://racquetball.fun/viewmatches/match/"+ this.state.matches[i].MATCH_UID
  navigator.clipboard.writeText("https://racquetball.fun/viewmatches/match/"+ this.state.matches[i].MATCH_UID)
  setGameURL(gameURL);
   setUrlOpen(true);
   setUrlRow(i);

}


const stringToColor=(string) =>{
  let hash = 0;
  let i;

  /* eslint-disable no-bitwise */
  for (i = 0; i < string.length; i += 1) {
    hash = string.charCodeAt(i) + ((hash << 5) - hash);
  }

  let color = '#';

  for (i = 0; i < 3; i += 1) {
    const value = (hash >> (i * 8)) & 0xff;
    color += `00${value.toString(16)}`.substr(-2);
  }
  /* eslint-enable no-bitwise */

  return color;
};
const appendLeadingZeroes= (n)  => {
  if (n <= 9) {
    return "0" + n;
  }
  return n
  }

const InitializeData = () => {
   
  const loggedInUser = Userfront.user;
  const requestOptions = {
    method: 'GET',
    headers: { 'Content-Type': 'application/json', 
    Authorization: `Bearer ${Userfront.tokens.accessToken}` }
  };
  
  var current_datetime
  if (selectedDate)
  {
    current_datetime= selectedDate.substring(0,10);
    
}
  //fetch game action is in progress
  fetch("/api/allMatches?rbc=" + selectedClub + "&rbt="+ selectedTeam + "&rbdate=" + current_datetime,requestOptions)
  
        
       .then((res)  => {
        if (res.status != 404)
        {
          return res.json()
        }
        else
        {
          setMatches([]);
          return res;
        }
        }
      )
           .then((res)=> {
            if (res.status != 404)
            {
              setMatches(res);
            }
            
                
            })
        .catch(error => {
          //console.log("Set Matches throw me an error " +  error)
            }  )
  
if (matchID != null)
 {
  //fetch game action is in progress
  fetch("/api/allMatches?rbm="+ matchID,requestOptions)
  .then((res) => res.json())
  .then((matchesData)=> {
   
    
      setSelectedMatch(matchesData[0]);
        
       this.buildPlayers(matchesData[0]);
       this.buildGame(matchesData[0]);
      }) 
     
 }
}


const initializeGame =(gamesData,match)  => {
{
let gamesDataRowCount=0;

if (gamesData.length>0) {
if (gamesData[0].gameNum==null)
{
  gamesDataRowCount=0;
  gamesData.pop();
}
else
{
  gamesDataRowCount=gamesData.length;
}
}

if (gamesDataRowCount<match.NUM_GAMES) {
  var addGames=[];
  if (gamesData)
  {
   addGames=gamesData;
  }
  else
  {
     addGames=[];
  }  

  //****FOR */
  //****FOR */
  for (let index = gamesDataRowCount; index <match.NUM_GAMES; index++) 
  {
    var gameStatus;
    if (index==0)
    {

      gameStatus="Game Available"
    }
    else if (addGames[index-1].gameStatus=="Game Available" || addGames[index-1].gameStatus=="Game Not Available" || addGames[index-1].gameStatus=="In Progress"  )
    {
      gameStatus="Game Not Available"

    }
    else 
    {
      gameStatus="Game Available"

    }
    const teams=[];
    let tempTeams = {}
    let tempTeams1 = {}
    tempTeams.teamId = match.TEAM1_ID;
    tempTeams.teamName = match.TEAM1_NAME;
  teams.push(tempTeams);
    tempTeams1.teamId = match.TEAM2_ID;
    tempTeams1.teamName = match.TEAM2_NAME;
    teams.push(tempTeams1);

    addGames.push({
    gameNum: index+1,
    game_UID: null,
    gameStatus: gameStatus,
    teams: teams,
    
    team1Score: 0,
    team2Score: 0
    });


   
    
   }
   //console.log(JSON.stringify(addGames))
  
    setGames(addGames); 
}

}








  


  const handlematchCells = (actionType) => 
  {
    const clickedButtonName= actionType;
    switch (clickedButtonName) {
      case "Serve":
        //
        break;
    case "Back":
      this.setState({
        //
      })
    break;
 
     
    }        
  };


  const handleChange = (e) => {
  
  const { name, value } = e.target;
  const myMatch = match;
  myMatch[name] = value;
  //console.log("name: " + name + " value: " + value)
  
    setMatch(myMatch);
    
}; 


  
} 
if ((stateChanger))
	{
	InitializeData();
	setStateChanger(false)
	
	}

  const [seconds, setSeconds] = useState(0);
  useEffect(() => {
    const interval = setInterval(() => {
      setStateChanger(true);
    }, 10000);
    return () => clearInterval(interval);
  }, []);
return (
      
      <div className="match">
       
      
    
    {(!selectedMatch) && (
     <Box sx={{ flexGrow: 1 }}>
     <Grid container spacing={2}>
       
        
      
                
                
       
            {(matches.length>0) ? (
              <>
                {matches.map((match, i) => {
                              return (
                                <>
                                <Grid >
                                <table class="myMatchesTop">
                                
                                <tr>
                                  <td>
                                {match.TEAM1_NAME + " vs " +match.TEAM2_NAME + " "}
                                </td>
                                </tr>
                                <tr>
                                <td>

                                {match.MATCH_NAME}
                                </td>
                                </tr>
                                </table>
                                <table class="myMatches">
                                <thead>
                                <tr>
                                  <td>Teams</td>
                                  <td>
                                  <a href={"https://" + currentHostName + "/viewgame/game/"+match.GAME1_UID}>Game 1</a>
                                  </td>
                                  {(match.NUM_GAMES>1) && (
                                  <td>
                                    <a href={"https://" + currentHostName + "/viewgame/game/"+match.GAME2_UID}>Game 2</a>
                                  </td>
                                  )
                                  }
                                  {(match.NUM_GAMES>2) && (
                                  <td>
                                    <a href={"https://" + currentHostName + "/viewgame/game/"+match.GAME3_UID}>Game 3</a>
                                  </td>
                                  )
                                  }
                                  {(match.NUM_GAMES>3) && (
                                  <td>
                                    <a href={"https://" + currentHostName + "/viewgame/game/"+match.GAME4_UID}>Game 4</a>
                                  </td>
                                  )
                                  }
                                  {(match.NUM_GAMES>4) && (
                                  <td><a href={"https://" + currentHostName + "/viewgame/game/"+match.GAME5_UID}>Game 5</a></td>
                                  )
                                  }
                                </tr>
                                </thead>
                                <tbody>
                                <tr>
                                    
 
                                 
                                    <td>
                                    
                                    <table >
                                  {(match.MATCH_TYPE=="Singles") ? (
                                    <>
                                    <tr>
  
                                    <Avatar {...stringAvatar(match.TEAM1P1_NAME)} src={"static/public/" + match.TEAM1P1_UID + ".JPEG" } variant="square"/>
                                  
                                    </tr>
                                    <tr>
                                     {match.TEAM1P1_NAME}
                                    </tr>
                                    </>
                                  ):
                                  (
                                    <>
                                    <tr>
                                    {match.TEAM1_NAME}
                                    </tr>
                                    <tr>
                                     {match.TEAM1P1_NAME + " / " + match.TEAM1P2_NAME}
                                     </tr>
                                    </>
                                  )
                                  
                                  }
                                  </table>
                                  </td>
                                  {(match.NUM_GAMES>0) && (
                                  <td>
                                  {match.GAME1TEAM1SCORE}
                                  </td>
                                  )}
                                 {(match.NUM_GAMES>1) && (
                                  <td>
                                    {match.GAME2TEAM1SCORE}
                                  </td>
                                  )}
                                  {(match.NUM_GAMES>2) && (
                                  <td>
                                  {match.GAME3TEAM1SCORE}
                                  </td>
                                  )}
                                  {(match.NUM_GAMES>3) && (
                                  <td>
                                  {match.GAME4TEAM1SCORE}
                                  </td>
                                  )}
                                  {(match.NUM_GAMES>4) && (
                                    <td>
                                  {match.GAME5TEAM1SCORE}
                                  </td>
                                  )}
                                </tr>
                                
                                <tr>
                                <td>
                                    <table>
                                  {(match.MATCH_TYPE=="Singles") ? (
                                    <>
                                    <tr>
                                    <Avatar {...stringAvatar(match.TEAM2P1_NAME)} src={"static/public/" + match.TEAM2P1_UID + ".JPEG"} variant="square"/>
                                    
                                    </tr>
                                    <tr>
                                    {match.TEAM2P1_NAME}
                                    </tr>
                                    </>
                                  ):
                                  (
                                    <>
                                    <tr>
                                    {match.TEAM2_NAME}
                                    </tr>
                                    <tr>
                                     {match.TEAM2P1_NAME + " / " + match.TEAM2P2_NAME}
                                     </tr>
                                    </>
                                  )
                                  
                                  }
                                  </table>
                                  </td>
                                  {(match.NUM_GAMES>0) && (
                                  <td>
                                  {match.GAME1TEAM2SCORE}
                                  </td>
                                  )}
                                 {(match.NUM_GAMES>1) && (
                                  <td>
                                    {match.GAME2TEAM2SCORE}
                                  </td>
                                  )}
                                  {(match.NUM_GAMES>2) && (
                                  <td>
                                  {match.GAME3TEAM2SCORE}
                                  </td>
                                  )}
                                  {(match.NUM_GAMES>3) && (
                                  <td>
                                  {match.GAME4TEAM2SCORE}
                                  </td>
                                  )}
                                  {(match.NUM_GAMES>4) && (
                                    <td>
                                  {match.GAME5TEAM2CORE}
                                  </td>
                                  )}
                                
        
                                  </tr>
                                  <tr>
                                    <td colSpan={2+ match.NUM_GAMES }>
                                  <a href="#" onClick={() => handleBoxScoreClick(i)}>
                                    Box Score
                                        </a>
                                        </td>
                                    </tr>
                                    <tr>
                                  {(showBoxScore[i]) && (
                                  <td   colSpan={2 + match.NUM_GAMES}>
                                   
                              <table class="myMatchesN">
                                <thead>
                                <tr>
                                <th>
                                  </th>
                                  <th> 1st Serves</th>
                                  <th>1st Fault</th>
                                  <th> 2nd Serves</th>
                                  <th>2nd Fault</th>
                                  <th> Serves</th>
                                  <th>Aces</th>
                                  <th>Points</th>
                                <th>Give</th>
                                  <th>|</th>
                                  <th>Gets</th>
                                  </tr>
                                  </thead>
                                <tr>
                                  
                                 
                             <td>
                                  <Avatar {...stringAvatar(match.TEAM1P1_NAME)} src={"static/public/" + match.TEAM1P1_UID + ".JPEG"}     sx={{ width: 24, height: 24 }} variant="square"/>
                                  </td>
                                  <td>
                             {match.T1P1_SERVES- match.T1P1_FAULT1}
                             </td>
                             <td>
                            {match.T1P1_FAULT1}
                            </td>
                            <td>
                            {match.T1P1_FAULT1}
                             </td>
                             <td>
                            
                            {match.T1P1_FAULT2}
                            </td>
                             <td>
                             {match.T1P1_SERVES}
                             </td>
                             <td>
                            {match.T1P1_ACES}
                            </td>
                             <td>
                            {match.T1P1_POINTS}
                            </td>  
                             <td>
                            {match.T1P1_GIVE}
                            </td>
                             <td></td>
                             <td>
                            
                            {match.T1P1_GETS}
                            </td>
                            </tr>
                            <tr>
                            <td>
                                  <Avatar {...stringAvatar(match.TEAM2P1_NAME)} src={"static/public/" + match.TEAM2P1_UID + ".JPEG"}     sx={{ width: 24, height: 24 }} variant="square"/>
                                  </td>
                             <td>
                            {match.T2P1_SERVES-match.T2P1_FAULT1}
                            </td>
                            <td>
                            {match.T2P1_FAULT1}
                            </td>
                            <td>
                            {match.T2P1_FAULT1}
                            </td>
                            <td>
                            {match.T2P1_FAULT2}
                            </td>
                            <td>
                            {match.T2P1_SERVES}
                            </td>
                             <td>
                            {match.T1P1_ACES}
                            </td>
                             <td>
                            {match.T2P1_POINTS}
                            </td>
                             
                             
                             <td>
                            {match.T2P1_GIVE}
                            </td>
                             <td>
                             </td>
                             <td>
                            {match.T2P1_GETS}
                            </td>
                             
                            </tr>
                            </table>
                            
                             </td> 
                                  )
                                    }
                                    
                                  </tr>
                                  </tbody>
                             </table>
                            
                             </Grid>
                             
                              </>
                              );
                            }
                        )
                    }
                    
                   </>   
                   )
                   :
                   (
                <Box display="flex" justifyContent="center">
                <Grid >
                <item >
                  
                     <Typography
                    variant="h6"
                    Wrap
                    component="div"
                    sx={{ mr: 2, display: { xs: 'flex', md: 'flex' } }}
                  >
                      There are no matches for the date selected
                  </Typography>
                
                  </item>

                  </Grid>
                  </Box>
                   )
                }
                  
                 
                
      </Grid>
    </Box>
       )} 
             
              
      </div>
      )
    
  
 
  }

    export default ViewMatch;