import { Aod } from "@mui/icons-material";
import React, { useState } from "react";
import { useParams } from "react-router-dom";

import dayjs from "dayjs";

import AppBar from "./appBar";
import Box from '@mui/material/Box';


import InputLabel from '@mui/material/InputLabel';

import FormControl from '@mui/material/FormControl';

import SportsHandballIcon from '@material-ui/icons/SportsHandball';

import Paper from '@mui/material/Paper';

import Userfront from "@userfront/react";

import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import { styled } from '@mui/material/styles';
import {
   Button, Table,
  TableBody, TableHead, TableRow,
} from "@material-ui/core";
import TableContainer from '@mui/material/TableContainer';

import ViewMyGames from './ViewMyGames';

function withParams(Component) {
  return props => <Component {...props} params={useParams()} />;
}

class viewMatches extends React.Component {
  
  constructor(props) {
    super(props);
    this.state = {
      
      matches:
      [],
      Games: 
          
      [],
      players: [],
      selectedMatch: null,
      interval: null,

}


}


handleScoreGameBackClick  = () => {
  this.setState({ selectedMatch: null});

}; 

InitializeData = () => {
   
  const loggedInUser = Userfront.user;
  const requestOptions = {
    method: 'GET',
    headers: { 'Content-Type': 'application/json', 
    Authorization: `Bearer ${Userfront.tokens.accessToken}` }
  };
  //fetch game action is in progress
  fetch("/api/setupmatch?rbo="+loggedInUser.userUuid,requestOptions)
  .then((res) => res.json())
  .then((matchesData)=> {
   
    this.setState({
    matches: matchesData
       }); 
     })
  
if (this.props.params.match)
 {
  //fetch game action is in progress
  fetch("/api/setupmatch?rbm="+ this.props.params.match,requestOptions)
  .then((res) => res.json())
  .then((matchesData)=> {
   
    this.setState({
      selectedMatch: matchesData[0]
       }); 
       this.buildPlayers(matchesData[0]);
       this.buildGame(matchesData[0]);
      }) 
     
 }
}


initializeGame(gamesData,match)
{

   const addGames=gamesData;
 
   //console.log(JSON.stringify(addGames))
   this.setState({
    Games: addGames
       }); 
}


buildPlayers = (match) => {
  const players = [];
  
    const tempPlayer={}
    tempPlayer.playerID=match.TEAM1P1_ID;
    tempPlayer.playerName=match.TEAM1P1_NAME;
    players.pop(tempPlayer);
    tempPlayer.playerID=match.TEAM1P2_ID;
    tempPlayer.playerName=match.TEAM1P2_NAME;
    players.pop(tempPlayer);
    tempPlayer.playerID=match.TEAM2P1_ID;
    tempPlayer.playerName=match.TEAM2P1_NAME;
    players.pop(tempPlayer);
    tempPlayer.playerID=match.TEAM2P2_ID;
    tempPlayer.playerName=match.TEAM2P2_NAME;
    players.pop(tempPlayer);
    this.setState({
      players: players
      
    });
  
}

buildGame = (match) => {
   
  const loggedInUser = Userfront.user;

  //fetch game is in progress
  
  const retGamesData=[]
  //fetch game action is in progress
  fetch("/api/getScoreBoard?rbm="+match.MATCH_UID,
  {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization:`Bearer ${Userfront.tokens.accessToken}`
        
    },
  })
  .then((res) => {
    if (res.status != 404)
    {
      return res.json()
    }
    else
    {
      return res;
    }
    }
  )
  .then((res)=> {
    if (res.status != 404)
    {
      this.setState({
    
        Games: res
        }); 
      this.initializeGame(res,match) 
    }
    else
    {
      this.initializeGame([],match) 
    }  
        
    })
  .catch(console.log("maybe syntax error")) ;
  //this.initializeGame([],match) 
  
  
 
 
  
}

componentWillUnmount(){
  clearInterval(this.state.interval)
  const interval = setInterval(this.InitializeData,10000);
  this.setState({
    interval: interval,
    });
}



componentDidMount() {
   
  this.InitializeData();
  
  }



 
  
    render()
     {
    return (
      
      <div className="match">
        
       
   
          <FormControl variant="standard">
            <InputLabel htmlFor="component-simple">Matches</InputLabel>
          
          </FormControl>
    
    {(!this.state.selectedMatch) && (
    <TableContainer component={Paper}>
      <Table className="myMatch" 
      size="small"
      aria-label="a dense table"
      stickyHeader padding="none">
        <TableHead>
          <TableRow >
            
            <TableCell  className="matchNameCell"> Match</TableCell>
                <TableCell className="matchCell" > Team 1</TableCell>
                <TableCell className="matchCell" > Team 2</TableCell>
                <TableCell  className="matchCell"> Status?</TableCell>
                <TableCell  className="matchCell"> Start</TableCell>
                <TableCell  className="matchCell"> Finish</TableCell>
                <TableCell  className="matchCell"> Singles?</TableCell>
                <TableCell  className="matchCell"> Games?</TableCell>
                <TableCell  className="matchCell"> To Win?</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
                
                
       
            {(this.state.matches) && (
              <>
                {this.state.matches.map((match, i) => {
                              return (

                                <TableRow>

                                  <TableCell  className="matchNameCell">
                                    {match.MATCH_NAME}
                                    <Button
                                      onClick={() => this.handleClick(i)}
                                      
                                      
                                    >
                                      <SportsHandballIcon />
                                    </Button>
                                  </TableCell>
                                  <TableCell align="center"  className="matchCellMedium">
                                    {match.TEAM1_NAME}
                                  </TableCell>
                                  <TableCell align="center"  className="matchCellMedium">
                                  {match.TEAM2_NAME}
                                  </TableCell>
                                  <TableCell align="center"  className="matchCellMedium">
                                  {match.MATCH_STATUS}
                                  </TableCell>
                                  <TableCell align="center"  className="matchCellWide">
                                  {dayjs(match.START_TIME).format('M/D/YY h:m a')}
                                  </TableCell>
                                  <TableCell align="center"  className="matchCellWide">
                                  {match.END_TIME}
                                  </TableCell>
                                  <TableCell align="center"  className="matchCellMedium">
                                  {match.MATCH_TYPE}
                                  </TableCell>
                                  <TableCell align="center"  className="matchCell">
                                  {match.NUM_GAMES}
                                  </TableCell>
                                  <TableCell align="center"  className="matchCell">
                                  {match.POINTSTOWIN}
                                 

                                    
                                  </TableCell>

                                </TableRow>

                              );
                            }
                        )
                    }
                   </>   
                   )
                  } 
                    </TableBody>
                </Table>
                </TableContainer>
       )} 
              <ViewMyGames handleClickBack={this.handleScoreGameBackClick} match={this.state.selectedMatch} games={this.state.Games} players={this.state.players}/>
              
      </div>
      )
    }
  
 
  }
    export default withParams(viewMatches);